import React ,{useState,useRef} from 'react'
import styles from './autoCompleteFilter.module.css'
import {ICON_PATH} from '../../../helper/pathConstants'
import axios from 'axios'
import CustomButton from '../buttons/CustomButton'
import useOutsideClick from '../../../hooks/useOutSideClick'
import { useTranslation } from "react-i18next";

export default function AutoCompleteFilter(props) {
    const initialValue = {};
    const [search, setSearch] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedOption , setselectedOption] = useState(initialValue);
    const token = localStorage.getItem("token");
    const localOffice = localStorage.getItem("localOffice");
    const [isLoading, setLoading] = useState(false);
    const propertyRef = useRef();
    const { t, i18n } = useTranslation();

    const handleOptionClick = (e) => {
        e.preventDefault();
        if(selectedOption.id !== e.target.dataset.id) {
            setselectedOption(e.target.dataset);
        } else {
            setselectedOption({})
        }   
    }

    const handleApplyClick = (e) => {
        e.preventDefault()
        props.handleOptionClick(props.name,selectedOption);
        setSearch("");
    }


    const handleCrossClick = (e) => {
        e.preventDefault()
        setSearch("");
        setSuggestions([]);
        setselectedOption({})
    }


    const debounce =(func,delay) => {
        let timer;
        return function() {
            let context = this;
            let args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(context,args),delay);
        };
    }

    const handleSearchChange = (event) => {
        event.preventDefault();
        setLoading(true);
        const value = event.target.value;
        setSearch(value);
        const payload = {
            headers: {
              Authorization: "Bearer " + token,
            },
            params : {
                search : value,
                localOffice : localOffice,
                roles : props.role
              }
          };
        debounce(() => {
            axios.get(`/api/autocomplete/${props.endPoint}`,payload)
            .then(res => {
                if(res && res.data) {
                    setSuggestions(res.data.result);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error();
            })
        },1000)();
    }

    const suggetionsJsx = suggestions.map((item,index) => {
        return <div key={item.id} className={styles.option} onClick={handleOptionClick} id={item.id} data-id={item.id} data-optional={item.optional} data-name={item.name} >
                <img src={ (item.id === selectedOption.id) ? ICON_PATH +"selected_radio.svg":ICON_PATH +"deselected_radio.svg"} alt="" data-id={item.id} data-optional={item.optional} data-name={item.name}/>
                <p style={{margin:0,width:"fit-content"}} id={item.id} data-id={item.id} data-optional={item.optional} data-name={item.name}>{item.name} </p>
            </div>
    })

    useOutsideClick(propertyRef,props.onExit,true);

    return (
        <div className={styles.property_filter} ref={propertyRef}>
            <div className={styles.search}> 
                <img src={ICON_PATH + "search.svg"} alt=""/>
                <input className={styles.search_box} type="text" placeholder={props.placeholder} value={search} onChange={handleSearchChange}/>
                <img style={{display : (search.length > 0) ? "inline" : "none"}} src={ICON_PATH + "x.svg"} alt="X" onClick={handleCrossClick}/>
            </div>
            {
                (search.length > 0) ?
                <div className={styles.option_list} >
                   {(isLoading)? <div className={styles.no_option} >{t("Loading.label")}</div>:
                    (suggetionsJsx.length > 0) ? suggetionsJsx : <div className={styles.no_option} >{t("NoResult.label")}</div>
                    }
                </div>
                : null
            }
            <div className={styles.footer} style={{display : (selectedOption.id) ? "flex" : "none"}}>
                <div className={styles.clear} onClick={() => setselectedOption({})}>{t("Clear.word")}</div>
                <CustomButton padding="90" buttonName={t("Apply.word")} type="default" onClick={handleApplyClick}/>
            </div> 
            
        </div>
    )
}
