import {ICON_PATH,SCHEDULER,STAFF,SUMMARY} from '../helper/pathConstants';
import moment from 'moment';

export const getMinutesList = () => {
    return Array(60).fill(0).map((item,index) => {
        if(index >= 10)
        return index;
        else {
            return `0${index}`;
        }
    });
} 

export const getHoursList = () => {
    return Array(24).fill("").map((item,index) => {
        if(index+1 <= 12) {
            return `${index+1} AM`;
        } else {
            return `${index-11} PM`;
        }
    })
}

export const tatList = [
    "0-15 minutes",
    "15-30 minutes",
    "1 hour",
    "2 hour",
    "3 hour",
    "4 hour",
    "5 hour",
    "6 hour",
    "7 hour",
    "8 hour"
]

export const staffDetailsMap = {
    "Employment Details" : {
        name : "Name" ,
        id : "Employee ID" ,
        role : "Role" ,
        manager : "Manager" ,
        mobile : "Mobile" ,
        email : "Email" ,
        joiningDate : "Joining Date",
        status : "Status" 
    },
    "Service Area" : {
        localeOffice : "Locale office",
        city :"City" ,
        business : "Business" 
    },
    "Vendor Details" : {
        vendorName : "Vendor Name",
        vendorId : "Employee ID",
    },
    "Other Info" : {
        createdDate : "Created Date",
        createdBy : "Created By",
        lastModified : "Last Modified Date",
        lastModifiedBy : "Last Modified By" 
    }
}

export const taskTitleOptions = [
    "CLEANING",
    "PLUMBING",
    "SWIMMING_POOL",
    "ELECTRICAL",
    "WASHING",
    "DELIVERY"
]

export const staffRoleOptions = [
    "CLEANERS",
    "SERVICE_STAFF",
    "POOL_TECHNICIANS",
    "HOMEOWNERS"
]

// {
//     image : ICON_PATH + "dashboard_icon.svg",
//     image_red : ICON_PATH  + "dashboard_icon_red.svg",
//     description : "Dashboard.label",
//     value : 0,
//     path : SCHEDULER 
// }

export const menuItems = [
    {
        image : ICON_PATH + "scheduler_icon.svg",
        image_red : ICON_PATH + "scheduler_icon_red.svg",
        description : "Scheduler.label",
        value : 1,
        path : SCHEDULER 
    },
    {
        image : ICON_PATH + "task_summary_icon.svg",
        image_red : ICON_PATH + "task_summary_icon_red.svg",
        description : "TaskSummary.label",
        value : 2,
        path : SUMMARY
    },
    {
        image : ICON_PATH + "staff_icon.svg",
        image_red : ICON_PATH + "staff_icon_red.svg",
        description : "Staff.label",
        value : 3,
        path : STAFF 
    },
] 

export const addTaskInitialState = {
    taskTitle : "",
    taskDescription : "",
    completeBy : moment().format("D MMM YYYY").toString(),
    hour : "4 PM",
    property : {},
    assignTo : {},
    turnAroundTime : "15-30 minutes",
    minutes : "00"
};

export const addStaffInitialState = {
    name : "",
    role : "",
    manager : {},
    countryCode : "",
    mobile : "",
    email : "",
    joiningDate : "",
    status : "",
    business : "Dancenter",
    vendorName : ""
};

export const editInfoInitialState = {
    dbId : "",
    name : "",
    id : "",
    role : "",
    manager : {},
    mobile : "",
    countryCode : "",
    email : "",
    joiningDate : "",
    status : "",
    localeOffice : "",
    city : "",
    business : "",
    vendorName : "",
    vendorId : "",
}

export const pageSizes = [
    5,
    10,
    15,
    20
]

export const getPaginationList = (current,total) => {
    if(total <= 9) {
        return Array(total).fill(0).map((item,index) => index+1);
    } else {
        if(current <= 4 ) {
            return Array(9).fill(0).map((item,index) => {
                if(index < 7) {
                    return index+1;
                } else if(index === 7 ) {
                    return "...";
                } else {
                    return total;
                }
            })
        } else if(total - current <= 4) {
            return Array(9).fill(0).map((item,index) => {
                if(index > 1) {
                    return total + index - 8;
                } else if(index === 1 ) {
                    return "...";
                } else {
                    return 1;
                }
            })
        } else {
            return Array(9).fill(1).map((item,index) => {
                if(index > 1 && index < 7) {
                    return current - (4 - index);
                } else if(index === 1 ||index === 7) {
                    return "...";
                } else if(index === 8){
                    return total;
                } else {
                    return item;
                }
            })
        }
    }
}

export const taskTypeMap = {
    "ALLE": {
        taskTitle : "Alle med ærinde i huset",
        taskIcon : "ALLE.svg"
    },
    "BOI": {
        taskTitle : "Ved indflytning",
        taskIcon : "BOI.svg"
    },
    "BOU": {
        taskTitle : "Ved udflytning",
        taskIcon : "BOU.svg"
    },
    "CFI": {
        taskTitle : "Check før indflytning",
        taskIcon : "CFI.svg"
    },
    "HNT": {
        taskTitle : "Afhent",
        taskIcon : "HNT.svg"
    },
    "LSK": {
        taskTitle : "Lejerskiftekontrol",
        taskIcon : "LSK.svg"
    },
    "MÅL": {
        taskTitle : "Måleraflæsning",
        taskIcon : "electrical_task.svg"
    },
    "MAN": {
        taskTitle : "Husk/Udfør",
        taskIcon : "MAN.svg"
    },
    "RNG": {
        taskTitle : "Rengøring",
        taskIcon : "cleaning_task.svg"
    },
    "UDB": {
        taskTitle : "Udbring",
        taskIcon : "delivery_task.svg"
    },
    "SPA": {
        taskTitle : "Spa rengøring",
        taskIcon : "SPA.svg"
    },
    "FLEX": {
        taskTitle : "Flex-control system",
        taskIcon : "FLEX.svg"
    },
    "FSK": {
        taskTitle : "HFI kontrol af fejlstrømsanlæg",
        taskIcon : "FSK.svg"
    },
    "FRB": {
        taskTitle : "Nøglebidrag Nordsøen",
        taskIcon : "FRB.svg"
    },
    "FALCK": {
        taskTitle : "FALCK pakke til huset (ny abonnent)",
        taskIcon : "FLACK.svg"
    },
    "NOTE": {
        taskTitle : "Note",
        taskIcon : "NOTE.svg"
    },
    "SVS": {
        taskTitle : "Standvandspa",
        taskIcon : "swimming_pool_task.svg"
    },
    "DEFAULT" : {
        taskIcon : "plumbing_task.svg"
    }

}

