import React from 'react';
import styles from './assignStaff.module.css';
import CustomButton from '../../common/buttons/CustomButton';
import GroupStaff from './GroupStaff';
import Modal from '../../common/modal/Modal';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'




export default function AssignStaff(props) {
    const { t, i18n } = useTranslation()

    const handleExitClick = () => {
        props.callBacks.clearStaffQueue();
        props.onExit();
    }

    const handleAssignStaffClick = () => {
        if(props.currentSize > 0) {
            props.callBacks.assignStaff();
            handleExitClick();
        }
        
    }

    const groupJsx = [];
    for (const [key,value] of Object.entries(props.data)) {
        groupJsx.push(<GroupStaff currentSize={props.currentSize} taskSize={props.taskSize} maxSize={props.maxSize} stafData={value} groupType={value.staffType} key={key} callBacks={props.callBacks}/>);
    }

    return (
        <Modal>
            <div className={styles.assign_staff}>
                <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={handleExitClick} alt=""/>
                <div className={styles.header}>
                    <div className={styles.row1}>
                        {t("AssignStaff.label")}
                    </div>
                    {/* <div className={styles.row2}>
                        {`Max 2 ${t("MaxPerson.label")}`}
                    </div> */}
                </div>
                <div className={styles.middle}>
                    {groupJsx}
                </div>
                <div className={styles.footer}>
                    <CustomButton type="default" buttonName={t("Cancel.button")} padding="62" onClick={handleExitClick}/>
                    <CustomButton type="colored" color={(props.currentSize === 0) ?"blocked":"blue"} buttonName={t("Assign.button")} padding="125" onClick={handleAssignStaffClick}/>
                </div>
            </div>
        </Modal>    
    )
}
