import React, { useState, useReducer, useEffect } from "react";
import styles from "./signIn.module.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../common/spinner/Spinner";
import { IMAGE_PATH, SCHEDULER,ICON_PATH} from "../../helper/pathConstants";
import {actions, reducer, initialState} from "../../reducers/SigninReducer";
import moment from "moment";
import ForgotPassword from "./ForgotPassword";
import ErrorPopUp from "../common/error/ErrorPopUp";

import {
    PLEASE_ENTER_EMAIL_AND_PASSWORD,
    INVALID_EMAIL_OR_PASSWORD,
    SOMETHING_WENT_WRONG,
    TO_MANY_INVALID_SIGNIN_ATTEMPTS,
} from "../../helper/text_EN.js";

export default function SignIn() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t, i18n } = useTranslation();
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [isForgotPasswordOpen, setForgotPassword] = useState(false);
    const [checkboxCheckedStatus, setCheckboxChecked] = useState(false);
    const [user, setUser] = useState({ email: "", password: "" });
    const [error, setError] = useState("");
    const history = useHistory();
    let maxSignInTimePeriod = 60 * 60 * 1000;

    const inputChangeHandler = (e) => {
        const { id, value } = e.target;
        setUser((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };
    const handleForgotPassword = (message) => {
        setForgotPassword(!isForgotPasswordOpen)
        if(message && message.length > 0) {
            setError(message);
        }
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        authenticateToServer();
    };

    useEffect(() => {
        if (checkboxCheckedStatus) maxSignInTimePeriod = 365 * 24 * 60 * 60 * 1000;
    }, [checkboxCheckedStatus]);

    const authenticateToServer = () => {
        if (user.email.length && user.password.length) {
            const payload = {
                email: user.email,
                password: user.password,
            };
            actions.fetchStart(dispatch);
            axios
                .post("/api/signin", payload)
                .then((res) => {
                    localStorage.setItem("isAuthenticate", true);
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("userId", res.data.name);
                    localStorage.setItem("localOffice",res.data.localOfficeId);
                    localStorage.setItem("localOfficeName",res.data.localOfficeName);
                    localStorage.setItem("legecyId",res.data.legacyId);
                    localStorage.setItem("city",res.data.city);
                    const expiryTime = new Date(
                        new Date().getTime() + maxSignInTimePeriod
                    );
                    localStorage.setItem("expiryTime", expiryTime.toISOString());
                    localStorage.setItem('startDate',moment().format("D MMM YYYY"));
                    localStorage.setItem('date',moment().format("D MMM YYYY"));
                    localStorage.setItem('endDate',moment().add(1,"day").format("D MMM YYYY"));
                    localStorage.setItem('languageId',"English")
                    actions.fetchSuccess(dispatch)
                    history.push(SCHEDULER);
                })
                .catch(function (error) {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.customMessage &&
                        error.response.data.customMessage.error &&
                        error.response.data.customMessage.error.code
                    ) {
                        const errorCode = error.response.data.customMessage.error.code;
                        if (error.response.status == 401 && errorCode === 11) {
                            actions.fetchError(dispatch,INVALID_EMAIL_OR_PASSWORD)
                        } else if (errorCode === 58) {
                            actions.fetchError(dispatch,TO_MANY_INVALID_SIGNIN_ATTEMPTS)
                        }
                    } else {
                        actions.fetchError(dispatch,SOMETHING_WENT_WRONG)
                    }
                });
        } else {
            actions.fetchError(dispatch,PLEASE_ENTER_EMAIL_AND_PASSWORD)
        }
    };

    return (
        <div className={styles.signInpage}>
            {state.loading && <Spinner />}
            {isForgotPasswordOpen && <ForgotPassword handleExitClick={handleForgotPassword}/>}
            {error.length > 0 ? <ErrorPopUp message={error} handleDismissClick={() => setError("")}/> : null}
            <div className={styles.rowLogo}>
                <img src={IMAGE_PATH + "OYO_Lettermark.svg"} alt="OYO" />
            </div>
            <div className={styles.rowContent}>
                <div className={styles.col1}>
                    <img src={IMAGE_PATH + "img_login.svg"} alt="main" />
                </div>
                <div className={styles.col2}>
                    <div className={styles.signIn_sec}>
                        <form className={styles.signIn_form}>
                            <h3>Sign in to Task Planner</h3>
                            <p className={styles.error}>{state.errorMessage}</p>
                            <input
                                type="text"
                                id="email"
                                className="mar-tb-10 pad-12"
                                value={user.email}
                                placeholder="Username (abc@oyorooms.com)"
                                onChange={inputChangeHandler}
                            />
                            <div className={styles.password}>
                                <input
                                    type={isPasswordOpen ? "text" : "password"}
                                    id="password"
                                    value={user.password}
                                    placeholder="Password"
                                    onChange={inputChangeHandler}
                                />
                                <img
                                    src={isPasswordOpen ? ICON_PATH + "eye.svg" : ICON_PATH + "eye-no.svg"}
                                    alt=""
                                    onClick={() => {
                                        setIsPasswordOpen((isPasswordOpen) => !isPasswordOpen);
                                    }}
                                />
                            </div>
                            <div className={styles.checkbox}>
                                <input
                                    type="checkbox"
                                    name="keepSignedIn"
                                    className="mar-tb-10"
                                    checked={checkboxCheckedStatus}
                                    onChange={() =>
                                        setCheckboxChecked(
                                            (checkboxCheckedStatus) => !checkboxCheckedStatus
                                        )
                                    }
                                />
                                <label>Keep me Signed in</label>
                            </div>
                            <input
                                type="submit"
                                value="Sign in"
                                className="mar-tb-10 pad-12"
                                onClick={handleSubmitClick}
                                style={{cursor:'pointer'}}
                            />
                        </form>
                        <p className={styles.forgot} onClick={handleForgotPassword}>Forgot Password?</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
