import React, {useState,useRef,useEffect} from 'react'
import useOutSideClick from '../../../hooks/useOutSideClick';
import CustomButton from '../buttons/CustomButton';
import styles from './autoCompleteFilter.module.css'
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from "react-i18next";
import axios from 'axios';

const filterStyle = {
    width : "360px",
    height : "224px",
    backgroundColor : "white",
    zIndex : 10,
    display : "flex",
    flexDirection : "column",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    boxShadow: "0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "6px"
}

const optionContainerStyle = {
    width : "100%",
    display : "flex",
    flexDirection : "column",
    rowGap : "16px",
    height : "136px",
    overflow : "auto",
    padding : "16px 0"
}

const optionStyle = {
    width : "100%",
    padding : "0 16px",
    display : "flex",
    columnGap : "16px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color : "black"
}

export default function CheckboxFilter(props) {
    const initialValue = {};
    props.value.forEach((item) => {
        initialValue[item] = true;
    })
    const [optionStatus , setOptionStatus] = useState(initialValue);
    const [optionList, setOptionList] = useState(props.options);
    const [isLoading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const modalRef = useRef();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(props.endPoint) {
            setLoading(true);
            const payload = {
                headers: {
                  Authorization: "Bearer " + token,
                },
            };
            axios.get(props.endPoint,payload)
            .then(res => {
                if(res && res.data) {
                    setOptionList(prevState => {
                        return [...prevState,...res.data.data]
                    });
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error();
            })
        }
    },[])

    const handleOptionClick = (e) => {
        if(optionStatus[e.target.id]) {
            delete optionStatus[e.target.id]
        } else {
            optionStatus[e.target.id] = true;
        }
        setOptionStatus({...optionStatus});
    }

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
    }

    const handleApplyClick = () => {
        let options = {}
        options.taskTypes = Object.keys(optionStatus);
        if(!arrayEquals(props.value,options.taskTypes)) {
            props.handleApplyClick(options);
        } else {
            props.onExit();
        }
    }

    const optionsJsx = optionList.map((item,index) => {
        return <div style={optionStyle} onClick={handleOptionClick} key={item.taskCode} id={item.taskCode}>
            {<img src={(optionStatus[item.taskCode] == true) ? ICON_PATH + "chekbox_selected.svg" : ICON_PATH + "chekbox.svg" } id={item.taskCode} alt=""/>}
            <div id={item.taskCode}>{item.taskTittle}</div>
        </div>
    })

    useOutSideClick(modalRef,props.onExit,true);

    return (
        <div style={filterStyle} ref={modalRef}>
            {
                (isLoading) ? <div className={styles.loading} >{t("Loading.label")}</div>
                : <React.Fragment>
                     <div style={optionContainerStyle}>
                        {
                            (optionsJsx.length > 0) ? optionsJsx : <div className={styles.no_option} >{t("NoResult.label")}</div>
                        }
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.clear} onClick={() => setOptionStatus({})}>{t("Clear.word")}</div>
                        <CustomButton padding="90" buttonName={t("Apply.word")} type="default" onClick={handleApplyClick}/>
                    </div> 
                </React.Fragment>
            }
        </div>
    )
}
