export const ICON_PATH = process.env.PUBLIC_URL + "/assets/icons/";
export const IMAGE_PATH = process.env.PUBLIC_URL + "/assets/images/";

export const SIGNIN = "/"
export const SCHEDULER = "/scheduler";
export const SUMMARY = "/task-summary";
export const STAFF = '/staff';
export const STAFF_DETAILS = "/staff/:id/details";
export const STAFF_EDIT = "/staff/:id/edit";
export const STAFF_BULK_ADD = '/staff/bulkStaff-add';
export const STAFF_BULK_UPLOAD = '/staff/bulkStaff-add/upload';
export const FORGOT_PASSWARD = "/forgot-password";
export const RESET_PASSWARD = "/reset-password";

export const ADD_TASK_BFF = "/api/tasks/create-task";
export const REASSIGN_TASK_BFF = "/api/task-summary/reassign";
export const ASSIGN_STAFF_BFF = "/api/scheduler/assign";
export const TASK_SUMMARY_BFF= "/api/task-summary";
export const ADD_STAFF_BFF = "/api/staff/addStaff";
export const STAFF_BFF = "/api/staff";
export const STAFF_DETAILS_BFF = "/api/staff/details";
export const STAFF_LEAVE_BFF = "/api/staff/leaves";
export const STAFF_ASSIGN_TO_MANAGER_BFF = "/api/staff/assign";
export const STAFF_EDIT_BFF = "/api/staff/editStaff";
export const SCHEDULER_BFF = "/api/scheduler";
export const ASSIGN_STAFF_SUMMARY_BFF = "/api/task-summary/assign"; 
export const TASK_TYPE_BFF = "/api/scheduler/task-types";
export const FORGOT_PASSWARD_BFF = "/api/signin/forgot-password";
export const RESET_PASSWARD_BFF = "/api/signin/reset-password";
