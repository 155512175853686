import React, {useState} from 'react'
import styles from './groupStaff.module.css';
import StaffRow from './StaffRow'
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

export default function GroupStaff (props) {
    const [isDropdownOpen, setDropdown] = useState(true);
    const { t, i18n } = useTranslation()
    
    const staffJsx = [];
    for(const [key,value] of Object.entries(props.stafData.staffList)) {
        staffJsx.push(<StaffRow currentSize={props.currentSize} taskSize={props.taskSize} maxSize={props.maxSize} staffDetails={value} groupType={props.groupType} key={value.id} callBacks={props.callBacks}/>)
    }
    
    return (
        <div className={styles.group}>
            <div className={styles.display} onClick={() => {setDropdown(!isDropdownOpen)}}>
                <div className={styles.left_sec}>
                    <p className={styles.large_font}>{props.stafData.staffType}</p>
                    <p className={styles.small_font}>{`( ${props.stafData.availableStaffs} ${t("Available.word")} )`}</p>
                </div>
                <img src={ICON_PATH + "chevron-up.svg"} className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]} alt=""/>
            </div>
            {    
                (isDropdownOpen) ?
                <div  className={styles.hidden}>
                    {staffJsx}
                </div>
                : null
            }
        </div>
    )
}
