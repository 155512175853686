import React ,{useState,useRef} from 'react'
import CustomButton from '../buttons/CustomButton';
import styles from './autoCompleteFilter.module.css'
import Slider from 'react-custom-slider';
import useOutSideClick from '../../../hooks/useOutSideClick'
import { useTranslation } from "react-i18next";

const filterStyle = {
    height : "214px",
    width : "360px",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    boxShadow: "0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "6px",
    display : "flex",
    flexDirection : "column"
}
const sliderContainerStyle = {
    height : "139px",
    width : "100%",
    display : "flex",
    flexDirection : "column",
    alignItems : "center" ,
    justifyContent : "center"
}

const sliderStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "296px"
}

const valueLabelStyle = {
    borderRadius: "4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    letterSpacing: "0.2px",
    padding : "4px 8px",
    width : "fit-content",
    height : "24px",
    boxSizing : "border-box",
}

const trackStyle = {
    height : "4px",
}

const handlerStyle = {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
}

export default function SliderFilter(props) {
    const initialValue = (props.value) ? parseInt(props.value) : 0;
    const [distance, setDistance] = useState(initialValue);
    const modalRef = useRef();
    const { t, i18n } = useTranslation();

    const handleApplyClick = () => {
        props.handleApplyClick(distance);
    }

    useOutSideClick(modalRef,props.onExit,true);

    return (
        <div style={filterStyle} ref={modalRef}>
            <div style={sliderContainerStyle}>
                <Slider
                    defaultValue={distance}
                    showValue={true}
                    valueLabelStyle={valueLabelStyle}
                    value={distance}
                    min={0}
                    max={50}
                    stepSize={1}
                    trackShape='rounded'
                    trackColor="#F2F2F2"
                    trackStyle={trackStyle}
                    handlerStyle={handlerStyle}
                    onChange={(value) => setDistance(value)}
                    valueRenderer={(value) => `${value} Kms`}
                    showMarkers={"active"}
                    handlerWidth={24}
                    handlerHeight={24}
                    style={sliderStyle}
                    fillColor="#2298DA"
                    valueLabelColor="#2298DA"
                    valueLabelPadding={20}
                    trackLength={296}
                />
            </div>
             <div className={styles.footer}>
                <div className={styles.clear} onClick={() => setDistance(0)}>{t("Clear.word")}</div>
                <CustomButton padding="90" buttonName={t("Apply.word")} type="default" onClick={handleApplyClick}/>
            </div> 
        </div>
    )
}
