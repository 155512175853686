import React ,{useState,useRef, useReducer}from 'react'
import styles from './upload.module.css';
import Navbar from '../../common/navbar/Navbar'
import Sidebar from '../../common/sidebar/Sidebar'
import BulkHeader from './BulkHeader';
import CustomButton from '../../common/buttons/CustomButton';
import axios, {CancelToken,isCancel} from 'axios';
import Button from '../../common/buttons/Button';
import {ICON_PATH} from '../../../helper/pathConstants';
import Spinner from "../../common/spinner/Spinner";
import {HttpReducer,loaderInitialState} from "../../../reducers/SchedulerReducer"
import ProfileCreatedModal from './ProfileCreatedModal';
import {SERVICE_TEMPORARILY_UNAVAILABLE,PROFILE_NOT_CREATED} from '../../../helper/text_EN'
import { useTranslation } from 'react-i18next'



const pageIndex = 3;

export default function Upload() {
    const [state, setState] =useState({
        percentage : 0,
        uploadStatus : "notStarted"
    });
    const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
    const [isProfileCreatedModalOpen, setProfileCreatedModal] = useState(false);
    const [response,setResponse] = useState("");
    const uploadRef = useRef();
    const cancelRef = useRef();
    const { t, i18n } = useTranslation()
    const token = localStorage.getItem("token");

    const uploadFile = (e) => {
        e.preventDefault();
        console.log(e.target.files[0]);
        let data = new FormData();
        data.append( 'file', e.target.files[0]);

        const options = {
            onUploadProgress: (progressEvent) => {
              const {loaded, total} = progressEvent;
              let percent = Math.floor( (loaded * 100) / total )
              if( percent < 100 ){
                setState({
                    uploadStatus : "inProgress", 
                    percentage: percent 
                })
              }
            },

            cancelToken : new CancelToken(cancel => cancelRef.current = cancel),
            headers: {
                Authorization: "Bearer " + token,  
                "Content-Type": "multipart/form-data",          
            }
        }
        axios.post("/api/staff/bulkupload", data, options).then(res => { 
            setState({ uploadStatus: "finished", percentage: 100 });
        }).catch(error => { 
            alert(error);
            if(isCancel) {
                console.log(error);
            }
            setState({
                percentage : 0,
                uploadStatus : "notStarted"
            })
        });
    }

    const submitFile = () => {
        loaderDispatch({ type: "FETCH_START" });
        const payload = {
            headers: {
              Authorization: "Bearer " + token,
            },
        };
        axios.post("/api/staff/bulkuploadSubmit",null, payload).then(res => { 
            if(res && res.data.message) {
                setResponse(res.data.message)
            } else {
                setResponse(PROFILE_NOT_CREATED);
            }
            loaderDispatch({ type: "FETCH_SUCCESS"});
            setProfileCreatedModal(true);
        }).catch(error => { 
            setResponse((error.response.data && error.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE)
            loaderDispatch({ type: "FETCH_ERROR" }); 
            setProfileCreatedModal(true);
        });
        
    }
    const restartUpload = () => {
        setState({
            percentage : 0,
            uploadStatus : "notStarted"
        })
    }

    const cancelUpload = () => {
        if(cancelRef.current) {
            cancelRef.current();
        }
    }

    const displayDecider = () => {
        if(state.uploadStatus === "notStarted") {
            return <div className={styles.button_sec}>
                <input type="file" style={{display:"none"}} name="bulk_staff" onChange={uploadFile} accept=".csv" ref={uploadRef}/>
                <Button type="default" image={ICON_PATH + "upload.svg"} buttonName={t("UploadFile.button")} padding="43" onClick={() => uploadRef.current.click()}/>
                <div className={styles.button_label}>{t("Drag&Drop.label")}</div>
            </div>
        } else if (state.uploadStatus === "inProgress") {
            return <div className={styles.button_sec}>
                <CustomButton type="default" color="green" buttonName={`${t("Uploading.button")} - ${state.percentage}%`} padding="36" onClick={null}/>
                <div className={styles.cancel_link} onClick={cancelUpload}>{t("CancelUpload.button")}</div>
            </div>
        } else {
            return <div className={styles.button_sec}>
                <Button type="default" color="green" image={ICON_PATH + "succes_icon.svg"} buttonName={t("Uploaded.button")} padding="43" onClick={null}/>
                <div className={styles.cancel_link} onClick={restartUpload}>{t("Upload&Replace.button")}</div>
            </div>
        }
    }

    return (
        <div className={styles.upload_page}>
            <Navbar/>
            <Sidebar index={pageIndex}/>
            {(isProfileCreatedModalOpen)? <ProfileCreatedModal message={response}/>: null}
            {loaderState.loading
                ? <Spinner /> 
                :<div className={styles.main_content}>
                    <BulkHeader/>
                    <div className={styles.main}>
                    
                        <div className={styles.upload}>
                            {displayDecider()}
                        </div>                   
                        <div className={styles.label}>
                            {t("SupportedFile.label")}
                            <p>csv</p>
                        </div>
                        {(state.uploadStatus === "finished") 
                            ? <CustomButton type="colored" color="red" padding="34" buttonName={t("CreateProfile.button")} onClick={submitFile}/>
                            : null
                        }
                    </div>
                </div>
            }       
        </div>
    )
}
