import React, { Suspense,useEffect} from 'react';
import './App.css';
import { Route, Switch } from "react-router-dom";
import SignIn from './components/signIn/SignIn';
import Scheduler from './components/scheduler/Scheduler';
import ProtectedRoute from './components/common/protectedRoute/ProtectedRoute';
import Spinner from './components/common/spinner/Spinner';
import TaskSummary from './components/tasksummary/TaskSummary';
import Staff from './components/staff/Staff';
import PersonHeader from './components/staff/personal-details/PersonHeader'
import BulkStaffAdd from './components/staff/bulkStaff-addition/BulkStaffAdd';
import Upload from './components/staff/bulkStaff-addition/Upload';
import EditInfo from './components/staff/personal-details/EditInfo';
import {SIGNIN,SCHEDULER,STAFF,STAFF_BULK_ADD,STAFF_BULK_UPLOAD,STAFF_DETAILS,STAFF_EDIT,SUMMARY,RESET_PASSWARD} from './helper/pathConstants';
import ErrorPage from './components/common/error/404';
import ChangePassword from './components/signIn/ChangePassword';

function App() {

  return (
    <div className="App">
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route path={SIGNIN} exact component={SignIn} />
          <Route path={RESET_PASSWARD} exact component={ChangePassword} />
          <ProtectedRoute path={SCHEDULER} exact component={Scheduler} />
          <ProtectedRoute path={SUMMARY} exact component={TaskSummary} />
          <ProtectedRoute path={STAFF} exact component={Staff} /> 
          <ProtectedRoute path={STAFF_DETAILS} exact component={PersonHeader}/>
          <ProtectedRoute path={STAFF_EDIT} exact component={EditInfo}/>
          <ProtectedRoute path={STAFF_BULK_ADD} exact component={BulkStaffAdd}/>
          <ProtectedRoute path={STAFF_BULK_UPLOAD} exact component={Upload}/>
          <ProtectedRoute path={""} exact component={ErrorPage}/>
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
