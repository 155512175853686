import React ,{useState} from 'react';
import styles from './commonModal.module.css'
import Modal from '../../common/modal/Modal';
import AutoComplete from '../../common/autoComplete/AutoComplete';
import CustomButton from '../../common/buttons/CustomButton';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'



export default function ChangeManager(props) {
    const [manager, setManager] = useState({});
    const { t, i18n } = useTranslation()
    

    const handleOptionClick = (key,value) => {
        setManager(value);
    }

    const handleSubmitClick = () => {
        if(manager.id) {
            props.handleSubmitClick(manager.id);
            props.handleExitClick();
        }
    }

    

    return (
        <Modal>
            <div className={styles.main_content} >
                <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={props.handleExitClick} alt=""/>
                <div className={styles.header}>
                    {t("AssignStaffTo.label")}
                </div>
                <div className={styles.middle}>
                    <AutoComplete placeholder={t("ChooseManager.label")} role="ADMIN,MANAGER" value={manager.name} name={"manager"} width="504" endPoint="staff"  handleOptionClick={handleOptionClick} error={{}}/>
                </div>
                <div className={styles.footer}>
                    <CustomButton type="default" buttonName={t("Cancel.button")} padding="62" onClick={props.handleExitClick}/>
                    <CustomButton type="colored" color={(!manager.id) ?"blocked":"blue"} buttonName={t("Submit.button")} padding="125" onClick={handleSubmitClick}/>
                </div>
            </div>
        </Modal>
    )
}
