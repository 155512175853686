import React from 'react';
import './commonStyle.css';
import {staffDetailsMap} from '../../../helper/dataConstants'

const dotStyle = {
    width : "8px",
    height : "8px",
    borderRadius : "50%"
}

const colors = {
    ACTIVE : "#00B28A",
    RESIGNED : "#EE2A24",
    ON_LEAVE : "#DF9641"
}

export default function EmployeeDetails(props) {

    const displayJsx = [];
    for(const[key,value] of Object.entries(staffDetailsMap)) {
        let rowJsx = Object.entries(value).map((item,index) => {
            return <div className="sub_block" key={index}>
                <p className="key">{item[1]}</p>
                {
                    (item[0]==="status") 
                    ? <div className="status">
                        <div style={{...dotStyle,backgroundColor : colors[props.employeeDetails[item[0]]]}}/>
                        <p className="value">{props.employeeDetails[item[0]]}</p>
                    </div>
                    : <p className="value">{props.employeeDetails[item[0]]}</p>
                }
            </div>
        })
        displayJsx.push(
            <div className="block" key={key}>
                <div className="sub_heading">
                    {key}
                </div>
                <div className="container">
                    {rowJsx}
                </div>
            </div>
        )
    }

    return (

        <div className="details_sec">
            {displayJsx}
        </div>
    )
            
}
