import React from 'react';
import styles from './sectionheader.module.css';
import Button from "../buttons/Button"
import CalendarDisplay from '../calendar/CalendarDisplay';
import { useTranslation } from 'react-i18next'
import {ICON_PATH} from '../../../helper/pathConstants'
import AutoComplete from '../autoComplete/AutoComplete';

export default function SectionHeader(props) {
    const { t, i18n } = useTranslation()
   
    return (
        <div className={styles.section_header}>
            <div className={styles.left_sec}>
                <p>{t(`${props.pageTitle}.label`)}</p>
                {
                    (props.pageTitle !== "Scheduler") ?
                    <AutoComplete name="userId" value={props.searchValue} handleOptionClick={props.handleSearchClick} placeholder={t("Search.label")} role={`STAFF${(props.pageTitle === "Staff") ? ",MANAGER" : ""}`} initial={true} endPoint="staff" width="304" error={{}}  errorMessage={t("FieldError.message")}/>
                    : null

                }
            </div>
            <div className={styles.right_sec}>
                {(props.pageTitle !== "Staff") ? <CalendarDisplay handleDateClick={props.handleDateClick} dateRange={props.dateRange}/> : null}
                <Button padding="0" type="default" image={ICON_PATH + props.buttonIcons[0]} buttonName={t(`${props.buttonNames[0]}.button`)} onClick={props.handleButtonClick[0]}/>
                {
                    (props.buttonNames[1] !== "Publish") ?
                    <Button padding="0" type="colored" color="red" image={ICON_PATH + props.buttonIcons[1]} buttonName={t(`${props.buttonNames[1]}.button`)} onClick={props.handleButtonClick[1]}/>
                    : null
                }
                
            </div>
        </div>
    )
}
