import React,{useState, useReducer, useEffect} from 'react';
import axios from "axios";
import styles from './staff.module.css';
import Navbar from '../common/navbar/Navbar';
import Sidebar from '../common/sidebar/Sidebar';
import SectionHeader from '../common/sectionHeader/SectionHeader';
import StaffTable from './staff-table/Table';
import {HttpReducer,loaderInitialState} from "../../reducers/SchedulerReducer"
import AddStaff from './add-staff/AddStaff';
import { useHistory} from 'react-router-dom';
import {staff} from '../../helper/pageConstants';
import Spinner from "../common/spinner/Spinner";
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE,SOMETHING_WENT_WRONG,PLEASE_TRY_AGAIN} from "../../helper/text_EN";
import ErrorMessage from '../common/error/ErrorMessage';
import ErrorPopUp from '../common/error/ErrorPopUp';
import moment from 'moment';
import {STAFF_BFF,STAFF_BULK_ADD,ADD_STAFF_BFF} from '../../helper/pathConstants'
import Pagination from '../common/pagination/Pagination'



export default function Staff(props) {
    let pageData = localStorage.getItem('staffPayloadPage') && JSON.parse(localStorage.getItem('staffPayloadPage'));
    let size = (props.location.state && props.location.state.size) || (pageData && pageData.size) ||10;
    let page = (props.location.state && props.location.state.page) || (pageData && pageData.page) ||1;
    const [isAddStaffOpen, setIsAddStaffOpen] = useState(false);
    const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
    const [StaffData,setStaffData] = useState([]);
    const [reload, setReload] = useState(false);
    const [pagination, setPagination] = useState({
      size : size,
      page : page,
      totalPages : 0,
      startingRecordNo : 0,
      totalRecordNo : 0,
      endingRecordNo : 0
    });

    const [staffId,setStaffId] = useState({});

    const [error , setError] = useState({
      loadingError : "",
      actionError : ""
    });
    const history = useHistory();

    const handleAddStaffClick = () => {
        setIsAddStaffOpen(!isAddStaffOpen);
    }

    const handleBulkAddClick = () => {
        history.push(STAFF_BULK_ADD);
    }

    const handlePagination = (value) => {
        setPagination(prevState => {
            let payloadParamsPage = {
              ...prevState,
              ...value
            }
            localStorage.setItem("staffPayloadPage", JSON.stringify(payloadParamsPage));
            return payloadParamsPage;
        })
    }

    const handleRowClick = (e) => {
        e.preventDefault();
        history.push({
          pathname : `/staff/${e.target.id}/details`,
          state : {
            page : pagination.page,
            size : pagination.size
          }
        })
    }

    const handleSearch = (key,value) => {
      if(value.id) {
        setPagination(prevState => {
          let payloadParamsPage = {
            ...prevState,
            size : 1,
            page : 1
          }
          return payloadParamsPage;
      })
      } else {
        setPagination(prevState => {
          let payloadParamsPage = {
            ...prevState,
            size : size,
            page : page
          }
          return payloadParamsPage;
      })
      }
      setStaffId(value);
    }

    const handleDismissError = () => {
      setError(prevState => {
        return {
          ...prevState,
          actionError : ""
        }
      })
    }

    const token = localStorage.getItem("token");
    const payload = {
        headers: {
          Authorization: "Bearer " + token,
        },
    };
    useEffect(() => {
        loaderDispatch({ type: "FETCH_START" });
        payload.params = {
          page : pagination.page,
          size : pagination.size,
          userId : staffId.id
        }
        axios
          .get(STAFF_BFF, payload)
          .then((res) => {
            if(res.data) {
              if(res.data.message === SUCCESSFUL) {
                setError({
                  loadingError : "",
                  actionError : ""
                }); 
                const localOffice = localStorage.getItem("localOfficeName");
                res.data.data.forEach(item => {
                  item["locale office"] = localOffice;
                  return item;
                })   
                setStaffData(res.data.data); 
                const pageDetails = res.data.pageDetails;
                setPagination(prevState => {
                  return {
                    ...prevState,
                    ...pageDetails
                  }
                })   
              } else {
                setError({
                  ...error,
                  loadingError : res.data.message
                });
              }
            } else {
              setError({
                ...error,
                loadingError : SOMETHING_WENT_WRONG
              });
            }
            loaderDispatch({ type: "FETCH_SUCCESS"});
          })
          .catch(err=>{
              setError({
                ...error,
                loadingError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
              });
              loaderDispatch({ type: "FETCH_ERROR" });          
            }
          );
      }, [pagination.page,pagination.size,reload,staffId.id]);

      const addStaff = (details) => {
        const joiningDate = moment(details.joiningDate).format("YYYY-MM-DD").toString();
        const data = {
          ...details,
          manager : details.manager.id,
          joiningDate : joiningDate,
          localOffice : localStorage.getItem("legecyId")
        }
        loaderDispatch({ type: "FETCH_START" });
        axios.post(ADD_STAFF_BFF,data,payload)
        .then(res => {
          if(res && res.data) {
            if(res.data.message === SUCCESSFUL) {
              setReload(true);
              // const newStaff = res.data.data;
              // newStaff.manager = newStaff.manager || managerName;
              // newStaff.managerId = (newStaff.manager) ? null : details.manager.id;
              // setStaffData(prevState => {
              //   return [
              //     ...prevState,
              //     newStaff
              //   ]
              // })
            } else {
              setError({
                ...error,
                actionError : res.data.message
              });
            }
          } else {
            setError({
              ...error,
              actionError : PLEASE_TRY_AGAIN
            });
          }
          loaderDispatch({ type: "FETCH_SUCCESS"});
        })
        .catch(err=>{
          loaderDispatch({ type: "FETCH_ERROR" }); 
          setError({
            ...error,
            actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
          });
                   
        }
      );

      }
      const handleReloadPageClick = () => {
        localStorage.setItem("staffPayloadPage","");
        window.location.reload();
      }  
 
    let displayJsx = null;
    if (isAddStaffOpen) {
        displayJsx = <AddStaff onExitClick={handleAddStaffClick} addStaff={addStaff}/>;
    } else {
        displayJsx = (error.loadingError.length > 0) ? <ErrorMessage message={error.loadingError} reloadPageClick={handleReloadPageClick}/> :<React.Fragment>
            <SectionHeader 
                pageTitle={staff.pageTitle} 
                buttonNames={staff.buttons} 
                buttonIcons={staff.buttonIcons} 
                searchValue={staffId.name}
                handleSearchClick={handleSearch}
                handleButtonClick={[handleBulkAddClick,handleAddStaffClick]}
            />
            <div className={styles.main_content}>
                <StaffTable data={StaffData} tableHeader={staff.tableHeader} onClick={handleRowClick} maxWidth="280px"/>
            </div>
            <Pagination size={pagination.size} startingRecordNo={pagination.startingRecordNo} endingRecordNo={pagination.endingRecordNo} totalRecordNo={pagination.totalRecordNo} current={pagination.page} totalPages={pagination.totalPages} handlePagination={handlePagination}/>
        </React.Fragment>
        
    }

    return (
        <div className={styles.staff_page}>
            <Navbar/>
            <Sidebar index={staff.pageIndex}/>
            {error.actionError.length > 0 ? <ErrorPopUp message={error.actionError} handleDismissClick={handleDismissError}/> : null}
            {loaderState.loading ? <Spinner /> : displayJsx}
        </div>
    )
}
