import React,{useState,useRef,useEffect} from 'react';
import useOutsideClick from '../../../hooks/useOutSideClick';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from "react-i18next";
import axios from 'axios';

const textBoxStyle = {
    boxSizing: "border-box",
    borderRadius: "6px",
    height : "40px",
    padding : "8px 12px",
    color: "#212121",
    position : "relative",
    display : "flex",
    alignItems : "center"
}

const displayStyle = {
    padding : 0,
    border : "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    flexGrow : 1,
    outline : "none"
}

const optionItemStyle = {
    height : "40px",
    boxSizing : "border-box",
    padding : " 8px 40px",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor : "white",
    width : "100%",
    borderBottom : "1px solid rgba(0,0,0,0.12)",
    display :"flex"
}

const optionStyle = {
    display : "flex",
    flexDirection : "column",
    backgroundColor : "white",
    overflow : "auto",
    position : "absolute",
    top : "40px",
    left : 0,
    width : "100%",
    minHeight :"fit-content",
    maxHeight : "165px",
    zIndex : 100,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: "6px",
    listStyleType: "none",
    margin : 0,
    padding : 0
}

const floatBoxStyle = {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: "0.2px",
    margin : 0,
    boxSizing: "border-box",
    backgroundColor: "white",
    padding :"0 4px",
    position : "absolute",
    top : "-8px",
    left : "8px"

}

const erroeStyle = {
    position : "absolute",
    bottom : "-8px",
    left : "12px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}

export default function TextTypeTextBox(props) {
    const [isDropdownOpen, setDropdown] = useState(false);
    const [optionStatus , setOptionStatus] = useState(props.value);
    const dropdownRef = useRef();
    const { t, i18n } = useTranslation();
    const [optionList, setOptionList] = useState(props.options);
    const [isLoading, setLoading] = useState(false);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if(props.endPoint) {
            setLoading(true);
            const payload = {
                headers: {
                  Authorization: "Bearer " + token,
                },
            };
            axios.get(props.endPoint,payload)
            .then(res => {
                if(res && res.data) {
                    setOptionList(prevState => {
                        return [...prevState,...res.data.data]
                    });
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error();
            })
        }
    },[])

    const handleDropdown = () => {
        setDropdown(!isDropdownOpen);
    }

    const handleOptionClick = (e) => {
        e.preventDefault();
        props.handleOptionClick(props.name,e.target.id);
        setOptionStatus(e.target.dataset.title);
        setDropdown(!isDropdownOpen);
    }
    
    const seletionColorPicker = (item) => {
        if(item === props.value) {
            return "#2298DA";
        } else {
            return "#212121";
        }
    }

    const optionsJsx = optionList.length && optionList.map((item,index) => {
        return<li key={index} style={{...optionItemStyle,color : seletionColorPicker(item.taskCode)}} id={item.taskCode} >
            <span style={{flexGrow : 1}} id={item.taskCode} data-title={item.taskTittle}>{item.taskTittle}</span>
            {(item.taskCode == props.value) ? <img src={ICON_PATH + "check-mark.svg"} alt="" id={item.taskCode} /> : null}
            </li>
    })
    const displayColorPicker = () => {
        if(props.value.length > 0) {
            return "#212121";
        } else {
            return "rgba(0, 0, 0, 0.5)";
        }
    }

    useOutsideClick(dropdownRef,handleDropdown,isDropdownOpen);
    return (
        <div style={{...textBoxStyle,width: `${props.width}px`,border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}}  ref={dropdownRef}>
            <div style={{...displayStyle,color : displayColorPicker()}} onClick={handleDropdown}>
                {(optionStatus.length > 0) ? optionStatus: props.placeholder}
            </div>
            <img src={ICON_PATH + "dropdown.svg"} alt="" onClick={handleDropdown}/>
            {(isDropdownOpen) ?
                (isLoading)? <div>{"isLoading.."}</div>
                    :<ul style={optionStyle} onClick={handleOptionClick} >
                        {optionsJsx}
                    </ul>
                : null}
            {(optionStatus.length > 0) ?<p style={floatBoxStyle}>{props.placeholder}</p> : null}
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null}             
        </div>
    )
}
