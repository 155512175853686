import React, {useState,useRef}from 'react';
import styles from './selectionHeader.module.css';
import Button from '../common/buttons/Button'
import { useTranslation } from 'react-i18next'
import useOutSideClick from '../../hooks/useOutSideClick';
import {ICON_PATH} from '../../helper/pathConstants'

export default function SelectionHeader(props) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { t, i18n } = useTranslation()
    const dropDownRef = useRef();
    

    useOutSideClick(dropDownRef,() => setDropdownOpen(false),isDropdownOpen);

    return (
        <div className={styles.header}>
            <div className={styles.left_sec}>
                <div className={styles.selection}>
                    <img src={ICON_PATH + "selection.svg"} alt="" onClick={() => setDropdownOpen(!isDropdownOpen)} className={styles.border_image}/>
                    {
                        (isDropdownOpen) ?
                        <div className={styles.dropdown} ref={dropDownRef} onClick={(e)=>props.callBacks.handleDropdownOperation(e.target.value)}>
                            <li value={0}>{`${t('SelectAlltask.option')}`}</li>
                            <img src={ICON_PATH + "dropdown_divider.svg"} alt=""/>
                            <li value={1}>{`${t('None.option')}`}</li>
                            <img src={ICON_PATH + "dropdown_divider.svg"} alt=""/>
                            <li value={2}>{`${t('SelectUnassignedTasks.option')}`}</li>
                            <img src={ICON_PATH + "dropdown_divider.svg"} alt=""/>
                            <li value={3}>{`${t('SelectassignedTasks.option')}`}</li>
                        </div> 
                        : null
                    }
                </div>
                <div>{`${props.selectedTaskNo} ${t('TaskSelected.label')}`}</div>
            </div>
            <div className={styles.right_sec}>
                <Button padding="16" type="default" image={ICON_PATH + "blue_cross.svg"} buttonName={`${t('Cancel.button')}`} onClick={() =>props.callBacks.handleDropdownOperation(1)}/>
                <Button padding="16" type="colored" color ="blue" image={ICON_PATH + "white_plus.svg"} buttonName={`${t('AssignStaff.button')}`} onClick={props.callBacks.handleModalClick}/>
            </div>
        </div>
    )
}
