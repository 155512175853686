import React from 'react'
import CustomButton from '../buttons/CustomButton';
import styles from './addingSectionHeader.module.css';
import {ICON_PATH} from '../../../helper/pathConstants'

export default function AddingSectionHeader(props) {

    return (
        <div className={styles.header}>
                <div className={styles.left_sec}>
                    <img src={ICON_PATH + "arrow-left.svg"} style={{cursor:'pointer'}} onClick={props.onExitClick} alt="<"/>
                    <p>{props.title}</p>
                </div>
                <CustomButton type="colored" color="red" buttonName="Submit" padding="54" onClick={props.onSubmitClick}/>
            </div>
    )
}
