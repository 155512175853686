import React from 'react'
import {ICON_PATH} from '../../../helper/pathConstants'

const taskTagStyle = {
    width:"fit-content",
    height:"32px",
    boxSizing:"border-box",
    padding:"4px 8px",
    display:"flex",
    columnGap:"8px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    flexWrap : 'wrap'
};
const nameBlock = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "black",
    height: "24px",
    width : "fit-content"
}

const completedNameBlock = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.5)",
    height: "24px",
    width : "fit-content"
}

export default function TaskTag(props) {
    
    const handleAssignStaffClick = () => {
        props.callBacks.setSelectedTask({
            taskId : props.data.taskId,
            propertyId : props.propertyId,
            staffId : props.staffId
        });
        props.callBacks.handleModalClick();
    }
    

    return (
        <div style={taskTagStyle}>
            {
                (props.data.status === "completed") ?
                    <img src={ICON_PATH + "status_positive.svg"} alt="" />
                : null
            }
            <div style={(props.data.status === "completed") ? completedNameBlock :nameBlock}>{props.data.taskName}</div>
            {(props.staffId == null) ?
                <img src={ICON_PATH + "user-plus.svg"} style={{cursor:'pointer'}} alt="" onClick={handleAssignStaffClick}/>
                :
                (props.data.status !== "completed") ?
                <React.Fragment>
                    <img src={ICON_PATH + "shuffle.svg"} style={{cursor:'pointer'}} alt="" onClick={handleAssignStaffClick}/>
                    <img src={ICON_PATH + "x.svg"} alt="" style={{cursor:'pointer'}} onClick={() =>
                        props.callBacks.unAssignTask(props.staffId,props.propertyId,props.data.taskId)
                    }/>
                </React.Fragment>
                : null
            }
        </div>
    )
}
