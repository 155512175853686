import React,{useState,useRef} from 'react';
import OptionTag from './OptionTag';
import useOutsideClick from '../../../hooks/useOutSideClick';
import axios from 'axios';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from "react-i18next";

const autoCompleteStyle = {
    boxSizing: "border-box",
    borderRadius: "6px",
    height : "40px",
    padding : "8px 12px",
    color: "#212121",
    position : "relative",
    display : "flex",
    columnGap : "16px"
}


const inputStyle = {
    padding : 0,
    border : "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    flexGrow : 1,
    outline : "none"
}

const optionItemStyle = {
    height : "48px",
    boxSizing : "border-box",
    padding : "12px 40px",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#212121",
    backgroundColor : "white",
    minWidth : "100%",
    width : "fit-content",
    borderBottom : "1px solid rgba(0,0,0,0.12)",
    whiteSpace : "nowrap",
}

const optionStyle = {
    display : "flex",
    flexDirection : "column",
    backgroundColor : "white",
    rowGap : "1px",
    overflow : "auto",
    position : "absolute",
    top : "44px",
    left : 0,
    minWidth : "100%",
    width : "auto",
    minHeight :"fit-content",
    maxHeight : "195px",
    zIndex : 100,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: "6px"
}

const containerStyle = {
    width : "100%",
    height : "100%",
    boxSizing : "border-box",
    padding : "8px",
    display : "flex",
    position : "absolute",
    top : 0,
    left : 0,
    alignItems : "center",
    zIndex : 10
}

const floatBoxStyle = {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: "0.2px",
    margin : 0,
    boxSizing: "border-box",
    backgroundColor: "white",
    padding :"0 4px",
    position : "absolute",
    top : "-8px",
    left : "8px"

}
const erroeStyle = {
    position : "absolute",
    bottom : "-8px",
    left : "12px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}

export default function AutoComplete(props) {
    const initialValue = (props.initial && props.value) ? props.value : "";
    const [search, setSearch] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedOption , setselectedOption] = useState(initialValue);
    const optionRef = useRef();
    const token = localStorage.getItem("token");
    const localOffice = localStorage.getItem("localOffice");
    const [isLoading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const handleOptionClick = (e) => {
        e.preventDefault();
        setselectedOption(e.target.dataset.name);
        props.handleOptionClick(props.name,e.target.dataset);
        setSearch("");
    }

    const handleCrossClick = () => {
        setselectedOption("");
        props.handleOptionClick(props.name,{});
        setSuggestions([]);
    }

    const debounce =(func,delay) => {
        let timer;
        return function() {
            let context = this;
            let args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(context,args),delay);
        };
    }

    const handleSearchChange = (event) => {
        event.preventDefault();
        setLoading(true);
        const value = event.target.value;
        setSearch(value);
        const payload = {
            headers: {
              Authorization: "Bearer " + token,
            },
            params : {
                search : value,
                localOffice : localOffice,
                roles : props.role,
                status : props.status
              }
          };
        debounce(() => {
            axios.get(`/api/autocomplete/${props.endPoint}`,payload)
            .then(res => {
                if(res && res.data) {
                    setSuggestions(res.data.result);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error();
            })
        },1000)();
    }

    const suggetionsJsx = suggestions.map((item,index) => {
        return <div key={item.id} style={optionItemStyle} onClick={handleOptionClick} id={item.id} data-id={item.id} data-optional={item.optional} data-name={item.name} >
                <p style={{margin:0,width:"fit-content"}} id={item.id} data-id={item.id} data-optional={item.optional} data-name={item.name}>{item.name} <span style={{color: "rgba(0, 0, 0, 0.5)"}} id={item.id} data-id={item.id} data-optional={item.optional} data-name={item.name}>{(item.optional != null) ? `(${item.optional})` : ""}</span></p>
            </div>
    })

    useOutsideClick(optionRef,() => setSearch(""),true)

    return (
        <div style={{...autoCompleteStyle,width:`${props.width}px`,border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}} ref={optionRef}>
            <img src={ICON_PATH + "search.svg"} alt=""/>
            <input style={inputStyle} type="text" placeholder={props.placeholder} value={search} onChange={handleSearchChange}/>
            {
                (search.length > 0) ?
                <div style={optionStyle} >
                   {(isLoading)? <div style={optionItemStyle} >{t("Loading.label")}</div>:
                    (suggetionsJsx.length > 0) ? suggetionsJsx : <div style={optionItemStyle} >{t("NoResult.label")}</div>
                    }
                </div>
                : null
            }
            { 
                (selectedOption.length > 0) ?
                <div style={containerStyle} >
                   <OptionTag data={selectedOption} onCrossClick={handleCrossClick}/>
                </div>
                : null
            }
            {(selectedOption.length > 0 || search.length > 0) ?<p style={floatBoxStyle}>{props.placeholder}</p> : null}
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null}    
        </div>
    )
}
