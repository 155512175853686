import React from 'react';
import styles from './errorMessage.module.css';
import {IMAGE_PATH} from '../../../helper/pathConstants'
import CustomButton from '../buttons/CustomButton';
import { useTranslation } from 'react-i18next'


export default function ErrorMessage(props) {
    const { t, i18n } = useTranslation()
    return (
       <div className={styles.error_page}>
           <img src={IMAGE_PATH + "img_error.svg"} alt="" />
           <p className={styles.error_title}>{props.message}</p>
           <CustomButton type="colored" color={"red"} buttonName={t("ReloadPage.button")} padding="76" onClick={props.reloadPageClick}/>
       </div>
    )
}
