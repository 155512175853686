import React ,{useState,useRef}from 'react'
import { useTranslation } from 'react-i18next'
import './languageSelector.css';
import Dropdown from '../dropdowns/Dropdown';
import useOutsideClick from '../../../hooks/useOutSideClick';
import {ICON_PATH} from '../../../helper/pathConstants'
import '../../../i18n';

const languages = {
  en : "English",
  da : "Danish"
}

const LanguageSelector = () => {
  const { t, i18n } = useTranslation()
  const [isLanguageSelectorOpen , setLanguageSelector] = useState(false);
  const languageRef = useRef();

  const changeLanguage = (id) => {
    i18n.changeLanguage(id);
    localStorage.setItem('languageId',languages[id]);
    setLanguageSelector(!isLanguageSelectorOpen);
  }

  useOutsideClick(languageRef,() => setLanguageSelector(false),isLanguageSelectorOpen);

  return (
    <div className = "language-selector" onChange={changeLanguage} onClick={() => setLanguageSelector(!isLanguageSelectorOpen)}>
      <img src={ICON_PATH + "globe.svg"} alt=""/>
      <div className="display_language">{localStorage.getItem('languageId')}</div>
      <img src ={ICON_PATH + "chevron-up.svg"} alt="" className={isLanguageSelectorOpen ?"chevron_up" : "chevron_down"} />
      {
        (isLanguageSelectorOpen) ?
        <div className="dropdown_list" ref={languageRef}>
          <Dropdown data={["English / Global","Danish / dansk"]} handleClicks={[() => changeLanguage("en"),() => changeLanguage("da")]}/>
        </div>
        : null
      }      
    </div>
  )
}

export default LanguageSelector