import React, {useState} from 'react';
import styles from "./addtask.module.css";
import AddingSectionHeader from '../../common/sectionHeader/AddingSectionHeader';
import CustomTextArea from '../../common/textboxes/CustomTextArea';
import AutoComplete from '../../common/autoComplete/AutoComplete';
import TaskTypeTextBox from '../../common/textboxes/TaskTypeTextBox';
import DropdownTextBox from '../../common/textboxes/DropdownTextBox';
import DateBox from '../../common/textboxes/DateBox';
import {getHoursList,getMinutesList,tatList,taskTitleOptions,addTaskInitialState} from '../../../helper/dataConstants';
import {TASK_TYPE_BFF} from '../../../helper/pathConstants';
import { useTranslation } from 'react-i18next'
import formFieldChecker from '../../../helper/formFieldChecker'


export default function AddTask(props) {
    const [taskDetails , setTaskDetails] = useState(addTaskInitialState);
    const { t, i18n } = useTranslation()
    const [blankField, setBlanckField] = useState({});

    const handleOptionClick = (key,value) => {
        setTaskDetails({
            ...taskDetails,
            [key] : value
        })
    }

    const handleSubmitClick = () => {
        let nullFields = formFieldChecker(taskDetails,["taskTitle","property","turnAroundTime","taskDescription","completeBy","minutes","hour"])
        if(!nullFields.isNull) {
            props.onSubmitClick(taskDetails);
            props.onExitClick();
        } else {
            setBlanckField(nullFields);
        }
       
    }

    const handleOnChange = (event) => {
        event.preventDefault();
        setTaskDetails({
            ...taskDetails,
            [event.target.name] : event.target.value
        })
    }


    return (
        <div className={styles.add_task}>
            <AddingSectionHeader title={t("AddTask.button")} onExitClick={props.onExitClick} onSubmitClick={handleSubmitClick}/>
            <div className={styles.add_task_form}>
                <div className={styles.block}>
                    <header className={styles.subheading}>{t("TaskDetails.label")}</header>
                    <TaskTypeTextBox placeholder={t("TaskTitle.word")} value={taskDetails.taskTitle} name="taskTitle" width="770" options={[]} handleOptionClick={handleOptionClick} error={blankField} endPoint={TASK_TYPE_BFF} errorMessage={t("FieldError.message")}/>
                    <CustomTextArea value={taskDetails.taskDescription} width={770} height={96} placeholder={`${t("TaskDescription.word")}`} name="taskDescription" onChange={handleOnChange} superScript={t("TaskDescription.word")} error={blankField} errorMessage={t("FieldError.message")}/>
                    <AutoComplete name="property" value={taskDetails.property} handleOptionClick={handleOptionClick} placeholder={t("Property.word")}  endPoint="property" width="770" error={blankField}  errorMessage={t("FieldError.message")}/>
                    <AutoComplete name="assignTo" role="STAFF" status="ACTIVE" value={taskDetails.assignTo} handleOptionClick={handleOptionClick} placeholder={t("AssignTo.word")} endPoint="staff" width="770" error={blankField}  errorMessage={t("FieldError.message")}/>
                </div>
                <div className={styles.block}>
                    <header className={styles.subheading}>{t("CompleteTask.label")}</header>
                    <DateBox placeholder={t("CompleteBy.word")} value={taskDetails.completeBy} name="completeBy" width="370" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                    <DropdownTextBox placeholder="Turn around time (TAT)" value={taskDetails.turnAroundTime} name="turnAroundTime" options={tatList} width="370" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                    <DropdownTextBox placeholder="Hour" value={taskDetails.hour} width="370" name="hour" options={getHoursList()}  handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                    <DropdownTextBox placeholder="Minutes" value={taskDetails.minutes} width="370" options={getMinutesList()} name="minutes" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                </div>                
            </div>
        </div>
    )
}
