import React ,{useState} from 'react'
import styles from './combinedFilter.module.css'
import { useTranslation } from 'react-i18next'
import {ICON_PATH} from '../../../helper/pathConstants'
import AutoCompleteFilter from './AutoCompleteFilter';
import SliderFilter from './SliderFilter'


export default function CombinedFilter(props) {
    const { t, i18n } = useTranslation()
    const [state, setState] = useState({
        distance : props.value.distance,
        property : props.value.property
    })
    const [isPropertyModalOpen, setPropertyModalOpen] = useState(false)
    const [isDistanceModalopen, setDistanceModalOpen] = useState(false);
    
    const handleDistaceModal = () => {
        setDistanceModalOpen(!isDistanceModalopen);
    }

    const handlePropertyModal = () => {
        setPropertyModalOpen(!isPropertyModalOpen)
    }

    const trimString = (name) => {
        if(name.length < 12)
        return name;
        else {
            return name.substring(0,10) + "..";
        }
    }

    const handleDistanceClick = (value) => {
        setState(prevState => {
            return {
                ...prevState,
                distance : value.toString()
            }
        })
        if(state.property.id) {
            props.handleFilterSelect({
                distance : value.toString(),
                property : state.property
            });
        }
        handleDistaceModal();
    } 

    const handlePropertyClick = (name,value) => {
        setState(prevState => {
            return {
                ...prevState,
                property : value
            }
        })
        if(state.distance.length > 0) {
            props.handleFilterSelect({
                distance : state.distance,
                property : value
            });
        }
        handlePropertyModal();
    }

    return (
        <div className={styles.filter}>
            <div className={styles.block}>
                <div onClick={handleDistaceModal}>{(state.distance) ? trimString(state.distance + " Kms") :t("Distance.word")}</div>
                {
                    (state.distance) ? 
                    <img src={ICON_PATH + "x.svg"} alt="X" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                distance : ""
                            }
                        })
                        props.handleFilterSelect({
                            distance : "",
                            property : state.property
                        });
                    }}/> 
                    : 
                    <img src={ICON_PATH + "dropdown.svg"} alt="" onClick={handleDistaceModal} className={styles[isDistanceModalopen ? "chevron_close" : "chevron_open"]}/>
                }
            </div>
            <div className={styles.divider}>
                <div className={styles.connector}>FROM</div>
            </div>
            <div className={styles.block}>
                <div onClick={handlePropertyModal}>{(state.property.name) ? trimString(state.property.name) :t("PropertyId.word")}</div>
                {
                    (state.property.id) ? 
                    <img src={ICON_PATH + "x.svg"} alt="X" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                property : {}
                            }
                        })
                        props.handleFilterSelect({
                            distance : state.distance,
                            property : {}
                        });
                    }}
                    /> 
                    : 
                    <img src={ICON_PATH + "dropdown.svg"} alt="" onClick={handlePropertyModal} className={styles[isPropertyModalOpen ? "chevron_close" : "chevron_open"]}/>
                } 
            </div>
            {
                (isDistanceModalopen) ?
                <div className={styles.distance_filter}>
                    <SliderFilter handleApplyClick={handleDistanceClick} value={state.distance} onExit={handleDistaceModal}/>
                </div>
                :null
            }
            {
                (isPropertyModalOpen) ? 
                <div className={styles.property_filter} >
                    <AutoCompleteFilter placeholder={t("SearchForId.label")} name="property" onExit={handlePropertyModal} value={state.property} handleOptionClick={handlePropertyClick} endPoint="property"/>
                </div> 
                : null
            }
            
        </div>
    )
}
