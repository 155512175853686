import React from 'react';
import styles from './column.module.css';
import { useTranslation } from 'react-i18next'



const statusBlockStyle = {
    width : "72px",
    height : "24px",
    boxSizing : "border-box",
    borderRadius: "4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    textAlign : "center",
    border : "1px solid",
    backgroundColor : "white",
    padding : "3px 0",
}

const colors = {
    ACTIVE : "#00B28A",
    RESIGNED : "#EE2A24",
    ON_LEAVE : "#DF9641"
}

export default function Column(props) {
    const { t, i18n } = useTranslation()

    const rowItemJsx = props.data.length && props.data.map((item,index) => {
        return <div key={(item.staffId) ? item.staffId : index} className={styles.row_item} style={{cursor:(props.onClick) ? 'pointer' : 'default'}} id={item.staffId} >
            {(props.header === "status") ? 
                <div style={{...statusBlockStyle, color : colors[item.details], borderColor : colors[item.details]}} id={item.staffId}  >
                    {item.details}
                </div> 
            :   item.details }
            </div>
    })
    return (
        <div className={styles.column} style={{maxWidth : (props.maxWidth) ? props.maxWidth : ""}}>
            <div className={styles.header}>{t(`${props.header}.word`)}</div>
            <div className={styles.content} onClick={props.onClick}>
                {rowItemJsx}
            </div>
        </div>
    )
}
