import React ,{useState,useEffect,useRef}from 'react';
import styles from './property.module.css';
import TaskBox from './TaskBox'
import { useTranslation } from 'react-i18next';
import {ICON_PATH} from '../../../helper/pathConstants'
import useOutsideClick from '../../../hooks/useOutSideClick';
import Dropdown from '../../common/dropdowns/Dropdown'

export default function Property(props) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [allChecked , setAllChecked] = useState(false);
    const [isTripleDotOpen ,setTripleDotOpen] = useState(false);
    const [allTaskStatus,setAllTaskStatus] = useState(Array(props.data.totalTaskNo).fill(false));
    const dropDownRef = useRef();
    const { t, i18n } = useTranslation()
    let lastDate = "";

    const selectTask = (id,index) => {
        if(allTaskStatus[index]) {
            props.callBacks.deSelectTask([{
                taskId : id,
                propertyId : props.data.propertyId
            }]);
            setAllChecked(false);
        } else {
            props.callBacks.selectTask([{
                taskId : id,
                propertyId : props.data.propertyId
            }]);
        }
        let temp = [];
        temp.push(...allTaskStatus);
        temp[index] = !allTaskStatus[index];
        setAllTaskStatus(temp);
    }
    
    const allTaskIds = []; 
    const taskBoxJsx = [];
    let taskIndex = 0;

    props.data.taskOrder.forEach((order,index) => {
        if (index === 0 || order.date !== lastDate) {
            taskBoxJsx.push(<div className={styles.dates}>{order.date}</div>);
        }
        allTaskIds.push({
            taskId :order.taskId,
            propertyId : props.data.propertyId
        })
        if((!props.dropdownStatus[2] || (props.dropdownStatus[2] && props.data.taskList[order.taskId].staffsAssigned === 0)) && (!props.dropdownStatus[3] || (props.dropdownStatus[3] && props.data.taskList[order.taskId].staffsAssigned > 0))){
            taskBoxJsx.push(<TaskBox propertyId={props.data.propertyId} taskIndex={taskIndex} key={order.taskId} checked={allTaskStatus[taskIndex]} taskData={props.data.taskList[order.taskId]} selectTask={selectTask} callBacks={props.callBacks}/>);
        }
        lastDate = order.date;
        taskIndex++;
    });
    // for(const[key,value] of Object.entries(props.data.taskList)) {
    //     allTaskIds.push({
    //         taskId :value.taskId,
    //         propertyId : props.data.propertyId
    //     })
    //     if((!props.dropdownStatus[2] || (props.dropdownStatus[2] && value.staffsAssigned === 0)) && (!props.dropdownStatus[3] || (props.dropdownStatus[3] && value.staffsAssigned > 0))){
    //         taskBoxJsx.push(<TaskBox propertyId={props.data.propertyId} taskIndex={taskIndex} key={value.taskId} checked={allTaskStatus[taskIndex]} taskData={value} selectTask={selectTask} callBacks={props.callBacks}/>);
    //     }
    //     taskIndex++;
    // }

    const cancelAll = () => {
        setAllTaskStatus(Array(props.data.totalTaskNo).fill(false));
        setAllChecked(false);
    }
    
    const selectAllAssignedTask = () => {
        let taskIds = [];
        let temp = [];
        temp.push(...allTaskStatus);
        let index = 0;
        for(const[key,value] of Object.entries(props.data.taskList)) {
            if(value.staffsAssigned > 0) {
                taskIds.push({
                    taskId : value.taskId,
                    propertyId : props.data.propertyId
                });
                temp[index] = true;
            } else {
                temp[index] = false;
            }
            index++;
        }
        props.callBacks.selectTask(taskIds);
        if(taskIds.length === allTaskIds.length) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
        setAllTaskStatus(temp);

    }

    const selectAll = () => {
        props.callBacks.selectTask(allTaskIds);
        setAllTaskStatus(Array(props.data.totalTaskNo).fill(true));
        setAllChecked(true);
    }

    const selectAllUnAssignedTask = () => {
        let taskIds = [];
        let temp = [];
        temp.push(...allTaskStatus);
        let index = 0;
        for(const[key,value] of Object.entries(props.data.taskList)) {
            if(value.staffsAssigned === 0) {
                taskIds.push({
                    taskId : value.taskId,
                    propertyId : props.data.propertyId
                });
                temp[index] = true;
            } else {
                temp[index] = false;
            }
            index++;
        }
        props.callBacks.selectTask(taskIds);
        if(taskIds.length === allTaskIds.length) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
        setAllTaskStatus(temp);
    }
    
    useEffect(() => {
        if(props.dropdownStatus[0]) {
            selectAll(); // SELECT ALL TASK OPERATION, VALUE = 0
        } else if(props.dropdownStatus[1]) {
            cancelAll(); // DESELECT ALL TASK OPERATION, VALUE = 1
        } else if(props.dropdownStatus[2]) {
            selectAllUnAssignedTask(); // SELECT ALL UNASSIGNED TASK OPERATION, VALUE = 2
        } else if (props.dropdownStatus[3]) {
            selectAllAssignedTask(); // SELECT ALL ASSIGNED TASK OPERATION, VALUE = 3
        }

    },[props.dropdownStatus])

    const handleClick = () => {
        if(allChecked) {
            props.callBacks.deSelectTask(allTaskIds);
        } else {
            props.callBacks.selectTask(allTaskIds);
        } 
        setAllTaskStatus(Array(props.data.totalTaskNo).fill(!allChecked));
        setAllChecked(!allChecked);
    }

    const unAssignAllStaff = (e) => {
        e.stopPropagation();
        setTripleDotOpen(!isTripleDotOpen);
        props.callBacks.unAssignAllStaff(props.data.propertyId);
    }

    
    useOutsideClick(dropDownRef,() => setTripleDotOpen(false),isTripleDotOpen);
    
    return (
        <div className={styles.property_item}>
            <div className={styles.header} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <div className={styles.left_sec}>
                    <img src={ICON_PATH + "chevron-up.svg"} alt="^" className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]} style={{cursor:'pointer'}}/>
                    {/* <div className={styles.id}>{props.data.propertyId}</div> */}
                    <div className={styles.property_name}>{props.data.propertyName}</div>
                </div>
                <div className={styles.right_sec}>
                    {`( ${props.data.unassignedTaskNo} ${t('UnassignedTask.label')} )`}
                    <img src={ICON_PATH + "triple-dot.svg"} onClick={(e) => {
                        e.stopPropagation();
                        setTripleDotOpen(!isTripleDotOpen)
                    }} alt="" style={{cursor:'pointer'}}/>
                    {
                        (isTripleDotOpen) ?
                        <div className={styles.list} ref={dropDownRef}>
                            <Dropdown data={[t("UnAssignAllTask.option")]} handleClicks={[unAssignAllStaff]}/>
                        </div>
                        : null
                    }
                </div>
            </div>
            {
                (isDropdownOpen) ?
                <div className={styles.hidden_sec}>
                    {
                        (taskBoxJsx.length > 0) ? 
                        <div className={styles.select_all}> 
                            <img src= {allChecked ? ICON_PATH + "chekbox_selected.svg" : ICON_PATH + "chekbox.svg"} onClick={handleClick} alt="" style={{cursor:'pointer'}} />
                            <label>{`${t('SelectAlltask.label')}`}</label>
                        </div>
                        : null
                    }
                    <div className={styles.task_list}>
                    {(taskBoxJsx.length > 0) ? taskBoxJsx : <div className={styles.no_task}>{t("NoTasks.label")}</div>}
                    </div>
                </div>
                : null
            }
        </div>
    )
}




