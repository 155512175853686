import React from 'react';
import styles from './error.module.css';
import Navbar from '../navbar/Navbar';
import { useTranslation } from 'react-i18next'
import {IMAGE_PATH,SCHEDULER} from '../../../helper/pathConstants'
import CustomButton from '../buttons/CustomButton';
import {useHistory} from 'react-router-dom'

export default function Error() {
    const { t, i18n } = useTranslation()
    const history = useHistory();

    const handleGoHomeClick = () => {
        history.push(SCHEDULER);
    }

    return (
        <div className={styles.error_page}>
            <Navbar/>
            <div className={styles.container}>
                <div className={styles.main_content}>
                    <img src={IMAGE_PATH + "img_404.svg"} alt="404"/>
                    <div>{t("Error.label")}</div>
                    <CustomButton type="colored" color={"red"} buttonName={t("GoHome.button")} padding="76" onClick={handleGoHomeClick}/>
                </div>
            </div>
        </div>
    )
}
