import React ,{useState,useRef,useEffect,useReducer} from 'react';
import styles from './personHeader.module.css';
import Button from '../../common/buttons/Button';
import {useHistory} from 'react-router-dom';
import ChangeManager from './ChangeManager';
import MarkLeave from './MarkLeave';
import useOutsideClick from '../../../hooks/useOutSideClick';
import Dropdown from '../../common/dropdowns/Dropdown'
import {ICON_PATH,STAFF_DETAILS_BFF,STAFF_LEAVE_BFF,STAFF_ASSIGN_TO_MANAGER_BFF} from '../../../helper/pathConstants';
import { useTranslation } from 'react-i18next'
import Navbar from "../../common/navbar/Navbar";
import Sidebar from '../../common/sidebar/Sidebar';
import {staffDetails} from '../../../helper/pageConstants';
import EmployeeDetails from "./EmployeeDetails"
import LeaveTable from '../staff-table/Table';
import {HttpReducer,loaderInitialState} from "../../../reducers/SchedulerReducer";
import Spinner from '../../common/spinner/Spinner'; 
import axios from "axios";
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE, SOMETHING_WENT_WRONG,PLEASE_TRY_AGAIN} from "../../../helper/text_EN";
import ErrorMessage from '../../common/error/ErrorMessage';
import {reducer,initialState,actions} from '../../../reducers/staffDetailsReducer';
import ErrorPopUp from '../../common/error/ErrorPopUp'


export default function PersonHeader(props) {
    const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
    const [state,dispatch] = useReducer(reducer,initialState);

    const [error , setError] = useState({
        loadingError : "",
        actionError : ""
      });
    const [menuCode ,setMenuCode] = useState(0);
    const [isTripleDotOpen ,setTripleDotOpen] = useState(false);
    const [isChangeManagerModalOpen, setChangeManagerModal] = useState(false);
    const [isMarkLeaveOpen, setMarkLeave] = useState(false);
    const history = useHistory();
    const dropDownRef = useRef();
    const { t, i18n } = useTranslation()

    const token = localStorage.getItem("token");
    const payload = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const staffId = props.match.params.id;
    const pageDetails = props.location.state;

    useEffect(() => {
        loaderDispatch({ type: "FETCH_START" });
        payload.params = {
            staffId : staffId
        }
        axios.get(STAFF_DETAILS_BFF,payload)
        .then(res => {
            if(res.data) {
                if(res.data.message === SUCCESSFUL) {
                    setError({
                        loadingError : "",
                        actionError : ""
                    }); 
                    const localOffice = localStorage.getItem("localOfficeName");
                    const manager = res.data.data.employeeDetails.manager;
                    res.data.data.employeeDetails.localeOffice = localOffice;
                    res.data.data.employeeDetails.manager = manager.name || "--";
                    res.data.data.employeeDetails.city = localStorage.getItem("city");
                    res.data.data.employeeDetails.mobile = res.data.data.employeeDetails.countryCode + res.data.data.employeeDetails.mobile;
                    actions.loadData(dispatch,res.data.data)      
                } else {
                    setError({
                        ...error,
                        loadingError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    loadingError : SOMETHING_WENT_WRONG
                });
            }
            loaderDispatch({ type: "FETCH_SUCCESS"});
        })
        .catch(err =>{
            setError({
                ...error,
                loadingError : SERVICE_TEMPORARILY_UNAVAILABLE
            });
            loaderDispatch({ type: "FETCH_ERROR" });
        }
      );

    },[])

    const handleApplyLeave = (leave) => {
        const data = {
            staffId,
            from : leave.from,
            till : leave.till,
            comment : leave.comment
        }
        axios.post(STAFF_LEAVE_BFF,data,payload)
        .then(res => {
            if(res && res.data) {
                if(res.data.message === SUCCESSFUL) {
                    actions.addLeave(dispatch,res.data.data)
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
        })
        .catch((err) =>
         {
            setError({
                ...error,
                actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
         }
        )
    }

    const handleMarkLeaveModalClick = () => {
        setMarkLeave(!isMarkLeaveOpen);
    }

    const editInfoClick = () => {
        history.push({
            pathname : `/staff/${staffId}/edit`,
            state : pageDetails
        })
    }

    const handleDismissError = () => {
        setError(prevState => {
          return {
            ...prevState,
            actionError : ""
          }
        })
    }

    const handleAssignManagerClick = (managerId) => {
        payload.params = {
            managerId : managerId
        }
        axios.post(STAFF_ASSIGN_TO_MANAGER_BFF,state.assignedStaffsId,payload)
        .then(res => {
            if(res && res.data ) {
                if(res.data.message === SUCCESSFUL) {
                    actions.reAssignStaffs(dispatch);
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
        })
        .catch((err) =>
            {
            setError({
                ...error,
                actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
            }
        )
    }

    const handleChangeManagerClick = () => {
        setChangeManagerModal(!isChangeManagerModalOpen);
    }

    const handleManuItemClick = (event) => {
       setMenuCode(event.target.id)
    }
    let displayJsx = null;
    if(menuCode == 0) {
        displayJsx = <EmployeeDetails employeeDetails={state.employeeDetails}/>
    } else if (menuCode == 1) {
        displayJsx = <LeaveTable data={state.leaves} tableHeader={staffDetails.leavesTableHeader} onClick={null}/>
    } else {
        displayJsx = <LeaveTable data={state.staffsAssigned} tableHeader={staffDetails.staffAssignedTableHeader} onClick={null}/>
    }


    useOutsideClick(dropDownRef,() => setTripleDotOpen(false),isTripleDotOpen);

    return (
        (loaderState.loading) ? <Spinner/> :
        <div className={styles.page}>
            <Navbar/>
            <Sidebar index={staffDetails.pageIndex}/>
            {error.actionError.length > 0 ? <ErrorPopUp message={error.actionError} handleDismissClick={handleDismissError}/> : null}
            {
                (error.loadingError.length > 0) ? <ErrorMessage message={error.loadingError} reloadPageClick={() => window.location.reload()}/> : 
                <div className={styles.main_content}>
                    <div className={styles.header}>
                        {(isMarkLeaveOpen)? <MarkLeave handleExitClick={handleMarkLeaveModalClick} handleMarkLeave={handleApplyLeave}/> : null}
                        {(isChangeManagerModalOpen)? <ChangeManager handleExitClick={handleChangeManagerClick} handleSubmitClick={handleAssignManagerClick}/> : null}
                        <div className={styles.subheader}>
                            <div className={styles.left_sec}>
                                <img src={ICON_PATH + "arrow-left.svg"} onClick={() => history.push({pathname : '/staff',state : pageDetails})} style={{cursor:'pointer'}} alt="<"/>
                                <p>{state.staffName}</p>
                            </div>
                            <div className={styles.right_sec}>
                                <Button type="default" image={ICON_PATH + "blue_edit_icon.svg"} buttonName={t("MarkLeave.button")} onClick={handleMarkLeaveModalClick} padding="16"/>
                                <Button type="colored" color="red" image={ICON_PATH + "edit_icon.svg"} buttonName={t("EditInfo.button")} onClick={editInfoClick} padding="16"/>
                                <img src={ICON_PATH + "triple-dot-horizontal.svg"} alt="" style={{cursor:'pointer'}} onClick={() => setTripleDotOpen(!isTripleDotOpen)}/>
                                {
                                    (isTripleDotOpen) ?
                                    <div className={styles.list} ref={dropDownRef}>
                                        <Dropdown data={[t("AssignStaffTo.option"),t("BulkStaffAssignment.option")]} handleClicks={[handleChangeManagerClick,null]}/>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={styles.menu}>
                            <div className={styles.item_container}>
                                <div id={0} className={styles[(menuCode == 0)? "selected" : "menu_item"]} onClick={handleManuItemClick}>{t("EmployeeDetails.label")}</div>
                                <div id={1} className={styles[(menuCode == 1)? "selected" : "menu_item"]} onClick={handleManuItemClick}>{t("Leaves.label")}</div>
                                <div id={2} className={styles[(menuCode == 2)? "selected" : "menu_item"]} onClick={handleManuItemClick}>{t("StaffAssigned.label")}</div>
                            </div>
                        </div>
                    </div>
                    {displayJsx}
                </div>
            }     
        </div>
        
    )
}
