import React from 'react'


const colors = {
    red : "#CC1414",
    orange : "#DF9641",
    green : "#00B28A",
    default : "rgba(0, 0, 0, 0.12)"
}


export default function BarGraph(props) {

    const colorPicker = (index) => {
        if(index > props.taskNo) return "default";
        
        if(props.taskNo >= 10) {
            return "red";
        } else if (props.taskNo >= 6) {
            return "orange";
        } else {
            return "green";
        }
    };

    const divJsx = Array(10).fill(1).map((item,index) => <div key={index} style={{height:"4px",width:`${props.width}px`,backgroundColor: colors[colorPicker(index+1)]}}/>)
    return (
        <div style={{width:"fit-content",height:"fit-content",borderRadius : "2px",display:"flex",overflow:"hidden"}}>
            {divJsx}
        </div>
    )
}
