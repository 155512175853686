export const types = {
    FETCH_START: "fetch_start",
    FETCH_SUCCESS:"fetch_seccess",
    FETCH_ERROR:"fetch_error",
};

export const initialState = {
    loading: false,
    errorMessage: "",
    data:[]
};

export const reducer = (state, action) => {
    switch (action.type) {
        case types.FETCH_START:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.FETCH_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.value,
            };
        default:
            return state;
    }
};

export const actions = {

    fetchStart: (dispatch,value) => (
        dispatch({type: types.FETCH_START})),

    fetchSuccess: (dispatch, value) => (
        dispatch({type: types.FETCH_SUCCESS})),
    
    fetchError: (dispatch, error) => (
        dispatch({type: types.FETCH_ERROR, value: error}))
};

