import React, {useState} from 'react';
import styles from './propertyBlockV2.module.css';
import TaskTag from '../employee-listing/TaskTag';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

export default function PropertyBlockV2(props) {
    const { t, i18n } = useTranslation()
    const [isDropdownOpen, setDropdown] = useState(true);
    
    const taskTagJsx = [];
    for(const[key,value] of Object.entries(props.data.taskList)) {
        taskTagJsx.push(<TaskTag data={value} staffId={null} propertyId={props.data.propertyId } callBacks={props.callBacks} key={key}/>)
    }
    
    return (
        <div className={styles.property_block}>
            <div className={styles.header} >
                <div className={styles.left_sec}>
                    <img src={ICON_PATH + "chevron-up.svg"} className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]} style={{cursor:'pointer'}} onClick={() => setDropdown(!isDropdownOpen)} alt="^"/>
                    <div>{props.data.propertyName}</div>
                </div>
                <div className={styles.right_sec}>
                    {`${props.data.totalTaskNo} ${t("Tasks.word")}`}
                </div>
            </div>
            {/* <div className={styles.property_name}>{props.data.propertyName}</div> */}
            {
                (isDropdownOpen) ?
                <div className={styles.hidden_sec}>
                    {taskTagJsx}
                </div>
                : null
            }
        </div>
    )
}
