import React from 'react'

const textBoxStyle = {
    boxSizing: "border-box",
    borderRadius: "6px",
    height : "40px",
    padding : "8px 12px",
    color: "#212121",
    position : "relative"
}

const inputStyle = {
    padding : 0,
    border : "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    width : "100%",
    outline : "none"
}

const floatBoxStyle = {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: "0.2px",
    margin : 0,
    boxSizing: "border-box",
    backgroundColor: "white",
    padding :"0 4px",
    position : "absolute",
    top : "-8px",
    left : "8px"

}

const erroeStyle = {
    position : "absolute",
    bottom : "-8px",
    left : "12px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}

export default function CustomTextBox(props) {
    return (
        <div style={{...textBoxStyle,width: `${props.width}px`, border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}}>
            <input style={inputStyle} type="text" placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}/>
            {(props.value.length > 0) ?<p style={floatBoxStyle}>{props.placeholder}</p> : null}
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null}             
        </div>
    )
}
