import React,{useState,useRef} from 'react'
import styles from './emoloyee.module.css'
import BarGraph from '../../common/barGraph/BarGraph';
import PropertyBlock from './PropertyBlock';
import Dropdown from '../../common/dropdowns/Dropdown';
import useOutsideClick from '../../../hooks/useOutSideClick';
import ReAssignAllTAsk from './ReAssignTask';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'


export default function Employee(props) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [isReAssignModalOpen, setReAssignModal] = useState(false);
    const [isTripleDotOpen ,setTripleDotOpen] = useState(false);
    const [isAllExpanded , setAllExpand] = useState(false);
    const dropDownRef = useRef();
    const { t, i18n } = useTranslation()

    const handleReAssignClick = (staff) => {
        props.callBacks.reAssignAllTaskTo(props.data.staffId,staff.id);
    }
    
    const handleReAssignModal = () => {
        setReAssignModal(!isReAssignModalOpen);
    }

    const handleExpandAllClick = () => {
        setAllExpand(false);
    }

    const propertyBlockJsx = [];
    for (const [key,value] of Object.entries(props.data.propertyList)) {
        propertyBlockJsx.push(<PropertyBlock data={value} expandStatus={isAllExpanded} staffId={props.data.staffId} key={key} handleExpandAllClick={handleExpandAllClick} callBacks={props.callBacks}/>)
    }
    
    useOutsideClick(dropDownRef,() => setTripleDotOpen(false),isTripleDotOpen);
   
    return (
        <div className={styles.employee}>
            {(isReAssignModalOpen) ? <ReAssignAllTAsk handleExitClick={handleReAssignModal} handleSubmitClick={handleReAssignClick}/> : null}
            <div className={styles.header} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <div className={styles.left_sec}>
                    <img src={ICON_PATH + "chevron-up.svg"} className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]} alt="^"/>
                    <div className={styles.employee_name}>{props.data.staffName}</div>
                    <div className={styles.task_no}>{`${props.data.assignedTaskNo} ${t("Tasks.word")}`}</div>
                    <BarGraph width="12" taskNo={props.data.assignedTaskNo}/>
                </div>
                <div className={styles.right_sec}>
                    <div>{`${props.data.distance} km ${t("travel.word")}`}</div>
                    <img src={ICON_PATH + "triple-dot.svg"} onClick={(e) => {
                        e.stopPropagation();
                        setTripleDotOpen(!isTripleDotOpen)}
                     } alt=""/>
                    
                    {
                        (isTripleDotOpen) ?
                        <div className={styles.list} ref={dropDownRef}>
                            <Dropdown data={[t("ReAssignTask.label"),t("ExpandAllTask.label")]} handleClicks={[handleReAssignModal,() => setAllExpand(true)]}/>
                        </div>
                        : null
                    }
                </div>
            </div>
            {
                (isDropdownOpen) ?
                <div className={styles.hidden_sec}>
                    {propertyBlockJsx}
                </div>
                : null
            }
        </div>
    )
}
