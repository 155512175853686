import React, {useState, useEffect} from 'react';
import moment from 'moment';
import styles from './calendar.module.css';


moment.updateLocale('en', {
    week: {
      dow: 1,
    },
});

const weekDays = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];

export default function Calendar(props) {
    
    const [calendar , setcalendar] = useState([]);
    let [pickedDate , setPickedDate] = useState(props.date);
    let [endPickedDate , setEndPickedDate] = useState(moment(localStorage.getItem('endDate')));
    const [calendarDate , setCalenderDate] = useState(props.date);

    const startDay = calendarDate.clone().startOf("month").startOf("week");
    const endDay = calendarDate.clone().endOf("month").endOf("week");
    
    const weekDaysJsx = weekDays.map((item,index )=> <div key={index} className={styles.day_names}>{item}</div>);

    useEffect(() => {
        setPickedDate(props.date);
        setCalenderDate(props.date);
    }, [props.date])

    
    useEffect(() => {
        const day = startDay.clone().subtract(1,"day");
        const temp = [];
        while(day.isBefore(endDay,"day")) {
            temp.push(
                Array(7).fill(0).map(item => day.add(1,"day").clone())
            )
        }
        setcalendar(temp);

    }, [calendarDate]);

    
    const prevMonth = () => {
        setCalenderDate(calendarDate.clone().subtract(1,"month"));
    }

    const nextMonth = () => {
        setCalenderDate(calendarDate.clone().add(1,"month"));
    }

    function currentYear() {
        return calendarDate.format("YYYY");
    }

    function currentMonth() {
        return calendarDate.format("MMMM");
    }

    function dateBackgroundStyle(day){
        if(props.dateRange){
            if(endPickedDate !== null) {
                if( endPickedDate.isSame(day,"day")) return "end";
                if( pickedDate.isSame(day,"day")) return "start";
            }
        }
        return '';
    }

    function dateStyle (day) {
        if(props.dateRange){
            if( pickedDate.isSame(day,"day")) return "selected";
            if(endPickedDate !== null) {
                if( endPickedDate.isSame(day,"day")) return "selected";
                if(day.isBetween(pickedDate, endPickedDate,'days', '()')) return "selected_range";
            }
            if(!calendarDate.isSame(day,"month") || ( !props.isPastSelectable && moment().isAfter(day,"day"))) return "blocked";
            return "date";
        }else{
            if( pickedDate.isSame(day,"day")) return "selected";
            if(!calendarDate.isSame(day,"month") || ( !props.isPastSelectable && moment().isAfter(day,"day")) || (props.selectBefore && props.selectBefore.length > 0 && day.isSameOrAfter(moment(props.selectBefore))) || (props.selectAfter && props.selectAfter.length > 0 && day.isSameOrBefore(moment(props.selectAfter))) ) return "blocked";
            return "date";
        }
    }

    const handleDateClick = (days) =>  {
        if((calendarDate.isSame(days,"month") && days.isSameOrAfter(moment(),"day")) || props.isPastSelectable) {  //&& days.isSameOrAfter(moment(),"day")
            if(props.dateRange){
                if(endPickedDate === null && days.isAfter(pickedDate,'day')){
                    setEndPickedDate(days);
                    props.onSelectRange(pickedDate,days);
                }else{
                    setPickedDate(days);
                    setEndPickedDate(null);
                }
            } else {
                if (props.selectBefore && props.selectBefore.length > 0) {
                    if( days.isBefore(moment(props.selectBefore))) {
                        setPickedDate(days);
                        props.onSelect(days);
                    }
                } else if (props.selectAfter && props.selectAfter.length > 0) {
                    if( days.isAfter(moment(props.selectAfter))) {
                        setPickedDate(days);
                        props.onSelect(days);
                    }
                } else {
                    setPickedDate(days);
                    props.onSelect(days);
                }
            }
        }
    }
      
    return (
    <div className={styles.date_picker}>
        <div className={styles.header}>
            <div onClick={prevMonth}> {String.fromCharCode(60) }</div>
            <div>{currentMonth()} {currentYear()}</div>
            <div onClick={nextMonth}>{String.fromCharCode(62)}</div>
        </div>
        <div className={styles.table_header}>
            {weekDaysJsx}
        </div>
        <div className={styles.table}>
            {
                calendar.map((week,index )=> (
                    <div className={styles.row} key={index}>
                        {
                            week.map(days => (
                                <div className={styles.table_block} key={days} onClick={() => handleDateClick(days)}>
                                    <div className={styles[dateStyle(days)]}>
                                        {days.format("D").toString()}
                                    </div>
                                    <div className={styles[dateBackgroundStyle(days)]}></div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    </div>
    )
}
