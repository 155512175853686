import React from 'react'
import {ICON_PATH} from '../../../helper/pathConstants'

const nameTag = {
    display : "flex",
    alignItems :"center",
    height: "32px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    boxSizing :"border-box",
    padding : "0 4px",
    marginRight: "1rem",
    flexWrap : 'wrap'
}

const firstLetterBlock = {
    height:"24px",
    width:"24px",
    fontWeight: 600, 
    fontSize: "11px",
    lineHeight: "16px",
    textAlign : "center",
    borderRadius: "50%",
    padding : "4px 0",
    boxSizing : "border-box",
    color :"white",
    textTransform: "capitalize"
}

const nameBlock = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "black",
    height: "24px",
    width : "fit-content",
    padding : "0 8px"
}


export default function NameTag(props) {
    const name = props.staffDetails.staffName;

    return (
        <div style={nameTag}>
            <div style={{...firstLetterBlock,backgroundColor: props.staffDetails.color}}>
                {name.charAt(0)}
            </div>
            <div style={nameBlock}>{name}</div>
            <img src={ICON_PATH + "x.svg"} alt="" style={{display:"inline-block",padding:"0 6px",cursor:'pointer'}} onClick={() => {
                props.callBacks.deleteStaff(props.staffDetails.staffId,props.taskId,props.propertyId)
                }}/>
        </div>
    )
}
