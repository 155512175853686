import React from 'react';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

const popUpStyle = {
    width:"380px",
    height : "fit-content",
    maxHeight : "240px",
    display : "flex",
    boxSizing : "border-box",
    padding:"8px 16px",
    backgroundColor : "#212121",
    borderRadius : "6px",
    fontSize: "14px",
    lineHeight: "24px",
    columnGap : "12px",
    position : "fixed",
    bottom : "16px",
    left : "calc(50% - 190px)",
    zIndex : 1000,
    alignItems : "center"
}

const messageStyle = {
    fontWeight: 500,
    color : "white",
    flexGrow : 1,
    overflow : 'auto',
    maxHeight : "240px",
    height : "fit-content"
}

const dismissStyle = {
    fontWeight: 600,
    color : "rgba(255, 255, 255, 0.5)",
    width : "fit-content"
}

export default function ErrorPopUp(props) {
    const { t, i18n } = useTranslation()
    return (
        <div style= {popUpStyle}>
            <img src={ICON_PATH + "error_icon.svg"} alt="" />
            <div style={messageStyle}>{props.message}</div>
            <div style={dismissStyle} onClick={props.handleDismissClick}>{t("Dismiss.word")}</div>
        </div>
    )
}
