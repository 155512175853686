import React, {useState,useRef} from 'react'
import styles from './navbar.module.css';
import { Redirect } from 'react-router-dom';
import LanguageSelector from '../languageSelector/LanguageSelector';
import useOutsideClick from '../../../hooks/useOutSideClick';
import {ICON_PATH,IMAGE_PATH} from '../../../helper/pathConstants'
import moment from 'moment';

export default function Navbar() {

    const [isLogoutWindowOpen, setLogOutWindow] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const logoutRef = useRef();
    const userName = localStorage.getItem("userId");

    function onHandleLogout(){
        localStorage.setItem('isAuthenticate','');
        localStorage.setItem('token','');
        localStorage.setItem('userId','');
        localStorage.setItem('expiryTime','');
        localStorage.setItem("localOffice",'');
        localStorage.setItem("localOfficeName",'');
        localStorage.setItem("city",'');
        localStorage.setItem("schedularPayloadFilter",'');
        localStorage.setItem("schedularPayloadPage",'');
        localStorage.setItem("staffPayloadPage",'');
        localStorage.setItem("legecyId",'');
        localStorage.setItem('startDate','');
        localStorage.setItem('date','');
        localStorage.setItem('endDate','');
        localStorage.setItem('languageId','');
        setRedirect(true);
    }

    function renderRedirect(){
        if (redirect) 
        return <Redirect to='/' />
    }

    useOutsideClick(logoutRef,() => setLogOutWindow(false),isLogoutWindowOpen);

    return (
        <div className={styles.navbar}>
            {renderRedirect()}
            <div className={styles.left_sec}>
                <li><img src={ICON_PATH + "hamberger_button.svg"} alt=""/></li>
                <li><img src={IMAGE_PATH + "OYO_Lettermark.svg"} alt="OYO"/></li>
                <img src={ICON_PATH + "divider.svg"} alt="-"/>
                <li><p>Jarvis</p></li>
            </div>
            <div className={styles.right_sec}>
                <LanguageSelector/>
                <img src={ICON_PATH + "notification_Bell.svg"} alt=""/>
                <div className={styles.profile} >
                    <div className={styles.profile_icon}>{userName.charAt(0)}</div>
                    <div>{userName}</div>
                    <img src={ICON_PATH + "chevron-up.svg"} alt="^" className={styles[isLogoutWindowOpen ? "chevron_up" : "chevron_down"]} onClick={() => setLogOutWindow(!isLogoutWindowOpen)} style={{cursor:'pointer'}}/>
                    {(isLogoutWindowOpen) ? 
                        <div className={styles.logout} onClick = {onHandleLogout} ref={logoutRef}>
                            <img src={ICON_PATH + "log-out.svg"} alt="[ >" />
                            <div>Logout</div>
                        </div>
                        : null 
                    }
                </div>
            </div>
        </div>
    )
}
