import React,{useReducer,useState, useEffect} from 'react';
import styles from './taskSummary.module.css';
import Navbar from '../common/navbar/Navbar';
import Sidebar from '../common/sidebar/Sidebar'
import SectionHeader from '../common/sectionHeader/SectionHeader';
import Employee from './employee-listing/Employee';
import PropertyBlockV2 from './unassignedTask-listing/PropertyBlockV2';
import AssignStaff from '../scheduler/assign-staff/AssignStaff';
import AddTask from '../scheduler/add-task/AddTask'
import Spinner from "../common/spinner/Spinner";
import axios from "axios";
import moment from 'moment';
import { summaryReducer , actions, taskSummaryInitialState} from '../../reducers/summaryReducer';
import {HttpReducer,loaderInitialState} from "../../reducers/SchedulerReducer";
import {summary} from '../../helper/pageConstants'
import { useTranslation } from 'react-i18next'
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE, SOMETHING_WENT_WRONG,PLEASE_TRY_AGAIN,DISTANCE_NOT_UPDATED} from "../../helper/text_EN";
import ErrorMessage from '../common/error/ErrorMessage'; 
import ErrorPopUp from '../common/error/ErrorPopUp'
import {TASK_SUMMARY_BFF,ASSIGN_STAFF_BFF,ADD_TASK_BFF,REASSIGN_TASK_BFF,ASSIGN_STAFF_SUMMARY_BFF} from '../../helper/pathConstants';


function TaskSummary() {
    const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
    const [summaryData,dispatchSummary] = useReducer(summaryReducer,taskSummaryInitialState); 
    const [error , setError] = useState({
        loadingError : "",
        actionError : ""
    });
    const [staffId,setStaffId] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [staffQueue, setStaffqueue] = useState(new Map());
    const [selectedTask,setSelectedTask] = useState({});
    const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [date,setDate] = useState(moment(localStorage.getItem("date")));
    const { t, i18n } = useTranslation()
    const dataLength = 10;
    const token = localStorage.getItem("token");
    const payload = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;   
        if (page < summaryData.totalPages - 1 && scrollTop !== 0 && scrollHeight - scrollTop === clientHeight) {        
            setPage(prev => prev + 1);    
        }
    }; 
    
    useEffect(() => {
        loaderDispatch({ type: "FETCH_START" });
        payload.params = {
          date : date.format("YYYY-MM-DD"),
          page : page,
          size : dataLength,
          userId : staffId.id
        }
        axios
          .get(TASK_SUMMARY_BFF, payload)
          .then((res) => {
              if(res.data) {
                if(res.data.message === SUCCESSFUL) {
                    setError({
                        loadingError : "",
                        actionError : ""
                    });   
                    actions.loadData(dispatchSummary,res.data.data)       
                  } else {
                    setError({
                        ...error,
                        loadingError : res.data.message
                    });
                  }
              } else {
                setError({
                    ...error,
                    loadingError : SOMETHING_WENT_WRONG
                });
              }
            
              loaderDispatch({ type: "FETCH_SUCCESS"});
          })
          .catch(err=>{
                setError({
                    ...error,
                    loadingError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
                });
                loaderDispatch({ type: "FETCH_ERROR" });
            }
          );
      }, [date,page,staffId.id]);

    const handleSelectDate = (date) => {
        setPage(0);
        actions.resetData(dispatchSummary) 
        setDate(date);  
    }
      
    const handleAddTaskClick = () => {
        setIsAddTaskOpen(!isAddTaskOpen);
    }

    const handleModalClick = () => {
        setIsOpenModal(!isOpenModal);
    }

    const handleDismissError = () => {
        setError(prevState => {
          return {
            ...prevState,
            actionError : ""
          }
        })
    }

    const handleSearch = (key,value) => {
        setPage(0);
        actions.resetStaffData(dispatchSummary) 
        setStaffId(value);
      }

    const assignStaff = () => {
        let data= {};
        let staffList = Array.from(staffQueue.keys());
        let actionType = "";
        let staffIds = []
        staffList.forEach(staffId => {
            if(summaryData.assignedTasks[staffId] && summaryData.assignedTasks[staffId].propertyList[selectedTask.propertyId] == null) {
                staffIds.push(staffId);
            }
        })
        if(selectedTask.staffId == null) {
            actionType = "ADD_STAFF";
            data[selectedTask.taskId] = staffList;
        } else {
            actionType = "CHANGE_STAFF";
            let existingStaffs= [];
            if(summaryData.taskStaffMap[selectedTask.taskId])
            {
                existingStaffs = summaryData.taskStaffMap[selectedTask.taskId].filter(item => item !== selectedTask.staffId);
            } 
            data[selectedTask.taskId] = [...staffList,...existingStaffs];
            if(summaryData.assignedTasks[selectedTask.staffId] && summaryData.assignedTasks[selectedTask.staffId].propertyList[selectedTask.propertyId]&& summaryData.assignedTasks[selectedTask.staffId].propertyList[selectedTask.propertyId].totalTaskNo === 1) {
                staffIds.push(selectedTask.staffId);
            }
        }
        if(staffIds.length > 0) {
            payload.params = {
                date : date.format("YYYY-MM-DD"),
                staffIds : staffIds.join(",")
            }
        } 
        axios.put(ASSIGN_STAFF_SUMMARY_BFF,data,payload)
        .then(res => {
            if(res && res.data) {
                if(res.data.message === SUCCESSFUL && res.data.data[selectedTask.taskId]) {
                    const payLoad = {
                        staffObjects: staffQueue,
                        selectedTask: selectedTask,
                        assignData : res.data.data,
                        distanceMap : res.data.distanceMap || {}
                    };
                    if(actionType === "ADD_STAFF") {
                        actions.addStaff(dispatchSummary,payLoad);
                    } else {
                        actions.changeStaff(dispatchSummary,payLoad);
                    }
                    if(res.data.distanceMap == null) {
                        setError({
                            ...error,
                            actionError : DISTANCE_NOT_UPDATED
                        });
                    }
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }    
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
        })
        .catch((err) => {
            setError({
              ...error,
              actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
          }
        )
        
    }
    
    const unAssignTask = (staffId,propertyId,taskId) => {
        let data = {};
        if(summaryData.taskStaffMap[taskId])
        {
            data[taskId] = summaryData.taskStaffMap[taskId].filter(item => item !== staffId);
        } else {
            data[taskId] = [];
        }
        if(summaryData.assignedTasks[staffId] && summaryData.assignedTasks[staffId].propertyList[propertyId] &&  summaryData.assignedTasks[staffId].propertyList[propertyId].totalTaskNo === 1){
            payload.params = {
                date : date.format("YYYY-MM-DD"),
                staffIds : `${staffId}`
            }
        }
        axios.put(ASSIGN_STAFF_SUMMARY_BFF,data,payload).then(
        res => {
            if(res && res.data) {
                if(res.data.message === SUCCESSFUL && res.data.data && !res.data.data[taskId].includes(staffId))
                { 
                    const payLoad = {
                        taskId,
                        staffId,
                        propertyId,
                        newStaffList : res.data.data[taskId],
                        distanceMap : res.data.distanceMap || {}
                    };
                    actions.unAssignTask(dispatchSummary,payLoad);
                    if(res.data.distanceMap == null) {
                        setError({
                            ...error,
                            actionError : DISTANCE_NOT_UPDATED
                        });
                    }
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
        }
        ).catch((err) => {
            setError({
              ...error,
              actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
          }
        )
        
    }

    const reAssignAllTaskTo = (sourceStaffId,targetStaffId) => {
        payload.params = {
            date : date.format("YYYY-MM-DD")
        }
        let taskMap = {};
        for(let propertyId in summaryData.assignedTasks[sourceStaffId].propertyList) {
            for(let taskId in summaryData.assignedTasks[sourceStaffId].propertyList[propertyId].taskList) {
                taskMap[taskId] = summaryData.taskStaffMap[taskId] || [];
            }
        }
        const data = {
            sourceStaffId,
            targetStaffId,
            taskMap
        }
        axios.put(REASSIGN_TASK_BFF,data,payload)
        .then(res => {
            if(res && res.data) {
                if(res.data.message === SUCCESSFUL) {
                    const payLoad = {
                        ...res.data,
                        staffId : sourceStaffId
                    };
                    actions.reAssignAll(dispatchSummary,payLoad);
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }
                
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
        }).catch((err) => {
            setError({
              ...error,
              actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
          }
        )

    }

    const addStaffInqueue = (staff) => {
        staffQueue.set(staff.staffId,staff)
        setStaffqueue(new Map(staffQueue));
    }

    const deleteStaffInqueue = (staffId) => {
        staffQueue.delete(staffId)
        setStaffqueue(new Map(staffQueue));
    }

    const clearStaffQueue = () => {
        setStaffqueue(new Map());
        setSelectedTask({});
    }

    const addTask = (taskDetails) => {
        loaderDispatch({ type: "FETCH_START" });
        taskDetails.completeBy = moment(taskDetails.completeBy,"D MMM YYYY").format("YYYY/MM/DD").toString();
        if(taskDetails.assignTo.id && summaryData.assignedTasks[taskDetails.assignTo.id] && summaryData.assignedTasks[taskDetails.assignTo.id].propertyList[taskDetails.property.id] == null && date.isSame(moment(taskDetails.completeBy,"YYYY/MM/DD"),"date")) {
            taskDetails.distanceRequired = true;
        }
        axios.post(ADD_TASK_BFF,taskDetails,payload)
        .then( res => {
            if(res && res.data) {
                if(res.data.message === SUCCESSFUL && date.isSame(moment(taskDetails.completeBy,"YYYY/MM/DD"),"date")) {
                    actions.addTask(dispatchSummary,res.data.taskData);  
                } else {
                    setError({
                        ...error,
                        actionError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    actionError : PLEASE_TRY_AGAIN
                });
            }
            loaderDispatch({ type: "FETCH_SUCCESS"})
        
        })
        .catch(err => {
        loaderDispatch({ type: "FETCH_ERROR" });
            setError({
                ...error,
                actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
            });
        })
        
    }

    const callBacks = {
        setSelectedTask,
        handleModalClick,
        unAssignTask,
        reAssignAllTaskTo
    }

    const assignStaffCallBacks = {
        addStaffInqueue,
        deleteStaffInqueue,
        clearStaffQueue,
        assignStaff,
    }

    let employeeListSize = 0;
    const employeeJsx = [];
    summaryData.staffOrder.forEach((item,index) => {
        if(summaryData.assignedTasks[item] && summaryData.assignedTasks[item].assignedTaskNo > 0) { //&& summaryData.assignedTasks[item].assignedTaskNo > 0
            employeeJsx.push(<Employee data={summaryData.assignedTasks[item]} key={item} callBacks={callBacks}/>);
            employeeListSize++;
        }
    })
    // for (const [key,value] of Object.entries(summaryData.assignedTasks)) {
    //     if(value.assignedTaskNo > 0) {
    //         employeeJsx.push(<Employee data={value} key={key} callBacks={callBacks}/>);
    //         employeeListSize++;
    //     }
    // }
    
    const unassignedTaskJsx = [];
    let unAssignedTaskNo = 0;
    for (const [key,value] of Object.entries(summaryData.unAssignedTasks)) {
        unassignedTaskJsx.push(<PropertyBlockV2 data={value} key={key} callBacks={callBacks}/>);
        unAssignedTaskNo += value.totalTaskNo;
    }

    let displayJsx = null;
    if (isAddTaskOpen) {
        displayJsx = <AddTask onExitClick={handleAddTaskClick} onSubmitClick={addTask}/>;
    } else {
        displayJsx = (error.loadingError.length > 0) ? <ErrorMessage message={error.loadingError} reloadPageClick={() => window.location.reload()}/> : (<React.Fragment>
           <SectionHeader 
                pageTitle={summary.pageTitle} 
                buttonNames={summary.buttons} 
                buttonIcons={summary.buttonIcons} 
                handleSearchClick={handleSearch}
                handleButtonClick={[handleAddTaskClick]} 
                handleDateClick={handleSelectDate}
            />
            <div className={styles.main_content}>
                <div className={styles.col1} onScroll={handleScroll}>
                    <div className={styles.listing_header}>
                        {`${employeeListSize} ${t("Employees.word")}`}
                    </div>
                    {employeeJsx}
                </div>
                <div className={styles.col2}>
                    <div className={styles.sub_header}>
                        <div>{t("UnassignedTask.heading")}</div>
                        <p>{`${unAssignedTaskNo} ${t("Tasks.word")}`}</p>
                    </div>
                    {unassignedTaskJsx}
                </div>
            </div>
        </React.Fragment>
        )
    }
    
    return (
        <div className={styles.task_summary_page}>
            {(isOpenModal)? <AssignStaff currentSize={staffQueue.size} taskSize={1} data={summaryData.staffData}onExit={handleModalClick} callBacks={assignStaffCallBacks} maxSize={Number.MAX_SAFE_INTEGER}/> : null}
            <Navbar/>
            <Sidebar index={summary.pageIndex}/>
            {error.actionError.length > 0 ? <ErrorPopUp message={error.actionError} handleDismissClick={handleDismissError}/> : null}
            {loaderState.loading ? <Spinner /> : null}
            {displayJsx}
        </div>
    )
}

export default React.memo(TaskSummary);