import React, {useState,useRef} from 'react';
import Modal from "../common/modal/Modal";
import { useTranslation } from 'react-i18next'
import styles  from "../staff/personal-details/commonModal.module.css";
import {ICON_PATH,FORGOT_PASSWARD_BFF} from "../../helper/pathConstants";
import CustomButton from "../common/buttons/CustomButton";
import CustomTextBox from "../common/textboxes/CustomTextBox";
import useOutSideClick from "../../hooks/useOutSideClick";
import formFieldChecker from "../../helper/formFieldChecker";
import axios from 'axios';
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE} from "../../helper/text_EN";


const infoStyle = {
    color : "rgba(0, 0, 0, 0.5)",
    letterSpacing : "0.2px",
    fontSize:"16px",
    fontWeight:500,
    lineHeight:"24px"
}

export default function ForgotPassword(props) {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");
    const [blankField, setBlanckField] = useState({});
    const modalRef = useRef();

    const handleSubmitClick = () => {
        let nullFields = formFieldChecker({email : email},["email"]);
        if(!nullFields.isNull) {
            axios
                .post(FORGOT_PASSWARD_BFF,{email : email})
                .then(res => {
                    if(res && res.data && res.data.message === SUCCESSFUL) {
                        props.handleExitClick();
                    } else {
                        props.handleExitClick(res.data.message);
                    }
                })
                .catch(err => {
                    let message = (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE;
                    props.handleExitClick(message);
                })
            
        } else {
            setBlanckField(nullFields);
        }

    }

    useOutSideClick(modalRef,props.handleExitClick,true);

    return (
        <Modal>
            <div className={styles.main_content} ref={modalRef}>
            <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={props.handleExitClick} alt=""/>
                <div className={styles.header}>
                    {t("ForgotPassword.label")}
                </div>
                <div className={styles.middle}>
                    <CustomTextBox value={email} width={504} placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} error={blankField} errorMessage={(email.length > 0) ? t("Emailerror.message") :t("FieldError.message")}/>
                    <p style={infoStyle}>N.B - You will receive an email containing password reset information.</p>
                </div>
                <div className={styles.footer}>
                    <CustomButton type="default" buttonName={t("Cancel.button")} padding="62" onClick={props.handleExitClick}/>
                    <CustomButton type="colored" color={(email.length === 0) ?"blocked":"blue"} buttonName={t("Submit.button")} padding="125" onClick={handleSubmitClick}/>
                </div>
            </div>
        </Modal>
    )
}
