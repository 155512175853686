import React, { useState, useReducer, useEffect } from "react";
import Navbar from "../common/navbar/Navbar";
import Sidebar from "../common/sidebar/Sidebar";
import SectionHeader from "../common/sectionHeader/SectionHeader";
import styles from "./scheduler.module.css";
import Property from "./scheduler-listing/Property";
import SelectionHeader from "./SelectionHeader";
import AssignStaff from "./assign-staff/AssignStaff";
import AddTask from "./add-task/AddTask";
import {SchedulerReducer, HttpReducer, schdulerInitialState,actions,loaderInitialState} from "../../reducers/SchedulerReducer"
import { useTranslation } from "react-i18next";
import Spinner from "../common/spinner/Spinner";
import axios from "axios";
import moment from 'moment';
import {scheduler} from '../../helper/pageConstants';
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE,SOMETHING_WENT_WRONG,PLEASE_TRY_AGAIN} from "../../helper/text_EN";
import ErrorMessage from '../common/error/ErrorMessage'; 
import ErrorPopUp from '../common/error/ErrorPopUp'
import {SCHEDULER_BFF,ASSIGN_STAFF_BFF,ADD_TASK_BFF,TASK_TYPE_BFF} from '../../helper/pathConstants'
import Pagination from '../common/pagination/Pagination'
import CombinedFilter from '../common/filters/CombinedFilter'
import SingleFilter from '../common/filters/SingleFilter'
import {taskTitleOptions} from '../../helper/dataConstants'

export default function Scheduler() {
  const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
  const [schedulerData, dispatch] = useReducer(SchedulerReducer, schdulerInitialState);
  let pageData = localStorage.getItem('schedularPayloadPage') && JSON.parse(localStorage.getItem('schedularPayloadPage'));
  let pageFilter = localStorage.getItem('schedularPayloadFilter') && JSON.parse(localStorage.getItem('schedularPayloadFilter'));
  const [pagination, setPagination] = useState({
    size : pageData && pageData.size ? pageData.size : 10,
    page : pageData && pageData.page ? pageData.page : 1,
    totalPages : 0,
    startingRecordNo : 0,
    totalRecordNo : 0,
    endingRecordNo : 0
  })

  const [filters, setFilters] = useState({
    distance : pageFilter && pageFilter.distance ? pageFilter.distance : "",
    property : pageFilter && pageFilter.property.id ? pageFilter.property : {},
    taskTypes : pageFilter && pageFilter.taskTypes ? pageFilter.taskTypes : []
  })

  const [error , setError] = useState({
    loadingError : "",
    actionError : ""
  });
  const [startDate,setStartDate] = useState(moment(localStorage.getItem("startDate")));
  const [endDate,setEndDate] = useState(moment(localStorage.getItem("endDate")));
  const [selectedTask, setSelectedTask] = useState(new Map());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [staffQueue, setStaffqueue] = useState(new Map());
  const [dropdownStatus, setDropdownStatus] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const payload = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  useEffect(() => {
    loaderDispatch({ type: "FETCH_START" });
    payload.params = {
      date : endDate.format("YYYY-MM-DD"),
      startDate : startDate.format("YYYY-MM-DD"),
      page : pagination.page,
      size : pagination.size,
      distance : filters.distance,
      distanceFrom : filters.property.id,
      taskTypes : filters.taskTypes
    }
    axios
      .get(SCHEDULER_BFF, payload)
      .then((res) => {
        if(res.data) {
          if(res.data.message === SUCCESSFUL) {
            setError({
              loadingError : "",
              actionError : ""
            });
            setSelectedTask(new Map());   
            actions.loadData(dispatch,res.data.data);
            const pageDetails = res.data.pageDetails;
            setPagination(prevState => {
              return {
                ...prevState,
                ...pageDetails
              }
            })        
          } else {
            setError({
              ...error,
              loadingError : res.data.message
            });
          }
        } else {
          setError({
            ...error,
            loadingError : SOMETHING_WENT_WRONG
          });
        }
        
        loaderDispatch({ type: "FETCH_SUCCESS"});
      })
      .catch(err=>{
          setError({
            ...error,
            loadingError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
          });
          loaderDispatch({ type: "FETCH_ERROR" });          
        }
      );
  }, [startDate,endDate,pagination.page,pagination.size,reload,filters.property,filters.distance,filters.taskTypes]);

  const handlePagination = (value) => {
    setPagination(prevState => {
      let payloadParamsPage = {
        ...prevState,
        ...value
      }
      localStorage.setItem("schedularPayloadPage", JSON.stringify(payloadParamsPage));
      return payloadParamsPage;
    })
  } 

  const handleFilterSelect = (value) => {
    setFilters(prevState => {
      let payloadParamsFilter = {
        ...prevState,
        ...value,
      }
      localStorage.setItem("schedularPayloadFilter", JSON.stringify(payloadParamsFilter));
      return payloadParamsFilter;
    })

    setPagination(prevState => {
      let payloadParamsPage = {
        ...prevState,
        page : 1
      }
      localStorage.setItem("schedularPayloadPage", JSON.stringify(payloadParamsPage));
      return payloadParamsPage;
    })
  }

  const handleDismissError = () => {
    setError(prevState => {
      return {
        ...prevState,
        actionError : ""
      }
    })
  }

  const handleSelectDate = (startDate,endDate) => {
    setStartDate(startDate);  
    setEndDate(endDate);
    setPagination(prevState => {
      let payloadParamsPage = {
        ...prevState,
        page : 1
      }
      localStorage.setItem("schedularPayloadPage", JSON.stringify(payloadParamsPage));
      return payloadParamsPage;
    })
  }
 
  const handleReloadPageClick = () => {
    localStorage.setItem("schedularPayloadPage","");
    localStorage.setItem('schedularPayloadFilter',"");
    window.location.reload();
  }

  const handleAddTaskClick = () => {
    setIsAddTaskOpen(!isAddTaskOpen);
  };

  const handleModalClick = () => {
    setIsOpenModal(!isOpenModal);
  };

  const assignStaff = () => {
    let staffList = Array.from(staffQueue.keys());
    let data = {};
    for (let key of selectedTask.keys()) {
      data[key] = [...schedulerData.taskData[key],...staffList];
    }
    axios.put(ASSIGN_STAFF_BFF,data,payload)
      .then(res => {
        if(res && res.data) {
          if(res.data.message === SUCCESSFUL) {
            const payLoad = {
              staffObjects: staffQueue,
              selectedTask: selectedTask,
              assignData : res.data.data
            };
            actions.addStaff(dispatch,payLoad);
          } else {
            setError({
              ...error,
              actionError : res.data.message
            });
          }
          
        } else {
          setError({
            ...error,
            actionError : PLEASE_TRY_AGAIN
          });
        }
      })
      .catch((err) => {
          setError({
            ...error,
            actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
          });
        }
      )
    
  };

  const deleteStaff = (staffId, taskId, propertyId) => {
    let data = {};
    data[taskId] = schedulerData.taskData[taskId].filter(item => item !== staffId)
    axios.put(ASSIGN_STAFF_BFF,data,payload).then(
      res => {
        if(res && res.data) {
          if(res.data.message === SUCCESSFUL && !res.data.data[taskId].includes(staffId))
          {
            const payLoad = {
              taskId,
              staffId,
              propertyId,
              newStaffList : res.data.data[taskId]
            };
            actions.deleteStaff(dispatch,payLoad);
          } else {
            setError({
              ...error,
              actionError : res.data.message
            });
          }
        } else {
          setError({
            ...error,
            actionError : PLEASE_TRY_AGAIN
          });
        }
      }
    ).catch((err) => {
      setError({
        ...error,
        actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
      });
    })
  };

  const unAssignAllStaff = (propertyId) => {
    let data = {};
    for(let taskId of Object.keys(schedulerData.propertyData[propertyId].taskList)) {
      data[taskId] = [];
    }
    axios.put(ASSIGN_STAFF_BFF,data,payload).then(
      res => {
        if(res && res.data) {
          if(res.data.message === SUCCESSFUL)
          {
            const payLoad = {
              propertyId,
              newTaskStaffMap : res.data.data
            };
            actions.unAssignStaffs(dispatch,payLoad);
          } else {
            setError({
              ...error,
              actionError : res.data.message
            });
          }
        } else {
          setError({
            ...error,
            actionError : PLEASE_TRY_AGAIN
          });
        }
      }
    ).catch((err) => {
      setError({
        ...error,
        actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
      });
    })
  };

  const addTask = (taskDetails) => {
    loaderDispatch({ type: "FETCH_START" });
    taskDetails.completeBy = moment(taskDetails.completeBy,"D MMM YYYY").format("YYYY/MM/DD").toString();
    axios.post(ADD_TASK_BFF,taskDetails,payload)
    .then( res => {
      if(res && res.data) {
        if(res.data.message === SUCCESSFUL && moment(taskDetails.completeBy,"YYYY/MM/DD").isBetween(startDate,endDate,"date","[]")) {
          actions.addTask(dispatch,res.data.taskData);
          //setReload(true); 
        } else {
          setError({
            ...error,
            actionError : res.data.message
          });
        }
      } else {
        setError({
          ...error,
          actionError : PLEASE_TRY_AGAIN
        });
      }
      loaderDispatch({ type: "FETCH_SUCCESS"});   
    })
    .catch(err => {
      loaderDispatch({ type: "FETCH_ERROR" });
      setError({
        ...error,
        actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
      });
    })
    
  }

  const selectTask = (tasks) => {
    const temp = new Map();
    for (let i = 0; i < tasks.length; i++) {
      if (!selectedTask.has(tasks[i].taskId)) {
        temp.set(tasks[i].taskId, tasks[i]);
      }
    }
    setSelectedTask((prev) => new Map([...prev, ...temp]));
  };

  const deSelectTask = (tasks) => {
    for (let i = 0; i < tasks.length; i++) {
      selectedTask.delete(tasks[i].taskId);
    }
    setSelectedTask(new Map(selectedTask));
  };

  const handleDropdownOperation = (index) => {
    setSelectedTask(new Map());
    const temp = Array(4).fill(false);
    temp[index] = true;
    setDropdownStatus(temp);
  };

  const addStaffInqueue = (staff) => {
    staffQueue.set(staff.staffId, staff);
    setStaffqueue(new Map(staffQueue));
  };

  const deleteStaffInqueue = (staffId) => {
    staffQueue.delete(staffId);
    setStaffqueue(new Map(staffQueue));
  };

  const clearStaffQueue = () => {
    setStaffqueue(new Map());
    handleDropdownOperation(1);
  };

  const assignStaffCallBacks = {
    addStaffInqueue,
    deleteStaffInqueue,
    clearStaffQueue,
    assignStaff,
  };

  const callBacks = {
    deleteStaff,
    selectTask,
    deSelectTask,
    handleModalClick,
    handleDropdownOperation,
    unAssignAllStaff
  };

  let propertyListSize = 0;
  const propertyItemJsx = [];
  for (const [key, value] of Object.entries(schedulerData.propertyData)) {
    if((!dropdownStatus[2] || (dropdownStatus[2] && value.unassignedTaskNo > 0)) && (!dropdownStatus[3] || (dropdownStatus[3] && value.totalTaskNo - value.unassignedTaskNo  > 0))) {
      if (value.totalTaskNo > 0) {
        propertyItemJsx.push(
          <Property
            data={value}
            dropdownStatus={dropdownStatus}
            key={key}
            callBacks={callBacks}
          />
        );
        propertyListSize++;
      }
    }
    
  }

  let displayJsx = null;
  if (isAddTaskOpen) {
    displayJsx = <AddTask onExitClick={handleAddTaskClick} onSubmitClick={addTask}/>;
  } else {
    displayJsx = (error.loadingError.length > 0) ?<ErrorMessage message={error.loadingError} reloadPageClick={handleReloadPageClick}/>:(
      <React.Fragment>
        {selectedTask.size > 0 ? (
          <SelectionHeader
            selectedTaskNo={selectedTask.size}
            callBacks={callBacks}
          />
        ) : (
          <SectionHeader
            pageTitle={scheduler.pageTitle}
            buttonNames={scheduler.buttons}
            buttonIcons={scheduler.buttonIcons}
            handleButtonClick={[handleAddTaskClick]}
            handleDateClick={handleSelectDate}
            dateRange={true}
          />
        )}
        <div className={styles.scheduler_listing}>
          <div className={styles.filter_sec}>
              <CombinedFilter handleFilterSelect={handleFilterSelect} value={filters}/>
              <SingleFilter handleFilterSelect={handleFilterSelect} value={filters.taskTypes} options={[]} endPoint={TASK_TYPE_BFF} params={t("TaskType.word")}/>
          </div>
          <div className={styles.listing_header}>
            {`${propertyListSize} ${t(`Results.label`)}`}
          </div>
          {propertyItemJsx}
        </div>
        <Pagination size={pagination.size} startingRecordNo={pagination.startingRecordNo} endingRecordNo={pagination.endingRecordNo} totalRecordNo={pagination.totalRecordNo} current={pagination.page} totalPages={pagination.totalPages} handlePagination={handlePagination}/>
      </React.Fragment>
    );
  }

  return (
    <div className={styles.scheduler_page}>
      {isOpenModal ? (
        <AssignStaff
          data={schedulerData.staffData}
          taskSize={selectedTask.size}
          currentSize={staffQueue.size}
          onExit={handleModalClick}
          callBacks={assignStaffCallBacks}
          maxSize={Number.MAX_SAFE_INTEGER}
        />
      ) : null}
      <Navbar />
      <Sidebar index={scheduler.pageIndex} />
      {error.actionError.length > 0 ? <ErrorPopUp message={error.actionError} handleDismissClick={handleDismissError}/> : null}
      {loaderState.loading ? <Spinner /> : displayJsx}
    </div>
  );
}
