import React from 'react';
import styles from './bulkStaffAdd.module.css';
import Navbar from '../../common/navbar/Navbar'
import Sidebar from '../../common/sidebar/Sidebar'
import BulkHeader from './BulkHeader';
import CustomButton from '../../common/buttons/CustomButton';
import Button from '../../common/buttons/Button'
import {Link} from 'react-router-dom'
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

const pageIndex = 3;

export default function BulkStaffAdd() {
    const { t, i18n } = useTranslation()

    return (
        <div className={styles.bulkStaff_page}>
            <Navbar/>
            <Sidebar index={pageIndex}/>
            <div className={styles.main_content}>
                <BulkHeader/>
                <div className={styles.main}>
                    <div className={styles.instruction}>
                        <div className={styles.block}>
                            <div className={styles.col1}>
                                {`${t("STEP.word")} 1`}
                            </div>
                            <div className={styles.col2}>
                                <p>{t("Step1.label")}</p>
                                <Link to="/assets/staff.csv" target="_blank" download style={{textDecoration:"none"}}>
                                    <Button type="default" padding="12" buttonName={t("DownloadFile.button")} image={ICON_PATH + "download.svg"}/>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.col1}>
                                {`${t("STEP.word")} 2`}
                            </div>
                            <div className={styles.col2}>
                                <p><p>{t("Step2.label")}</p></p>
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.col1}>
                                {`${t("STEP.word")} 3`}
                            </div>
                            <div className={styles.col2}>
                                <p><p>{t("Step3.label")}</p></p>
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.col1}>
                                {`${t("STEP.word")} 4`}
                            </div>
                            <div className={styles.col2}>
                                <p><p>{t("Step4.label")}</p></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/staff/bulkStaff-add/upload" style={{textDecoration:"none"}}>
                        <CustomButton type="colored" color="red" padding="48" onClick={null} buttonName={t("NextUpload.button")}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
