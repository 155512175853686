import React ,{useState} from 'react'
import styles from '../../staff/personal-details/commonModal.module.css'
import Modal from '../../common/modal/Modal';
import AutoComplete from '../../common/autoComplete/AutoComplete';
import CustomButton from '../../common/buttons/CustomButton';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next';


export default function ReAssignTask(props) {
    const { t, i18n } = useTranslation()
    const [staff, setStaff] = useState({});    


    const handleOptionClick = (key,value) => {
        setStaff(value)
    }

    const handleSubmitClick = () => {
        if(staff.id) {
            props.handleSubmitClick(staff);
            props.handleExitClick();
        }    
    }

    

    return (
        <Modal>
            <div className={styles.main_content} >
                <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={props.handleExitClick} alt=""/>
                <div className={styles.header}>
                    {t("ReAssignTask.label")}
                </div>
                <div className={styles.middle}>
                    <AutoComplete placeholder={t("ChooseStaff.label")} role="STAFF" value={staff} name={"staff"} width="504" endPoint="staff" handleOptionClick={handleOptionClick} error={{}}/>
                </div>
                <div className={styles.footer}>
                        <CustomButton type="default" buttonName={t("Cancel.button")} padding="62" onClick={props.handleExitClick}/>
                        <CustomButton type="colored" color={(!staff.id) ?"blocked":"blue"} buttonName={t("Submit.button")} padding="125" onClick={handleSubmitClick}/>
                    </div>
            </div>
        </Modal>
    )
}
