import React,{useState} from 'react'
import {ICON_PATH} from '../../../helper/pathConstants'
import CheckboxFilter from './CheckboxFilter'
import styles from './combinedFilter.module.css'
import {taskTypeMap} from '../../../helper/dataConstants'

const filterStyle = {
    height : "32px",
    width : "fit-content",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display : "flex",
    columnGap : "8px",
    padding : "4px 8px",
    position : "relative",
    alignItems : "center",
    borderRadius: "16px",
    boxSizing : "border-box",
    cursor : "pointer"
}

const modalStyle = {
    position: "absolute",
    top: "40px",
    left : "-60px"
}

export default function SingleFilter(props) {
    const [isDropdownOpen, setDropdown] = useState(false);

    const handleDropdown = () => {
        setDropdown(!isDropdownOpen);
    }

    const handleApplyClick = (value) => {
        props.handleFilterSelect(value);
        handleDropdown();
    }

    const makeString = () => {
        if(props.value.length > 0) {
            let result = "";
            let taskTitle = taskTypeMap[props.value[0]].taskTitle
            if(taskTitle.length > 10) {
                result = taskTitle.substring(0,8) + "..";
            } else {
                result = taskTitle;
            }
            if(props.value.length > 1) {
                result += ` + ${props.value.length - 1}`
            }
            return result;
            
        } else {
            return props.params
        }
    }

    return (
        <div style={{...filterStyle, backgroundColor : (props.value.length > 0) ? "rgba(34, 152, 218, 0.2)" : "white", color : (props.value.length>0) ? "#2298DA" : "#212121",border : (props.value.length > 0) ? "1px solid rgba(34, 152, 218, 0.08)" : "1px solid rgba(0, 0, 0, 0.12)"}}>
            <div onClick={handleDropdown}>{makeString()}</div>
            {
                (props.value.length > 0) ?
                <img src={ICON_PATH + "blue_cross.svg"} alt="" onClick={() => props.handleFilterSelect({taskTypes : []})}/>
                : <img src={ICON_PATH + "dropdown.svg"} onClick={handleDropdown}alt="" className={styles[isDropdownOpen ? "chevron_close" : "chevron_open"]}/>
            }
            {
                (isDropdownOpen) ? <div style={modalStyle}><CheckboxFilter handleApplyClick={handleApplyClick} onExit={handleDropdown} value={props.value} options={props.options} endPoint={props.endPoint}/></div>
                : null
            }
        </div>
    )
}
