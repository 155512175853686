import React from 'react';
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const currentTime = new Date(new Date().getTime());
    let expiryTime = null;
    let isAuthenticated = false;

    if(localStorage.getItem('expiryTime')){
        expiryTime = new Date(localStorage.getItem('expiryTime'));
    }
    if(expiryTime > currentTime){
        isAuthenticated = localStorage.getItem('isAuthenticate') ? JSON.parse(localStorage.getItem('isAuthenticate')) : false;
    }

    return (
        <Route 
            {...rest} 
            render={(props) => (isAuthenticated === true ? <Component {...props} /> : <Redirect to='/' />
        )} />
    )
}

export default ProtectedRoute;