import moment from 'moment';
export const types = {
    LOAD_DATA: "load_data",
    DELETE_STAFF: "delete_staff",
    ADD_STAFF: "add_staff",
    ADD_TASK: "add_task",
    UNASSIGN_ALL_STAFF : 'unassign_all_staff'
};

export const schdulerInitialState = {
    propertyData : {},
    staffData : {},
    taskData : {}
  }
  


export function SchedulerReducer(state,action) {

    let staffId = null;
    let propertyId = null;
    let taskId = null;
    
    switch(action.type) {
        case types.LOAD_DATA :
            return {
                ...action.payLoad
            }
        case types.DELETE_STAFF :
            propertyId = action.payLoad.propertyId;
            taskId = action.payLoad.taskId;
            staffId = action.payLoad.staffId;
            state.taskData[taskId] = action.payLoad.newStaffList;

            if(state.propertyData[propertyId] == null || state.propertyData[propertyId].taskList[taskId]== null) {
                return {...state};
            }

            if(state.propertyData[propertyId].taskList[taskId].assignedStaff[staffId] != null) { // check if staff exists for the task

                const staffdetail = state.propertyData[propertyId].taskList[taskId].assignedStaff[staffId];

                delete state.propertyData[propertyId].taskList[taskId].assignedStaff[staffId]; // Deletion of Staff

                state.propertyData[propertyId].taskList[taskId].staffsAssigned--;   // decrement staffsAssigned for that task

                if(state.propertyData[propertyId].taskList[taskId].staffsAssigned === 0) {
                    state.propertyData[propertyId].unassignedTaskNo++; // Increment of unassigned task No
                }

                if(state.staffData[staffdetail.staffType] != null && state.staffData[staffdetail.staffType].staffList[staffdetail.staffId] !=null) {
                    state.staffData[staffdetail.staffType].staffList[staffdetail.staffId].assignedTaskNo--;    // update date in staffData for data consistancy
                }
                
            }
            return {
                ...state
            }; 
        case types.ADD_STAFF :
            for(let task in action.payLoad.assignData) {
                state.taskData[task] = action.payLoad.assignData[task];

                if(!action.payLoad.selectedTask.has(task)) continue;

                propertyId = action.payLoad.selectedTask.get(task).propertyId;
                taskId = action.payLoad.selectedTask.get(task).taskId;

                if(state.propertyData[propertyId] == null || state.propertyData[propertyId].taskList[taskId]== null) {
                    continue;
                }

                if(state.propertyData[propertyId].taskList[taskId].staffsAssigned  === 0) {
                    state.propertyData[propertyId].unassignedTaskNo--; // Decrement unassigned task No as it will be assigned
                }
                
                for(let j = 0; j < action.payLoad.assignData[task].length; j++) { // looping for all assigned staffs
                    let staff = action.payLoad.assignData[task][j];
                    if(!action.payLoad.staffObjects.has(staff)) continue;
                    
                    let groupType = action.payLoad.staffObjects.get(staff).groupType;
                    staffId = action.payLoad.staffObjects.get(staff).staffId;

                    if(state.staffData[groupType] == null || state.staffData[groupType].staffList[staffId] == null) continue;

                    const staffdetails = {
                        staffName : state.staffData[groupType].staffList[staffId].name,
                        staffId : state.staffData[groupType].staffList[staffId].id,
                        color : state.staffData[groupType].staffList[staffId].color,
                        staffType : groupType
                    }
                    
                    if(state.propertyData[propertyId].taskList[taskId].assignedStaff[staffdetails.staffId] == null) {
                        
                        state.staffData[groupType].staffList[staffId].assignedTaskNo++; // Increment assigned task no of that staff.
                        
                        state.propertyData[propertyId].taskList[taskId].assignedStaff[staffdetails.staffId]=staffdetails;

                        state.propertyData[propertyId].taskList[taskId].staffsAssigned++; // increment staffsAssigned as staff is assigned.
                    }
                }
            }
            
            return {
                ...state
            };
        case types.ADD_TASK :
            propertyId = action.payLoad.propertyId;
            taskId = action.payLoad.taskId;

            state.taskData[taskId] = [];
            let assignedStaff = {};
            let staffsAssigned = 0;
            if(action.payLoad.assignedStaff) {
                action.payLoad.assignedStaff.forEach(item => {
                    assignedStaff[item.staffId] = item
                    state.taskData[taskId].push(item.staffId);
                    if(state.staffData[item.staffType] && state.staffData[item.staffType].staffList[item.staffId])
                    state.staffData[item.staffType].staffList[item.staffId].assignedTaskNo++;
                })
                staffsAssigned = action.payLoad.assignedStaff.length;
            }
            let taskObject = {
                taskName : action.payLoad.taskName,
                taskDescription : action.payLoad.taskDescription,
                taskId : taskId,
                staffsAssigned : staffsAssigned,
                taskIcon : action.payLoad.taskIcon,
                taskType : action.payLoad.taskType,
                expireDate : action.payLoad.expireDate,
                assignedStaff : assignedStaff
            }

            if(state.propertyData[propertyId]) {
                state.propertyData[propertyId].totalTaskNo++; // Increment totalTaskNo for new created task
                if (!state.propertyData[propertyId].taskList[taskId]) {
                    state.propertyData[propertyId].taskOrder.push({
                        date : taskObject.expireDate,
                        taskId : taskObject.taskId
                    })
                    state.propertyData[propertyId].taskOrder.sort((a,b) => {
                        return moment(a.date,"DD MMM YYYY") - moment(b.date,"DD MMM YYYY");
                    })
                }
                state.propertyData[propertyId].taskList[taskId] = taskObject;
                if (taskObject.staffsAssigned === 0) state.propertyData[propertyId].unassignedTaskNo++; // Increment of unassigned task No
            }
            // } else {
            //     state.propertyData[propertyId] = {
            //         propertyId : propertyId,
            //         propertyName : 	action.payLoad.propertyName,
            //         unassignedTaskNo : 0,
            //         totalTaskNo : 1,
            //         taskList : {
            //             [taskId] : taskObject
            //         }
            //     } 
            // }
            
            return {
                ...state
            }
        case types.UNASSIGN_ALL_STAFF :
            propertyId = action.payLoad.propertyId;
            for (const [key,value] of Object.entries(action.payLoad.newTaskStaffMap)) {
                state.taskData[key] = value;
                if(state.propertyData[propertyId]) {
                    if(state.propertyData[propertyId].taskList[key]) {
                        if(state.propertyData[propertyId].taskList[key].staffsAssigned != 0) {
                            state.propertyData[propertyId].unassignedTaskNo++;
                        }
                        state.propertyData[propertyId].taskList[key].staffsAssigned = 0;
                        for(let staffIds of Object.keys(state.propertyData[propertyId].taskList[key].assignedStaff)) {
                            let staffType = state.propertyData[propertyId].taskList[key].assignedStaff[staffIds].staffType;
                            if(state.staffData[staffType] && state.staffData[staffType].staffList[staffIds]) {
                                state.staffData[staffType].staffList[staffIds].assignedTaskNo--;
                            }
                        }
                        state.propertyData[propertyId].taskList[key].assignedStaff = {};
                    }
                }
            } 
            return {
                ...state
            }    
        default :
        return state;
    }
}

export const actions = {

    loadData: (dispatch,value) => (
        dispatch({
            type: types.LOAD_DATA,
            payLoad : value
        })
    ),

    deleteStaff: (dispatch, value) => (
        dispatch({
            type: types.DELETE_STAFF,
            payLoad : value
        })
    ),
    
    addStaff: (dispatch, value) => (
        dispatch({
            type: types.ADD_STAFF,
            payLoad : value
        })
    ),

    addTask: (dispatch, value) => (
        dispatch({
            type: types.ADD_TASK,
            payLoad : value
        })
    ),
    
    unAssignStaffs: (dispatch, value) => (
        dispatch({
            type: types.UNASSIGN_ALL_STAFF,
            payLoad : value
        })
    ), 
};

export const loaderInitialState = {
    loading: false,
};

export const HttpReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                loading: true,
            };
        case "FETCH_SUCCESS":
            return {
                loading: false,
            };
        case "FETCH_ERROR":
            return {
                loading: false,
            };
        default:
            return state;
    }
};