export const PLEASE_ENTER_EMAIL_AND_PASSWORD = "Please enter email and password";
export const INVALID_EMAIL_OR_PASSWORD = "Invalid email or password";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const TO_MANY_INVALID_SIGNIN_ATTEMPTS = "Too many invalid sign in attempts";
export const PLEASE_TRY_AGAIN = "Please Reload Page";
export const SUCCESSFUL = "Successful"
export const SERVICE_TEMPORARILY_UNAVAILABLE = "Service Temporarily Unavailable";
export const STAFF_INFO_NOT_UPDATED = "Please Try Again";
export const PROFILE_CREATED = "Profiles created successfully";
export const PROFILE_NOT_CREATED = "Profiles creation failed";
export const DISTANCE_NOT_UPDATED = "Reload page to see updated distance";
export const PASSWORD_NOT_RESET = "Reset Password Fails. Try Again."
