import React ,{useState,useRef} from 'react'
import styles from './calendar.module.css';
import moment from 'moment';
import Calendar from './Calendar';
import useOutsideClick from '../../../hooks/useOutSideClick';
import {ICON_PATH} from '../../../helper/pathConstants'

export default function CalendarDisplay(props) {
    const [displayDate,setDisplayDate] = useState(props.dateRange?moment(localStorage.getItem("startDate"),"D MMM YYYY"):moment(localStorage.getItem("date"),"D MMM YYYY"));
    const [openCalendar, setOpenCalendar] = useState(false);
    const calendarRef = useRef();

    let current = moment(localStorage.getItem("date"),"D MMM YYYY");

    const handleCalendarOpenClick = () => {
        setOpenCalendar(!openCalendar);
    };

    const selectDate = (date) => {
        localStorage.setItem('date',date.format("D MMM YYYY"));
        setDisplayDate(date);
        props.handleDateClick(date);
        handleCalendarOpenClick();
    }

    const selectDateRange = (startDate,endDate) => {
        localStorage.setItem('startDate',startDate.format("D MMM YYYY"));
        localStorage.setItem('endDate',endDate.format("D MMM YYYY"));
        setDisplayDate(startDate);
        props.handleDateClick(startDate,endDate);
    }

    const onDislayDate = (startDate,endDate) => {
        if(props.dateRange){
            const startDate = moment(localStorage.getItem('startDate'),"D MMM YYYY").format("D MMM");
            const endDate = moment(localStorage.getItem('endDate'),"D MMM YYYY").format("D MMM");
            return `${startDate} - ${endDate}`;
        
        }else{
            return localStorage.getItem("date");
        }
    }

    const nextDate = () => {
        if (!props.dateRange){
            current = moment(localStorage.getItem("date"),"D MMM YYYY");
            localStorage.setItem('date',current.clone().add(1,"day").format("D MMM YYYY"));
            setDisplayDate(current.clone().add(1,"day"));
            props.handleDateClick(current.clone().add(1,"day"));
        }
    }

    const prevDate = () => {
        if (!props.dateRange){
            current = moment(localStorage.getItem("date"),"D MMM YYYY");
            if(current.isAfter(moment())) {
                localStorage.setItem('date',current.clone().subtract(1,"day").format("D MMM YYYY"));
                setDisplayDate(current.clone().subtract(1,"day"));
                props.handleDateClick(current.clone().subtract(1,"day"));
            }
        }
    }


    useOutsideClick(calendarRef,handleCalendarOpenClick,openCalendar);
    return (
        <div className={styles.calendar} ref={calendarRef}>
            <div className={styles.display}>

                <img src={ICON_PATH + "chevron-left.svg"} onClick={prevDate} alt=""/>
                <div onClick={handleCalendarOpenClick}>{onDislayDate()}</div>
                <img src={ICON_PATH + "chevron-right.svg"} onClick={nextDate} alt=""/>
            </div>
            {
                (openCalendar) ? 
                <Calendar onSelect={selectDate} onSelectRange={selectDateRange} date={displayDate} dateRange={props.dateRange}/>
                : null 
            }
        </div>
    )
}
