import React from 'react';
import styles from './sidebar.module.css';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {menuItems} from '../../../helper/dataConstants'



export default function Sidebar(props) {
    const { t, i18n } = useTranslation()
    

    const menuItemJsx = menuItems.map(item => {
        let selectedItemClassname = `menu_item${(props.index === item.value)? "_selected" : ""}`;
    
        return (
            <Link to={item.path} key={item.value} style={{textDecoration:"none"}}>
            <div  className={styles[selectedItemClassname]} onMouseOver={event => event.currentTarget.firstChild.src=item.image_red}
            onMouseOut={event => event.currentTarget.firstChild.src= (props.index === item.value)? item.image_red:item.image}>
                <img className={styles["menu_icon"]} alt="" src={(props.index === item.value)? item.image_red : item.image}/>
                <div className={styles.item_description}>
                    {t(`${item.description}`)}
                </div>
            </div>
            </Link>
        );
    })

    return (
        <div className={styles.sidebar}>
            {menuItemJsx}
        </div>
    )
}
