import React,{useState} from 'react'
import styles from './taskbox.module.css'
import NameTag from './NameTag'
import { useTranslation } from 'react-i18next'
import {ICON_PATH} from '../../../helper/pathConstants'
import {taskTypeMap} from '../../../helper/dataConstants'
import Button from '../../common/buttons/Button'
import Notes from './Notes'

export default function TaskBox(props) {
    const { t, i18n } = useTranslation()
    const [isNoteModalOpen, setNoteModal] = useState(false);

    const staffsize = props.taskData.staffsAssigned;

    const nameTagJsx = [];
    for(const [key,value] of Object.entries(props.taskData.assignedStaff)) {
        nameTagJsx.push(<NameTag propertyId={props.propertyId} key ={value.staffId} taskId={props.taskData.taskId} staffDetails={value} callBacks={props.callBacks}/>)
    }

    const handleNoteModalClick = () => {
        setNoteModal(!isNoteModalOpen);
    }
    
    const handleClick = () => { 
        props.selectTask(props.taskData.taskId,props.taskIndex);
    }
    const handleAssignStaffClick = () => {
        props.callBacks.selectTask([{
            taskId : props.taskData.taskId,
            propertyId : props.propertyId
        }]);
        props.callBacks.handleModalClick();
    }

    const taskIconSelector = (taskCode) => {
        if(taskTypeMap[taskCode]) {
            return taskTypeMap[taskCode].taskIcon;
        } else {
            return taskTypeMap["DEFAULT"].taskIcon;
        }
    }

    return (
        <div className={styles.task_box}>
            {
                (isNoteModalOpen) ? <Notes  notes={props.taskData.taskNotes} bookingId={props.taskData.bookingId} handleExitClick={handleNoteModalClick}/> : null
            }
            <div className={styles.left_sec}>
                <img src= {props.checked ? ICON_PATH + "chekbox_selected.svg" : ICON_PATH + "chekbox.svg"} onClick={handleClick} style={{cursor:'pointer'}} alt =""/>
                <img className={styles.padding_left} src={ICON_PATH + taskIconSelector(props.taskData.taskType)} alt=""/>
                <div className={styles.task_name}>
                    <div>
                        {props.taskData.taskName}
                    </div>
                    <div className={styles.task_description}>
                        {props.taskData.taskDescription}
                    </div> 
                </div>
                {/* {
                    (props.taskData.priority) ?
                    <div className={styles.task_priority}>
                        <p>Priority</p>
                        <img src={ICON_PATH + "arrow-up.svg"} alt="^"/>
                    </div> 
                    : null
                } */}
            </div>
            <div className={styles.right_sec}>
                <img src={ICON_PATH + "info.svg"} alt="" onClick={handleNoteModalClick}/>
                {(staffsize !== 0)? <img src={ICON_PATH + "assign_staff_icon.svg"} onClick={handleAssignStaffClick} alt="+"/> :<div className={styles.assign_staff} onClick={handleAssignStaffClick}>{`+ ${t('AssignStaff.button')}`}</div>}
                <div className={styles.staff_list}>
                    {nameTagJsx}
                </div>
            </div>
            
        </div>
    )
}
