import React from 'react';
import Column from './Column';


const tableStyle = {
        width: "100%",
        height: "fit-content",
        display: "flex",
        justifyContent: "stretch"
}

export default function StaffTable(props) {

    const columnJsxs = [];
    for (let i = 0 ; i < props.tableHeader.length; i++) {
        const columnData = props.data.length && props.data.map(item => {
            return {
                staffId : item.id,
                details : item[props.tableHeader[i]],
            };
        })
        columnJsxs.push(<Column key={i} data={columnData} header={props.tableHeader[i]} onClick={props.onClick} maxWidth={props.maxWidth}/>)
    }

    return (
        <div style={tableStyle}>
            {columnJsxs}
        </div>
    )
}
