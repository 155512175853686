import React ,{useRef,useState} from 'react'
import useOutsideClick from '../../../hooks/useOutSideClick';
import Calendar from '../calendar/Calendar';
import moment from 'moment';
import {ICON_PATH} from '../../../helper/pathConstants'

const textBoxStyle = {
    boxSizing: "border-box",
    borderRadius: "6px",
    height : "40px",
    padding : "8px 12px",
    color: "#212121",
    position : "relative",
    display : "flex",
    alignItems : "center",
    cursor : "pointer"
}

const displayStyle = {
    padding : 0,
    border : "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    flexGrow : 1,
    outline : "none"
}

const floatBoxStyle = {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: "0.2px",
    margin : 0,
    boxSizing: "border-box",
    backgroundColor: "white",
    padding :"0 4px",
    position : "absolute",
    top : "-8px",
    left : "8px"

}

const erroeStyle = {
    position : "absolute",
    bottom : "-8px",
    left : "12px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}

export default function DateBox(props) {
    const[selectedDate, setSelectedDate] = useState(moment());
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const dateBoxRef = useRef();

    const handleCalendarView = () => {
        setCalendarOpen(!isCalendarOpen);
    }

    const displayColorPicker = () => {
        if(props.value.length > 0) {
            return "#212121";
        } else {
            return "rgba(0, 0, 0, 0.5)";
        }
    }
    const selectDate = (date) => {
        props.handleOptionClick(props.name,date.format("DD MMM YYYY").toString());
        setSelectedDate(date);
        setCalendarOpen(!isCalendarOpen);
    }

    useOutsideClick(dateBoxRef,handleCalendarView,isCalendarOpen);
    return (
        <div style={{...textBoxStyle,width: `${props.width}px`,border : (props.error[props.name])? "1px solid #EE2A24" : "1px solid rgba(0, 0, 0, 0.12)"}} ref={dateBoxRef}>
            <div style={{...displayStyle,color : displayColorPicker()}} onClick={handleCalendarView}>
                {(props.value.length > 0) ? props.value : props.placeholder}
            </div>
            <img src={ICON_PATH + "dropdown.svg"} alt="" onClick={handleCalendarView}/>
            {(isCalendarOpen) ?
                <Calendar onSelect={selectDate} date={selectedDate} isPastSelectable={true} selectBefore={props.selectBefore} selectAfter={props.selectAfter} />
                : null}
            {(props.value.length > 0) ?<p style={floatBoxStyle}>{props.placeholder}</p> : null}
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null}            
        </div>
    )
}
