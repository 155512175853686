import React,{useState} from 'react';
import styles from './addStaff.module.css';
import CustomTextBox from '../../common/textboxes/CustomTextBox'
import DropdownTextBox from '../../common/textboxes/DropdownTextBox'
import DateBox from '../../common/textboxes/DateBox'
import AddingSectionHeader from '../../common/sectionHeader/AddingSectionHeader';
import { useTranslation } from 'react-i18next'
import {staffRoleOptions} from '../../../helper/dataConstants';
import AutoComplete from '../../common/autoComplete/AutoComplete';
import LockedTextBox from '../../common/textboxes/LockedTexBox';
import MobileTextBox from '../../common/textboxes/MobileTextBox'
import formFieldChecker from '../../../helper/formFieldChecker';
import {addStaffInitialState} from '../../../helper/dataConstants'



export default function AddStaff(props) {
    const { t, i18n } = useTranslation()
    const [staffDetails, setStaffDetails] = useState({...addStaffInitialState,
        localeOffice : localStorage.getItem("localOfficeName"),
        city : localStorage.getItem("city")
    });
    const [blankField, setBlanckField] = useState({});

    const handleOnChange = (event) => {
        event.preventDefault();
        setStaffDetails({
            ...staffDetails,
            [event.target.name] : event.target.value
        })
    }
    
    const handleOptionClick = (key,value) => {
        setStaffDetails({
            ...staffDetails,
            [key] : value
        })
    }

    const handleMobileNoChange = (countryCode,mobile) => {
        setStaffDetails({
            ...staffDetails,
            countryCode : countryCode,
            mobile : mobile
        })
    }

    const handleAddStaffClick = () => {
        let nullFields = formFieldChecker(staffDetails,["email","role","name","joiningDate","status","manager"])
        if(!nullFields.isNull) {
            props.addStaff(staffDetails);
            props.onExitClick();
        } else {
            setBlanckField(nullFields);
        }
        
    }

    return (
        <div className={styles.add_staff}>
            <AddingSectionHeader title={t("AddStaff.button")} onExitClick={props.onExitClick} onSubmitClick={handleAddStaffClick}/>
            <div className={styles.main_content}>
                <div className={styles.form}>
                    <div className={styles.subheader}>
                        {t("GeneralInfo.label")}
                    </div>
                    <div className={styles.flex_container}>
                            <CustomTextBox placeholder={t("name.word")} value={staffDetails.name} name="name" width="370" onChange={handleOnChange} error={blankField}  errorMessage={t("FieldError.message")}/>
                            {/* <CustomTextBox placeholder={`${t("Employee.word")} ID`} value={staffDetails.id} name="id" width="370" onChange={handleOnChange} error={blankField}  errorMessage={t("FieldError.message")}/> */}
                            <DropdownTextBox placeholder={t("role.word")} value={staffDetails.role} width="370" options={staffRoleOptions} name="role" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <AutoComplete placeholder={t("manager.word")} role="ADMIN,MANAGER" value={staffDetails.manager} endPoint="staff" width="370" name="manager" handleOptionClick={handleOptionClick} error={blankField} errorMessage={t("FieldError.message")}/>
                            <MobileTextBox placeholder={t("mobile number.word")} value={""} width="370" handleOnChange={handleMobileNoChange} name="mobile" error={blankField}  errorMessage={t("FieldError.message")}/>
                            <CustomTextBox placeholder="Email" value={staffDetails.email} name="email" width="370" onChange={handleOnChange} error={blankField}  errorMessage={(staffDetails.email.length > 0) ? t("Emailerror.message") :t("FieldError.message")}/>
                            <DateBox placeholder={t("JoiningDate.word")} value={staffDetails.joiningDate} name="joiningDate" width="370" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <DropdownTextBox placeholder={t("status.word")} value={staffDetails.status} width="370" options={["ACTIVE","ON_LEAVE","RESIGNED"]} name="status" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.subheader}>
                        {t("ServiceArea.label")}
                    </div>
                    <div className={styles.flex_container}>
                            <LockedTextBox placeholder={t("locale office.word")} value={staffDetails.localeOffice} name="localeOffice" width="370" onChange={handleOnChange}/>
                            <LockedTextBox placeholder="City" value={staffDetails.city} width="370" name="city"  onChange={handleOnChange}/>
                            <LockedTextBox placeholder={t("Business.word")} value={staffDetails.business} width="370" name="business" onChange={handleOnChange}/>
                    </div>
                </div>
                {/* <div className={styles.form}>
                    <div className={styles.subheader}>
                        {t("VendorDetails.label")}
                    </div>
                    <div className={styles.flex_container}>
                        <DropdownTextBox placeholder={t("VendorName.word")} value={staffDetails.vendorName} name="vendorName" options={[]} width="370" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
