import React, {useState} from 'react';
import BarGraph from  '../../common/barGraph/BarGraph';
import styles from './staffRow.module.css';
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

export default function StaffRow(props) {
    const { t, i18n } = useTranslation()
    const [ checkBoxSelect, setCheckBoxSelect] = useState(false);

    
    const handleClick = () => {
        if(checkBoxSelect) {
            props.callBacks.deleteStaffInqueue(props.staffDetails.id);
            setCheckBoxSelect(!checkBoxSelect)
        } else if(props.currentSize < props.maxSize ){ //props.staffDetails.assignedTaskNo<10 && && props.taskSize + props.staffDetails.assignedTaskNo <= 10
            props.callBacks.addStaffInqueue({
                staffId : props.staffDetails.id,
                groupType : props.groupType,
            });
            setCheckBoxSelect(!checkBoxSelect)
        }
    }

    return (
        <div className={styles.row}>
            <div className={styles.left_sec}>
                <img src={(checkBoxSelect)? ICON_PATH + "chekbox_selected.svg" : ICON_PATH + "chekbox.svg"} onClick={handleClick} alt=""/>
                <div>{props.staffDetails.name}</div>
            </div>
            <div className={styles.right_sec}>
                <BarGraph width="12" taskNo={props.staffDetails.assignedTaskNo}/>
                <div className={styles.fixed}>
                    <p>{`${props.staffDetails.assignedTaskNo} ${t("Actve.word")} ${t("Tasks.word")}`}</p>
                </div>
            </div>
        </div>
    )
}
