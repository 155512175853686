export const scheduler = {
    pageIndex : 1,
    pageTitle : "Scheduler",
    buttons : ["AddTask", "Publish"],
    buttonIcons : ["blue_plus.svg", "publish_icon.svg"]
}

export const summary = {
    pageIndex : 2,
    pageTitle : "Summary",
    buttons : ["AddTask", "Publish"],
    buttonIcons : ["blue_plus.svg", "publish_icon.svg"]
}

export const staff = {
    pageIndex : 3,
    pageTitle : "Staff",
    buttons : ["BulkStaff","AddStaff"],
    buttonIcons : ["bulk_staff_icon.svg","white_plus.svg"],
    tableHeader : ["name", "id", "mobile number","locale office","role","manager","status"]
}

export const staffDetails = {
    pageIndex : 3,
    leavesTableHeader : ["From","Till","Total days","Comments"],
    staffAssignedTableHeader : ["name", "id", "mobile number","locale office","role","status"]
}

export const reloadPage = () =>  window.location.reload();