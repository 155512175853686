export const types = {
    LOAD_DATA : "load_data",
    ADD_LEAVE : "add_leave",
    REASSIGN_STAFFS : "reassign_staffs"
}

export const initialState = {
    staffName : "",
    employeeDetails : {},
    leaves : [],
    staffsAssigned : [],
    assignedStaffsId : []
}

export const reducer = (state,action) => {
    switch(action.type) {
        case types.LOAD_DATA :
            return {
                ...state,
                ...action.payLoad
            };
        case types.ADD_LEAVE :
            return {
                ...state,
                leaves : [
                    ...state.leaves,
                    action.payLoad
                ]
            }
        case types.REASSIGN_STAFFS :
            return {
                ...state,
                assignedStaffsId : [],
                staffsAssigned : []
            }         
        default :
            return state; 
    }
}

export const actions = {
    loadData : (dispatch,value) => {
        dispatch({
            type : types.LOAD_DATA,
            payLoad : value
        })
    },
    addLeave : (dispatch,value) => {
        dispatch({
            type : types.ADD_LEAVE,
            payLoad : value
        })
    },
    reAssignStaffs : (dispatch) => {
        dispatch({
            type : types.REASSIGN_STAFFS
        })
    }
}