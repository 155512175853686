import React ,{useState,useEffect,useReducer} from 'react';
import styles from './editInfo.module.css';
import Navbar from '../../common/navbar/Navbar';
import Sidebar from '../../common/sidebar/Sidebar';
import {useHistory} from 'react-router-dom'
import CustomButton from '../../common/buttons/CustomButton';
import CustomTextBox from '../../common/textboxes/CustomTextBox';
import DropdownTextBox from '../../common/textboxes/DropdownTextBox';
import LockedTextBox from '../../common/textboxes/LockedTexBox';
import {ICON_PATH,STAFF_DETAILS_BFF,STAFF_EDIT_BFF} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'
import {HttpReducer,loaderInitialState} from "../../../reducers/SchedulerReducer";
import Spinner from '../../common/spinner/Spinner'
import axios from 'axios';
import ErrorMessage from '../../common/error/ErrorMessage';
import {SUCCESSFUL,SERVICE_TEMPORARILY_UNAVAILABLE, SOMETHING_WENT_WRONG,STAFF_INFO_NOT_UPDATED} from "../../../helper/text_EN";
import ErrorPopUp from '../../common/error/ErrorPopUp';
import MobileTextBox from '../../common/textboxes/MobileTextBox';
import AutoComplete from '../../common/autoComplete/AutoComplete';
import {staffRoleOptions,editInfoInitialState} from '../../../helper/dataConstants'
import formFieldChecker from '../../../helper/formFieldChecker'

const pageIndex = 3;

export default function EditInfo(props) {
    const [loaderState, loaderDispatch] = useReducer(HttpReducer, loaderInitialState);
    const [reload , setReload] = useState(false);
    const [staffDetails,setStaffDetails] = useState(editInfoInitialState);
    const [error , setError] = useState({
        loadingError : "",
        actionError : ""
      });
    const [blankField, setBlanckField] = useState({});
    const history = useHistory();
    const { t, i18n } = useTranslation()

    const token = localStorage.getItem("token");
    const payload = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    let fields = ["email","mobile","name","manager"];
    const staffId = props.match.params.id;
    const pageDetails = props.location.state;

    useEffect(() => {
        setBlanckField({})
        loaderDispatch({ type: "FETCH_START" });
        payload.params = {
            staffId : staffId
        }
        axios.get(STAFF_DETAILS_BFF,payload)
        .then(res => {
            if(res.data) {
                if(res.data.message === SUCCESSFUL) {
                    setError({
                        loadingError : "",
                        actionError : ""
                    }); 
                    const localOffice = localStorage.getItem("localOfficeName");
                    res.data.data.employeeDetails.localeOffice = localOffice;
                    res.data.data.employeeDetails.city = localStorage.getItem("city"); 
                    if(!staffRoleOptions.includes(res.data.data.employeeDetails.role)) {
                        res.data.data.employeeDetails.role = "";
                    } 
                    setStaffDetails((prevState) => {
                        return {
                            ...prevState,
                            ...res.data.data.employeeDetails
                        }
                    });      
                } else {
                    setError({
                        ...error,
                        loadingError : res.data.message
                    });
                }
            } else {
                setError({
                    ...error,
                    loadingError : SOMETHING_WENT_WRONG
                });
            }
            loaderDispatch({ type: "FETCH_SUCCESS"});
        })
        .catch(err =>{
            setError({
                ...error,
                loadingError : SERVICE_TEMPORARILY_UNAVAILABLE
            });
            loaderDispatch({ type: "FETCH_ERROR" });
        }
      );
      setReload(false);
      if (staffDetails.manager.id) {
        fields.push("manager");
    }

    },[reload])

    const handleOptionClick = (key,value) => {
        setStaffDetails({
            ...staffDetails,
            [key] : value
        })
    }
    const handleDismissError = () => {
        setError(prevState => {
          return {
            ...prevState,
            actionError : ""
          }
        })
    }
    const getMobileNoFromCountryCodeAndNo = () => {
        const number = (staffDetails.countryCode && staffDetails.countryCode.length && ((staffDetails.countryCode[0] === "+") ?staffDetails.countryCode.substring(1):staffDetails.countryCode)) + staffDetails.mobile;
        return number;
    }

    const handleOnChange = (event) => {
        event.preventDefault();
        setStaffDetails({
            ...staffDetails,
            [event.target.name] : event.target.value
        })
    }

    const handleMobileNoChange = (countryCode,mobile) => {
        setStaffDetails({
            ...staffDetails,
            countryCode : countryCode,
            mobile : mobile
        })
    }

    const handleSaveChangeClick = () => {
        let nullFields = formFieldChecker(staffDetails,fields)
        if(nullFields.isNull) {
            setBlanckField(nullFields);
        } else {
            const data = {
                ...staffDetails,
                manager : staffDetails.manager.id
            }
            loaderDispatch({ type: "FETCH_START" });
            axios.put(STAFF_EDIT_BFF,data,payload)
              .then(res => {
                if(res && res.data) {
                  if(res.data.message === SUCCESSFUL) {
                    history.push({
                        pathname : `/staff/${staffId}/details`,
                        state : pageDetails
                    })
                    
                  } else {
                    setError({
                      ...error,
                      actionError : res.data.message
                    });
                  }
                } else {
                  setError({
                    ...error,
                    actionError : STAFF_INFO_NOT_UPDATED
                  });
                }
                loaderDispatch({ type: "FETCH_SUCCESS"});
              })
              .catch(err=>{
                loaderDispatch({ type: "FETCH_ERROR" });  
                setError({
                  ...error,
                  actionError : (err.response.data && err.response.data.message) || SERVICE_TEMPORARILY_UNAVAILABLE
                });        
              }
            );
        }
    }

    return (
        (loaderState.loading) ? <Spinner/> :
        <div className={styles.editinfo_page}>
            <Navbar/>
            <Sidebar index={pageIndex}/>
            {error.actionError.length > 0 ? <ErrorPopUp message={error.actionError} handleDismissClick={handleDismissError}/> : null}
            {
                (error.loadingError.length > 0) ? <ErrorMessage message={error.loadingError} reloadPageClick={() => window.location.reload()}/> :
                <div className={styles.main_content}>
                    <div className={styles.header}>
                        <div className={styles.left_sec}>
                            <img src={ICON_PATH + "arrow-left.svg"} onClick={() => history.goBack()} style={{cursor:'pointer'}} alt="<"/>
                            <div>{t("EditStaff.label")}</div>
                        </div>
                        <div className={styles.right_sec}>
                            <CustomButton type="default"  buttonName={t("DiscardChanges.button")} onClick={() => setReload(true)} padding="12"/>
                            <CustomButton type="colored" color="red"  buttonName={t("SaveChanges.button")} onClick={handleSaveChangeClick} padding="50"/>
                        </div>
                    </div>
                    <div className={styles.edit_sec}>
                        <div className={styles.block}>
                            <header className={styles.subheading}>{t("GeneralInfo.label")}</header>
                            <CustomTextBox placeholder={t("name.word")} value={staffDetails.name} name="name" width="370" onChange={handleOnChange} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <LockedTextBox placeholder={`${t("Employee.word")} ID`} value={staffDetails.id} name="id" width="370" onChange={handleOnChange}/>
                            <DropdownTextBox placeholder={t("role.word")} value={staffDetails.role} width="370" options={staffRoleOptions} name="role" handleOptionClick={handleOptionClick} error={blankField}/>
                            <AutoComplete placeholder={t("manager.word")} role="ADMIN,MANAGER" value={staffDetails.manager.name} initial={true} width="370" name="manager" endPoint="staff" handleOptionClick={handleOptionClick} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <MobileTextBox placeholder={t("mobile number.word")} value={getMobileNoFromCountryCodeAndNo()} width="370" name="mobile" handleOnChange={handleMobileNoChange} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <CustomTextBox placeholder="Email" value={staffDetails.email} name="email" width="370" onChange={handleOnChange} error={blankField}  errorMessage={t("FieldError.message")}/>
                            <LockedTextBox placeholder={t("JoiningDate.word")} value={staffDetails.joiningDate} name="joiningDate" width="370" onChange={handleOnChange}/>
                            <DropdownTextBox placeholder={t("status.word")} value={staffDetails.status} width="370" options={["ACTIVE","ON_LEAVE","RESIGNED"]} name="status" handleOptionClick={handleOptionClick} error={blankField} />
                        </div>
                        <div className={styles.block}>
                            <header className={styles.subheading}>{t("ServiceArea.label")}</header>
                            <LockedTextBox placeholder={t("locale office.word")} value={staffDetails.localeOffice} name="localeOffice"  width="370" onChange={handleOnChange}/>
                            <LockedTextBox placeholder="City" value={staffDetails.city} width="370" name="city"  onChange={handleOnChange}/>
                            <LockedTextBox placeholder={t("Business.word")} value={staffDetails.business} width="370"  name="business" onChange={handleOnChange}/>
                        </div>
                        <div className={styles.block}>
                            <header className={styles.subheading}>{t("VendorDetails.label")}</header>
                            <DropdownTextBox placeholder={t("VendorName.word")} value={staffDetails.vendorName} name="vendorName" options={[]} width="370" handleOptionClick={handleOptionClick} error={blankField} />
                            <LockedTextBox placeholder={`${t("Employee.word")} ID`} value={staffDetails.vendorId} name="vendorId" width="370" onChange={handleOnChange}/>
                        </div>                 
                    </div>
                </div>
            }
        </div>
    )
}
