import React from 'react'
import {ICON_PATH} from '../../../helper/pathConstants'

const textBoxStyle = {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "6px",
    height : "40px",
    padding : "8px 12px",
    color: "#212121",
    position : "relative",
    display : "flex",
    alignItems : "center"
}

const inputStyle = {
    padding : 0,
    border : "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    flexGrow : 1,
    outline : "none"
}

const floatBoxStyle = {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: "0.2px",
    margin : 0,
    boxSizing: "border-box",
    backgroundColor: "white",
    padding :"0 4px",
    position : "absolute",
    top : "-8px",
    left : "8px"

}


export default function LockedTexBox(props) {

    return (
        <div style={{...textBoxStyle,width: `${props.width}px`}}>
            <input style={inputStyle} type="text" placeholder={props.placeholder} disabled={true} name={props.name} value={props.value} onChange={props.onChange}/>
            <img src={ICON_PATH + "lock_icon.svg"} alt=""/>
            {(props.value.length > 0) ?<p style={floatBoxStyle}>{props.placeholder}</p> : null}            
        </div>
    )
}
