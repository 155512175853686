import React,{useState} from 'react';
import styles from './commonModal.module.css'
import CustomButton from '../../common/buttons/CustomButton';
import DateBox from '../../common/textboxes/DateBox';
import CustomTextArea from '../../common/textboxes/CustomTextArea';
import Modal from '../../common/modal/Modal';
import { useTranslation } from 'react-i18next'
import {ICON_PATH} from '../../../helper/pathConstants'
import formFieldChecker from '../../../helper/formFieldChecker';



export default function MarkLeave(props) {
    const { t, i18n } = useTranslation();
    const [blankField, setBlanckField] = useState({});
    const [leaveDetails, setLeave ] = useState({
        from : "",
        till : "",
        comment : ""
    });
    

    const handleOptionClick = (key,value) => {
        setLeave({
            ...leaveDetails,
            [key] : value
        })
    }
    
    const handleSubmitClick = () => {
        let nullFields = formFieldChecker(leaveDetails,["from","till"])
        if (!nullFields.isNull) {
            props.handleMarkLeave(leaveDetails);
            props.handleExitClick();
        } else {
            setBlanckField(nullFields);
        }
    }

    const handleOnChange = (event) => {
        event.preventDefault();
        setLeave({
            ...leaveDetails,
            [event.target.name] : event.target.value
        })
    }


    return (
        <Modal>
            <div className={styles.main_content} >
                <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={props.handleExitClick} alt=""/>
                <div className={styles.header}>
                    {t("MarkLeave.button")}
                </div>
                <div className={styles.middle}>
                    <DateBox placeholder={t("From.word")} error={blankField} value={leaveDetails.from} selectBefore={leaveDetails.till} name={"from"} width="244" handleOptionClick={handleOptionClick} errorMessage={t("FieldError.message")}/>
                    <DateBox placeholder={t("Till.word")} error={blankField} value={leaveDetails.till} selectAfter={leaveDetails.from} name={"till"} width="244" handleOptionClick={handleOptionClick} errorMessage={t("FieldError.message")}/>
                    <CustomTextArea value={leaveDetails.comment} width={504} height={120} placeholder={t("TypeComment.label")} name="comment" onChange={handleOnChange} superScript={t("Comment.word")} error={blankField} errorMessage={t("FieldError.message")}/>
                </div>
                <div className={styles.footer}>
                        <CustomButton type="default" buttonName={t("Cancel.button")} padding="62" onClick={props.handleExitClick}/>
                        <CustomButton type="colored" color="blue" buttonName={t("Submit.button")} padding="125" onClick={handleSubmitClick}/>
                    </div>
            </div>
        </Modal>
    )
}
