import React ,{useRef} from 'react'
import Modal from '../../common/modal/Modal'
import styles from './notes.module.css'
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'
import useOutsideClick from '../../../hooks/useOutSideClick'

export default function Notes(props) {
    const { t, i18n } = useTranslation()
    const modalRef = useRef()


    let notesJsx = props.notes.map((item,index) => {
        return <div key={index}>{item.content}</div>
    })

    useOutsideClick(modalRef,props.handleExitClick,true)
    
    return (
        <Modal>
            <div className={styles.container} ref={modalRef}>
                <img className={styles.crossmark} src={ICON_PATH + "white_x.svg"} onClick={props.handleExitClick} alt=""/>
                <div className={styles.header}>
                    {t(`TaskDetails.label`)}
                </div>
                <div className={styles.content}>
                    <div className={styles.sub_sec}>
                        <div className={styles.subheader}>
                            {t(`Booking.label`)}
                        </div>
                        <div className={styles.booking_sec}>
                            <div className={styles.block}>
                                <p className={styles.caption}>Booking ID</p>
                                <p className={styles.details}>{(props.bookingId) ? props.bookingId : "--"}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sub_sec}>
                        <div className={styles.subheader}>
                            {t(`Notes.button`)}
                        </div>
                        <div className={styles.notes_sec}>
                            {(notesJsx.length > 0) ? notesJsx : <div>{t('NoNotes.label')}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
