import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const erroeStyle = {
    position : "absolute",
    bottom : "-8px",
    left : "48px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}

export default function MobileTextBox(props) {

    const handleOnChange = (inputValue,formattedValue) => {
        let countryCode= formattedValue.dialCode;
        let mobile =  inputValue.substring(countryCode.length);
        props.handleOnChange("+" + countryCode, mobile);
    }
    
    return (
        <div style={{position:"relative"}}>
            <PhoneInput
                country={'dk'}
                value={props.value}
                onChange={handleOnChange}
                placeholder={props.placeholder}
                containerStyle = {{width:`${props.width}px`}}
                inputStyle = {{height:"40px", width:`${props.width}px`, border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}}
                buttonStyle = {{border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}}
            />
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null}
        </div>
        
    )
}
