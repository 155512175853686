import React, {useState,useEffect} from 'react';
import styles from './propertyBlock.module.css';
import TaskTag from './TaskTag'
import {ICON_PATH} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'

export default function PropertyBlock(props) {
    const [isDropdownOpen, setDropdown] = useState(props.expandStatus);
    const { t, i18n } = useTranslation()

    useEffect(() => {
        if(props.expandStatus) {
            setDropdown(props.expandStatus);
        }
        
    },[props.expandStatus])

    const handleDropDownClick = () => {
        if(isDropdownOpen) {
            props.handleExpandAllClick();
        }
        setDropdown(!isDropdownOpen); 
    }

    const taskTagJsx = [];
    for(const [key,value] of Object.entries( props.data.taskList)) {
        taskTagJsx.push(<TaskTag data={value} staffId={props.staffId} propertyId={props.data.propertyId} key={key} callBacks={props.callBacks}/>)
    }
    
    return (
        <div className={styles.property_block}>
            <div className={styles.header} onClick={handleDropDownClick} >
                <div className={styles.left_sec}>
                    <div className={styles.property_name}>{props.data.propertyName}</div>
                </div>
                <div className={styles.right_sec}>
                    <div>{`${props.data.totalTaskNo} ${t("Tasks.word")}`}</div>
                    <img src={ICON_PATH + "chevron-up.svg"} className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]}  alt="^"/>
                </div>
            </div>
            {
                (isDropdownOpen) ?
                <div className={styles.hidden_sec}>
                    {taskTagJsx}
                </div>
                : null
            }
        </div>
    )
}
