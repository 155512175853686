import React from 'react'
import {ICON_PATH} from '../../../helper/pathConstants'

const tagStyle = {
    height : "24px",
    width : "100%",
    boxSizing : "border-box",
    padding : "3px 12px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "12px",
    background: "#F8F8F8",
    display : "flex",
    justifyContent : "space-between",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#212121"
}

const nameStyle = {
    maxWidth : "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
}


export default function OptionTag(props) {
    
    return (
        <div style={tagStyle}>
            <div style={nameStyle}>{props.data}</div>
            <img src={ICON_PATH + "x.svg"} alt="X" onClick={props.onCrossClick}/>
        </div>
    )
}
