import React from 'react'

const textAreaStyle = {
    position: "relative"
}

const textBoxStyle = {
    outline: "none",
    borderRadius:"6px",
    padding: "12px",
    boxSizing: "border-box",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    resize: "none"
}

const superscriptStyle = {
    position: "absolute",
    top : "-8px",
    left: "8px",
    padding: "0 4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "rgba(0, 0, 0, 0.5)",
    backgroundColor: "white",
    margin: 0
}

const erroeStyle = {
    position : "absolute",
    bottom : "-4px",
    left : "12px",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color : "#EE2A24",
    backgroundColor: "white",
    letterSpacing: "0.2px",
    textTransform: "capitalize"
}


export default function CustomTextArea(props) {
    return (
        <div style={textAreaStyle}>
            <textarea maxLength={254} style={{...textBoxStyle,width:`${props.width}px`,height:`${props.height}px`,border: (props.error[props.name])? "1px solid #EE2A24" :"1px solid rgba(0, 0, 0, 0.12)"}} value={props.value} placeholder={props.placeholder} name={props.name} onChange={props.onChange}/>
            {(props.value.length > 0) ?<p style={superscriptStyle}>{props.superScript}</p> : null}
            {(props.error[props.name])? <div style={erroeStyle}>{props.errorMessage}</div> : null} 
        </div> 
    )
}
