import React from 'react'
import Modal from '../../common/modal/Modal'
import {IMAGE_PATH,STAFF,STAFF_BULK_ADD} from '../../../helper/pathConstants';
import {PROFILE_CREATED} from '../../../helper/text_EN'
import {useHistory} from 'react-router-dom' 
import { useTranslation } from 'react-i18next'

const modalStyle = {
    height : "240px",
    width : "240px",
    display : "flex",
    flexDirection : "column",
    backgroundColor : "white",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
}

const row1Style = {
    display : "flex",
    flexDirection : "column",
    width : "100%",
    alignItems : "center",
    rowGap : "16px",
    justifyContent : "center",
    height : "192px",
    boxSizing : "border-box",
    borderBottom : "1px solid rgba(0, 0, 0, 0.12)"
}

const msgStyle = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#000000",
    width : "100%",
    height : "fir-content"
}

const linkStyle = {
    width : "100%",
    height : "48px",
    padding : "12px 0",
    display : "flex",
    justifyContent : 'center',
    color : "#2298DA",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    boxSizing : "border-box"
}

export default function ProfileCreatedModal(props) {
    const history = useHistory();
    const { t, i18n } = useTranslation()

    const handleClick = () => {
        if(props.message === PROFILE_CREATED) {
            history.push(STAFF)
        } else {
            history.push(STAFF_BULK_ADD);
        }
    }

    return (
        <Modal>
            <div style={modalStyle}>
                <div style={row1Style}>
                    <img src={`${IMAGE_PATH}${(props.message === PROFILE_CREATED) ?"status_positive.svg" : "status_negative.svg"}`} alt=""/>
                    <div style={msgStyle}>{props.message}</div> 
                </div>
                <div style={linkStyle} onClick={handleClick}>
                    {(props.message === PROFILE_CREATED)? t("ViewStaffList.link") : t("TryAgain.link")}
                </div>
            </div>
        </Modal>
    )
}
