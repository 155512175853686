import React from 'react';
import {useHistory} from 'react-router-dom'
import {ICON_PATH,STAFF} from '../../../helper/pathConstants'
import { useTranslation } from 'react-i18next'


const headerStyle = {
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "center",
    padding: "0 32px",
    columnGap: "16px",
}
const headingStyle = {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.3px",
    color: "#000000",
    margin : 0
}

export default function BulkHeader() {
    const { t, i18n } = useTranslation()
    const history = useHistory();

    return (
        <div style={headerStyle}>
            <img src={ICON_PATH + "arrow-left.svg"} alt="<" onClick={() => history.push(STAFF)}/>
            <p style={headingStyle}>{t("BulkStaff.button")}</p>
        </div>
    )
}
