export const types = {
    LOAD_DATA: "load_data",
    RESET_DATA: "reset_data",
    CHANGE_STAFF: "change_staff",
    UNASSIGN_TASK: "unassign_task",
    ADD_STAFF: "add_staff",
    ADD_TASK: "add_task",
    REASSIGN_ALL: "reassign_all",
    DELETE_STAFF_DATA : "delete_staff_data"
};

export const taskSummaryInitialState = {
    assignedTasks: {},
    unAssignedTasks: {},
    staffData: {},
    taskStaffMap : {},
    totalPages: 0,
    staffOrder : []
}


export const summaryReducer = (state,action) => {
    let staffId = null;
    let propertyId = null;
    let taskId = null ;
    switch(action.type) {
        case types.LOAD_DATA :
            return {
                ...state, 
                assignedTasks:{ ...state.assignedTasks,...action.payLoad.assignedTasks},
                staffData:{ ...state.staffData,...action.payLoad.staffData},
                taskStaffMap:{ ...state.taskStaffMap,...action.payLoad.taskStaffMap},
                unAssignedTasks:{ ...state.unAssignedTasks,...action.payLoad.unAssignedTasks},
                staffOrder : [...state.staffOrder,...action.payLoad.staffOrder],
                totalPages:action.payLoad.totalPages
            }
        case types.RESET_DATA :
            return {
                ...taskSummaryInitialState
            }
        case types.DELETE_STAFF_DATA : 
            return {
                ...state,
                assignedTasks: {},
                staffOrder : []
            }    
        case types.CHANGE_STAFF :
            staffId = action.payLoad.selectedTask.staffId;    
            propertyId = action.payLoad.selectedTask.propertyId;
            taskId = action.payLoad.selectedTask.taskId;

            state.taskStaffMap[taskId] = action.payLoad.assignData[taskId];

            if(state.assignedTasks[staffId] == null || state.assignedTasks[staffId].propertyList[propertyId] == null ) {
                return {...state};
            }

            if( state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId] != null) { // check if task exists
                const taskDetails = {
                    ...state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId],
                    propertyId : propertyId,
                    propertyName : state.assignedTasks[staffId].propertyList[propertyId].propertyName
                };

                if(!action.payLoad.assignData[taskId].includes(staffId)) { // If task is deleted for that staff from backEnd

                    delete state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId]; // delete task from existing entry

                    state.assignedTasks[staffId].propertyList[propertyId].totalTaskNo--; //decrement totalTaskNo from deleted task

                    if(state.assignedTasks[staffId].propertyList[propertyId].totalTaskNo === 0) {
                        delete state.assignedTasks[staffId].propertyList[propertyId]; // remove property if no task
                    }
                    if(action.payLoad.distanceMap[staffId]) {
                        state.assignedTasks[staffId].distance = action.payLoad.distanceMap[staffId];
                    }

                    state.assignedTasks[staffId].assignedTaskNo--; // decrement staff's assignedtaskNo

                    if(state.staffData[state.assignedTasks[staffId].staffType] && state.staffData[state.assignedTasks[staffId].staffType].staffList[staffId]) {
                        state.staffData[state.assignedTasks[staffId].staffType].staffList[staffId].assignedTaskNo--; // decrement staff's assignedtaskNo in staffData for data consistancy
                    }

                    // if(state.assignedTasks[staffId].assignedTaskNo === 0) {
                    //     delete state.assignedTasks[staffId]; // remove staff if no task is assigned
                    // } 

                }
               

                for(let i = 0; i < action.payLoad.assignData[taskId].length; i++) { // looping for all new assignee
                    let newAssigneeId = action.payLoad.assignData[taskId][i]; // new assignee details
                    if(!action.payLoad.staffObjects.has(newAssigneeId)) continue;
                    let newAssigneeType = action.payLoad.staffObjects.get(newAssigneeId).groupType;

                    if(state.staffData[newAssigneeType] == null || state.staffData[newAssigneeType].staffList[newAssigneeId] == null) continue;

                    let newAssigneeName = state.staffData[newAssigneeType].staffList[newAssigneeId].name;
          
                    state.staffData[newAssigneeType].staffList[newAssigneeId].assignedTaskNo++; // increment new assignee assignedTaskNo

                    if(state.assignedTasks[newAssigneeId]) { // check if staff exist in listing section
                        if(action.payLoad.distanceMap[newAssigneeId]) {
                            state.assignedTasks[newAssigneeId].distance = action.payLoad.distanceMap[newAssigneeId];
                        }
                        if (state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId]) { // check if property entry exist for that staff

                            state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId].taskList[taskDetails.taskId] = { // task assigned
                                taskName : taskDetails.taskName,
                                taskId : taskDetails.taskId
                            }

                            state.assignedTasks[newAssigneeId].assignedTaskNo++; 

                            state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId].totalTaskNo++;

                        } else {
                            state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId] = { //if property is not listed for the staff
                                propertyId : taskDetails.propertyId,
                                propertyName : 	taskDetails.propertyName,
                                totalTaskNo : 1,
                                taskList : {
                                    [taskDetails.taskId] : {
                                        taskName : taskDetails.taskName,
                                        taskId : taskDetails.taskId
                                    }
                                }
                            }

                            state.assignedTasks[newAssigneeId].assignedTaskNo++;  
                        }
                    }
                    // } else {
                    //     state.assignedTasks[newAssigneeId] = { // if staff is not listed previously
                    //         staffName : newAssigneeName,
                    //         staffType : newAssigneeType,
                    //         assignedTaskNo : 1,
                    //         distance  : 0,
                    //         staffId : newAssigneeId,
                    //         propertyList : {
                    //             [taskDetails.propertyId] : {
                    //                 propertyId : taskDetails.propertyId,
                    //                 propertyName : 	taskDetails.propertyName,
                    //                 totalTaskNo : 1,
                    //                 taskList : {
                    //                     [taskDetails.taskId] : {
                    //                         taskName : taskDetails.taskName,
                    //                         taskId : taskDetails.taskId
                    //                     }
                    //                 }
                    //             } 
                    //         }
                    //     }
                    // }
                }
            }
            return {
                ...state
            }
        case types.UNASSIGN_TASK :
            staffId = action.payLoad.staffId;    
            propertyId = action.payLoad.propertyId;
            taskId = action.payLoad.taskId;

            state.taskStaffMap[taskId] = action.payLoad.newStaffList;

            if(state.assignedTasks[staffId] == null || state.assignedTasks[staffId].propertyList[propertyId] == null ) {
                return {...state};
            }

            if( state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId] != null) { // check if task exists
                if(action.payLoad.distanceMap[staffId]) {
                    state.assignedTasks[staffId].distance = action.payLoad.distanceMap[staffId];
                }

                const propertyName = state.assignedTasks[staffId].propertyList[propertyId].propertyName;
                const taskName = state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId].taskName;
                
                delete state.assignedTasks[staffId].propertyList[propertyId].taskList[taskId]; // delete task from existing entry

                state.assignedTasks[staffId].propertyList[propertyId].totalTaskNo--; //decrement totalTaskNo from deleted task

                if(state.assignedTasks[staffId].propertyList[propertyId].totalTaskNo === 0) {
                    delete state.assignedTasks[staffId].propertyList[propertyId]; // remove property if no task
                }

                state.assignedTasks[staffId].assignedTaskNo--; // decrement staff's assignedtaskNo

                if(state.staffData[state.assignedTasks[staffId].staffType] != null && state.staffData[state.assignedTasks[staffId].staffType].staffList[staffId] != null) {
                    state.staffData[state.assignedTasks[staffId].staffType].staffList[staffId].assignedTaskNo--;
                }

                if(state.assignedTasks[staffId].assignedTaskNo === 0) {
                    delete state.assignedTasks[staffId]; // remove staff if no task is assigned
                }
                if(state.taskStaffMap[taskId] && state.taskStaffMap[taskId].length === 0) {
                    if(state.unAssignedTasks[propertyId]) { // check if Deleted task's property exists in unAssignedTask section
                        state.unAssignedTasks[propertyId].taskList[taskId] = { // add deleted  task in unAssigned Task section
                            taskName : taskName,
                            taskId : taskId
                        }
                        state.unAssignedTasks[propertyId].totalTaskNo++;
                    } else {
                        state.unAssignedTasks[propertyId] = { // add deleted  task in unAssigned Task section
                            propertyId : propertyId,
                            propertyName : 	propertyName,
                            totalTaskNo : 1,
                            taskList : {
                                [taskId] : {
                                    taskName : taskName,
                                    taskId : taskId
                                }
                            }
                        }
                    }
                }
            }

            return {
                ...state
            }
        case types.ADD_STAFF:
            propertyId = action.payLoad.selectedTask.propertyId;
            taskId = action.payLoad.selectedTask.taskId;

            state.taskStaffMap[taskId] = action.payLoad.assignData[taskId];

            if(state.unAssignedTasks[propertyId] != null) { // check if property exists
                
                if(state.unAssignedTasks[propertyId].taskList[taskId] != null) { //check if task exists for that property
                    const taskDetails = {
                        ...state.unAssignedTasks[propertyId].taskList[taskId],
                        propertyId : propertyId,
                        propertyName : state.unAssignedTasks[propertyId].propertyName
                    };

                    delete state.unAssignedTasks[propertyId].taskList[taskId];
                    state.unAssignedTasks[propertyId].totalTaskNo--; // decrement totalTaskNo in that property at unAssignedTask section
        
                    if(state.unAssignedTasks[propertyId].totalTaskNo === 0) {
                        delete state.unAssignedTasks[propertyId]; // remove property from unAssignedTask section if there is no task
                    }

                    for(let i = 0; i < action.payLoad.assignData[taskId].length; i++) { // looping for all new assignee
                        let newAssigneeId = action.payLoad.assignData[taskId][i]; // new assignee details
                        if(!action.payLoad.staffObjects.has(newAssigneeId)) continue;
                        let newAssigneeType = action.payLoad.staffObjects.get(newAssigneeId).groupType;

                        if(state.staffData[newAssigneeType] == null || state.staffData[newAssigneeType].staffList[newAssigneeId] == null) continue;

                        let newAssigneeName = state.staffData[newAssigneeType].staffList[newAssigneeId].name;

                        state.staffData[newAssigneeType].staffList[newAssigneeId].assignedTaskNo++; // increment new assignee assignedTaskNo

                        if(state.assignedTasks[newAssigneeId]) { // check if staff exist in listing section
                            if(action.payLoad.distanceMap[newAssigneeId]) {
                                state.assignedTasks[newAssigneeId].distance = action.payLoad.distanceMap[newAssigneeId];
                            }
                            if (state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId]) { // check if property entry exist for that staff
                                state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId].taskList[taskDetails.taskId] = {  // task assigned
                                    taskName : taskDetails.taskName,
                                    taskId : taskDetails.taskId
                                }

                                state.assignedTasks[newAssigneeId].assignedTaskNo++; 

                                state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId].totalTaskNo++;
                            } else {
                                state.assignedTasks[newAssigneeId].propertyList[taskDetails.propertyId] = {   //if property is not listed for the staff
                                    propertyId : taskDetails.propertyId,
                                    propertyName : 	taskDetails.propertyName,
                                    totalTaskNo : 1,
                                    taskList : {
                                        [taskDetails.taskId] : {
                                            taskName : taskDetails.taskName,
                                            taskId : taskDetails.taskId
                                        }
                                    }
                                }

                                state.assignedTasks[newAssigneeId].assignedTaskNo++;  
                            }
                        }
                        // } else {
                        //     state.assignedTasks[newAssigneeId] = {  // if staff is not listed previously
                        //         staffName : newAssigneeName,
                        //         staffType : newAssigneeType,
                        //         assignedTaskNo : 1,
                        //         distance  : 0,
                        //         staffId : newAssigneeId,
                        //         propertyList : {
                        //             [taskDetails.propertyId] : {
                        //                 propertyId : taskDetails.propertyId,
                        //                 propertyName : 	taskDetails.propertyName,
                        //                 totalTaskNo : 1,
                        //                 taskList : {
                        //                     [taskDetails.taskId] : {
                        //                         taskName : taskDetails.taskName,
                        //                         taskId : taskDetails.taskId
                        //                     }
                        //                 }
                        //             } 
                        //         }
                        //     }
                        // }
                    }
                }
               
            }

            return {
                ...state
            }
        case types.ADD_TASK :
            let taskObject = {
                taskName : action.payLoad.taskName,
                taskId : action.payLoad.taskId
            }
            propertyId = action.payLoad.propertyId;

            let propertyObject = {
                propertyId : propertyId,
                propertyName : 	action.payLoad.propertyName,
                totalTaskNo : 1,
                taskList : {
                    [taskObject.taskId] : taskObject
                }
            }

            action.payLoad.assignedStaff.forEach(item => {
                if(state.assignedTasks[item.staffId]) {
                    if(action.payLoad.distanceMap && action.payLoad.distanceMap[item.staffId]) {
                        state.assignedTasks[item.staffId].distance = action.payLoad.distanceMap[item.staffId];
                    }
                    if(state.assignedTasks[item.staffId].propertyList[propertyId]) {
                        state.assignedTasks[item.staffId].propertyList[propertyId].totalTaskNo++;
                        state.assignedTasks[item.staffId].propertyList[propertyId].taskList[taskObject.taskId] = taskObject;
                    } else {
                        state.assignedTasks[item.staffId].propertyList[propertyId] = propertyObject;
                    }
                    state.assignedTasks[item.staffId].assignedTaskNo++;
                }
                // } else {
                //     let staffObject = {
                //         staffName : item.staffName,
                //         staffType : item.staffType,
                //         assignedTaskNo : 1,
                //         distance  : 0,
                //         staffId : item.staffId,
                //         propertyList : {}
                //     }
                //     staffObject[propertyId] = propertyObject;
                //     state.assignedTasks[item.staffId] = staffObject;
                // }

                

                if(state.staffData[item.staffType] && state.staffData[item.staffType].staffList[item.staffId]) {
                    state.staffData[item.staffType].staffList[item.staffId].assignedTaskNo++;
                }

            });

            if(!action.payLoad.assignedStaff || action.payLoad.assignedStaff.length === 0 ) {
                if(state.unAssignedTasks[propertyId]) {
                    state.unAssignedTasks[propertyId].totalTaskNo++;
                    state.unAssignedTasks[propertyId].taskList[taskObject.taskId] = taskObject;
                } else {
                    state.unAssignedTasks[propertyId] = propertyObject;
                }
            }
            return {
                ...state
            }
        case types.REASSIGN_ALL :
            for(let taskId in action.payLoad.newTaskMap) {
                state.taskStaffMap[taskId] = action.payLoad.newTaskMap[taskId];
            }
            // if(state.assignedTasks[action.payLoad.staffId]) 
            // {
            //     let staffType = state.assignedTasks[action.payLoad.staffId].staffType;
                
            //     if(state.staffData[staffType] && state.staffData[staffType].staffList[action.payLoad.staffId]) {
            //         state.staffData[staffType].staffList[action.payLoad.staffId].assignedTaskNo -= state.assignedTasks[action.payLoad.staffId].assignedTaskNo;
            //     }
            //     state.assignedTasks[action.payLoad.staffId].assignedTaskNo = 0;
            //     state.assignedTasks[action.payLoad.staffId].distance = 0;
            //     state.assignedTasks[action.payLoad.staffId].propertyList = {};
            //     delete state.assignedTasks[action.payLoad.staffId];
            // }
            for (let staffId in action.payLoad.staffData) {
                if(state.staffData[action.payLoad.staffData[staffId].staffType] && state.staffData[action.payLoad.staffData[staffId].staffType].staffList[staffId]) {
                    state.staffData[action.payLoad.staffData[staffId].staffType].staffList[staffId].assignedTaskNo = action.payLoad.staffData[staffId].assignedTaskNo;
                }
                if(state.assignedTasks[staffId]) {
                    state.assignedTasks[staffId] = action.payLoad.staffData[staffId];
                }

            }
            // if(action.payLoad.staffData) {
            //     if(state.staffData[action.payLoad.staffData.staffType] && state.staffData[action.payLoad.staffData.staffType].staffList[action.payLoad.staffData.staffId]) {
            //         state.staffData[action.payLoad.staffData.staffType].staffList[action.payLoad.staffData.staffId].assignedTaskNo = action.payLoad.staffData.assignedTaskNo;
            //     }
            //     if(state.assignedTasks[action.payLoad.staffData.staffId]) {
            //         state.assignedTasks[action.payLoad.staffData.staffId] = action.payLoad.staffData;
            //     }
            // }
            return {
                ...state
            }         
        default :
        return state;
    }
};




export const actions = {

    loadData: (dispatch,value) => (
        dispatch({
            type: types.LOAD_DATA,
            payLoad : value
        })
    ),
    resetData: (dispatch) => (
        dispatch({
            type: types.RESET_DATA,
            payLoad : {}
        })
    ),

    resetStaffData: (dispatch) => (
        dispatch({
            type: types.DELETE_STAFF_DATA,
            payLoad : {}
        })
    ),

    unAssignTask: (dispatch, value) => (
        dispatch({
            type: types.UNASSIGN_TASK,
            payLoad : value
        })
    ),

    changeStaff: (dispatch, value) => (
        dispatch({
            type: types.CHANGE_STAFF,
            payLoad : value
        })
    ),
    
    addStaff: (dispatch, value) => (
        dispatch({
            type: types.ADD_STAFF,
            payLoad : value
        })
    ),

    addTask: (dispatch, value) => (
        dispatch({
            type: types.ADD_TASK,
            payLoad : value
        })
    ),
    reAssignAll :  (dispatch, value) => (
        dispatch({
            type: types.REASSIGN_ALL,
            payLoad : value
        })
    ),   
};