
function ValidateEmail(email) 
{
    if (/^[^\s@]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email))
    {
        return true;
    }
    return false;
}


const checkIfNull = (data,field) => {
    if(data[field] == null) return false;
    if (field === "email") {
        return !ValidateEmail(data[field]);
    }

    if(typeof (data[field]) === "string") {
        return !(data[field].length > 0);
    } else if (typeof (data[field]) === "object") {
        return !data[field].id;
    } else {
        return false;
    }
} 


export default function formFieldChecker (data,fields) {
    let nullFields = {
        isNull : false
    };
    fields.forEach(field => {
        if(checkIfNull(data,field)) {
            nullFields.isNull = true;
            nullFields[field] = true;
        }
    })
    return nullFields;
}