import React,{useState,useRef} from 'react'
import styles from './pagination.module.css';
import {ICON_PATH} from '../../../helper/pathConstants'
import {pageSizes,getPaginationList} from '../../../helper/dataConstants'
import { useTranslation } from 'react-i18next'
import useOutsideClick from '../../../hooks/useOutSideClick';

export default function Pagination(props) {
    const { t, i18n } = useTranslation()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const optionRef = useRef();

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const handleSizeClick = (e) => {
        handleDropdownClick();
        props.handlePagination({
            size: parseInt(e.target.id,10),
            page : 1
        });
        
    }
    const handlePageClick = (e) => {
        if(e.target.id !== "...") {
            props.handlePagination({
                page : parseInt(e.target.id,10)
            });
        }
        
    }

    const handleNextPageClick = () => {
        if(props.current < props.totalPages) {
            props.handlePagination({
                page : props.current+1
            });
        }
    }

    const handlePrevPageClick = (e) => {
        if(props.current > 1) {
            props.handlePagination({
                page : props.current-1
            });
        }
    }

    useOutsideClick(optionRef,handleDropdownClick,isDropdownOpen);

    const paginationArray = getPaginationList(props.current,props.totalPages);
    const paginationJsx = paginationArray.map((item,index) => {
        return <div className={styles[(props.current === item)? "selected" : "block"]} key={index} id={item}>
            {item}
        </div>
    })

    const dropDownJsx = pageSizes.map((item,index) => {
        return <div key={item} className={styles.option}>
                <div className={styles.row} id={item} >{`${item} ${t("Results.label")}`}</div>
                {(index < pageSizes.length-1) ? <img className={styles.divider} src={ICON_PATH + "dropdown_divider.svg"} alt="-"/> : null}                
        </div> 
    });

    return (
        <div className={styles.footer}>
            <div className={styles.left_sec}>
                <p>{t("Showing.word")}</p>
                <div className={styles.size_selector} onClick={handleDropdownClick}>
                    <div className={styles.display_size}>
                        {`${props.size} ${t("Results.label")}`}
                    </div>
                    <img src={ICON_PATH + "chevron-up.svg"} alt="^" className={styles[isDropdownOpen ? "chevron_open" : "chevron_close"]}/>
                    {
                        (isDropdownOpen) ? <div className={styles.dropdown} onClick={handleSizeClick} ref={optionRef}>
                            {dropDownJsx}
                        </div> : null
                    }
                </div>
            </div>
            <div className={styles.middle_sec}>
                <img src={ICON_PATH + "pagination-left.svg"} alt="<" className={styles.chevron} onClick={handlePrevPageClick}/>
                <div className={styles.pagination_container} onClick={handlePageClick}>
                    {paginationJsx}
                </div>
                <img src={ICON_PATH + "pagination-right.svg"} alt=">" className={styles.chevron} onClick={handleNextPageClick}/>
            </div>
            <div className={styles.right_sec}>
                {`${props.startingRecordNo}-${props.endingRecordNo} of ${props.totalRecordNo}`}
            </div>
        </div>
    )
}
